import React from "react";
import { useRequestShareOfShelfTable } from "../../../services/Hooks/MyExecution/useRequestShareOfShelfTable";
import { IStateFilters } from "../../../store/SideFilters/types";
import TableComplements from "../../TableComplements";

interface IOwnProps {
  SFilters: IStateFilters;
  period: string;
}

const ShareOfShelf: React.FC<IOwnProps> = ({ SFilters, period }) => {
  const shareOfShelfTable = useRequestShareOfShelfTable(period, SFilters);

  return (
    <TableComplements
      // mainFilter={SAllFilters.data.myExecution.subviewOSA.main.categories}
      // secundaryFilter={SFilters.brand.listFilters || []}
      noFilter
      titleCard="Share de Gondola %"
      subTitleCard="(Mensal)"
      subTitle="Detalhe CATEGORIA / MARCAS"
      data={shareOfShelfTable.data}
      isLoading={shareOfShelfTable.isLoading}
      // goDetail={{
      //   detailPage: INavContent.MN_DETAIL_COMPLIANCE,
      //   label: "Detalhe loja",
      // }}
    />
  );
};

export default ShareOfShelf;
