import { connect } from "react-redux";
import React, { memo, useContext } from "react";
import {
  ContainerSubMenu,
  SubItem,
} from "../../../components/Contents/subMenu";

// Imagens
import bookImg from "../../../assets/MyDay/camera.svg";
import downloadIcon from "../../../assets/MyDay/file.svg";
import notificationImg from "../../../assets/MyDay/notification.svg";

// Components
import ViewBook from "./Book/ViewBook";
import { GlobalProps } from "../../../store";

// Types
import { ENavSubMenu, stateType } from "../../../store/Navigation/types";
import ViewPlanning from "./Planning";
import { ConfigContext } from "../../../services/Context/ConfigContext";
import ExtractData from "./ExtractData";

interface ownProp {
  indexSubMenu: ENavSubMenu;
  changeSubMenu: (data: ENavSubMenu) => void;
}

export const SubMenuPromo = ({
  changeSubMenu,
  indexSubMenu,
}: ownProp): JSX.Element => {
  const { config } = useContext(ConfigContext);

  return (
    <>
      <ContainerSubMenu>
        {config.modules.promo.subModules.day && (
          <SubItem
            icon={notificationImg}
            onClick={() => changeSubMenu(ENavSubMenu.MD_DAY)}
            active={indexSubMenu === ENavSubMenu.MD_DAY}
          />
        )}
        {config.modules.promo.subModules.book && (
          <SubItem
            icon={bookImg}
            onClick={() => changeSubMenu(ENavSubMenu.MD_BOOK)}
            active={indexSubMenu === ENavSubMenu.MD_BOOK}
          />
        )}
        <SubItem
          icon={downloadIcon}
          iconSize="45px"
          onClick={() => changeSubMenu(ENavSubMenu.MD_EXTRACT_DATA)}
          active={indexSubMenu === ENavSubMenu.MD_EXTRACT_DATA}
        />
      </ContainerSubMenu>
    </>
  );
};

interface stateProps {
  navigation: stateType;
}

const ContentPromo: React.FC<stateProps> = ({ navigation }: stateProps) => {
  switch (navigation.indexSubMenu) {
    case ENavSubMenu.MD_DAY:
      return <ViewPlanning />;
    case ENavSubMenu.MD_BOOK:
      return <ViewBook />;
    case ENavSubMenu.MD_EXTRACT_DATA:
      return <ExtractData />;
    default:
      break;
  }

  return null;
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
});

export default connect(mapStateToProps)(memo(ContentPromo));
