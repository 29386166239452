import { useQuery, UseQueryResult } from "react-query";
import api from "../../api";

export interface IChangePassword {
  newPassword: string;
  newPasswordConfirmation: string;
  oldPassword: string;
}

export enum ERenderingForms {
  "FormsLogins" = 0,
  "SendEmail" = 1,
  "SendNewPassword" = 2,
  "ScreenMessage" = 3,
}

interface IResponseChangePassword {
  status: string;
  message: string;
}

const requestChangePassword = async (props: IChangePassword) => {
  const response = await api.patch<IResponseChangePassword>(
    `change_password/?old_password=${props.oldPassword}&new_password=${props.newPassword}&new_password_confirmation=${props.newPasswordConfirmation}`
  );

  return response.data;
};

export function useChangePassword(
  props: IChangePassword
): UseQueryResult<IResponseChangePassword, unknown> {
  return useQuery(
    ["ChangePassword", props],
    () => requestChangePassword(props),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
}
