import styled from "styled-components";
import { theme } from "../../../../styles/theme";

export const BSMainConteiner = styled.div`
  overflow-y: hidden;

  height: calc(100% - 63px);
  display: grid;
  grid-template-rows: auto 1fr;
  margin: 5px;
  padding-bottom: 3px;
  color: black;

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }
`;

export const WrapperCardSubMenu = styled.div`
  height: 100%;
  width: inherit;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;
`;

export const BSSubMenu = styled.div`
  margin: auto auto;
  display: flex;
  width: 100%;
  min-width: fit-content;
  align-items: center;
  justify-content: left;
  background-color: white;
  height: 85px;
  padding: 5px 0;
  box-sizing: border-box;
  padding-right: 5px;

  & > button {
    min-width: 142px;
  }
`;

export const BSLegendTable = styled.section<{
  minWidth: string;
}>`
  background-color: #e7e6e6;
  width: ${(props) => props.minWidth};
  min-width: ${(props) => props.minWidth};
  border-radius: 7px;
  height: 70px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  div {
    min-width: 0;
    width: 100%;

    span {
      min-width: 0;
    }
  }
`;

export const BSConteinerData = styled.div`
  width: 100%;
  margin-top: 5px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    width: 99%;
    margin: 0 auto;
  }

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }

  & ::-moz-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  & ::-moz-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }
`;

export const BSHeader = styled.header`
  background-color: ${theme.colors.header};
  color: var(--white);
  height: 45px;
  width: calc(100% - 10px);
  display: flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;

  .header-msl-products {
    width: 100%;
    height: 100%;
    display: flex;

    h1 {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      margin: auto;
    }

    img {
      width: 45px;
      margin-left: 8px;
      position: absolute;
    }
  }

  & > h1 {
    font-size: 1.1rem;
    margin: 0;
    font-weight: bold;
    text-align: center;
  }

  @media screen and (max-width: 1120px) {
    background-color: #000040;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > h1 {
      font-size: 1.1rem;
      margin: 0;
      font-weight: bold;
    }
  }
`;

export const BSCard = styled.div`
  width: 100%;
  color: black;
  margin-top: 50px;
  background-color: var(--white);
`;

export const BSBoxSelection = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px;

  & > img {
    width: 40px;
    margin-right: 10px;
  }

  .selectForm {
    padding: 0 10px;
    height: 24px;
    margin: 0 10px;
  }
`;

export const BSWrapperGraphics = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 99.75%;
  height: 0;
  min-height: calc(100% - 70px);
  overflow-x: hidden;
  padding-right: 5px;
  /* margin-top: 30px; */

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }
`;

interface IPropStyle {
  margin?: string;
  paddingTable?: string;
  width?: string;
  minWidth?: string;
  color?: string;
  inline?: boolean;
  align?: string;
  top?: string;
  display?: string;
  columns?: string;
  gridColumns?: string;
  height?: string;
  fixedHeader?: boolean;
}

export const BSContainer = styled.section<IPropStyle>`
  margin-right: ${(props) => (props.margin ? props.margin : "5px")};
  margin-top: ${(props) => (props.top ? props.top : "0px")};
  width: ${(props) => (props.width ? props.width : "100%")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "501px")};
  display: ${(props) => props.display || "flex"};
  grid-template-columns: ${(props) => props.columns || ""};
  column-gap: 5px;
  flex-direction: ${(props) => (props.inline ? "row" : "column")};
  color: ${(props) => props.color};
  height: ${(props) => props.height || "100%"};

  padding-left: 10px;

  // Container do texto Top 50
  div {
    background: #e7e6e6;
    height: 29px;
    padding: 0.5rem 1rem;
    font-size: 14;

    display: flex;
    align-items: center;
    justify-content: space-between;
    ${(props) =>
      props.fixedHeader &&
      `
        position: sticky;
        top: 0;

      `}
    p {
      margin-bottom: 0;
      color: #c82a2a;
      font-size: 14px; // 19.2px
      font-weight: 700;
    }
  }

  // Estiilzando a TableSubView
  table {
    border-collapse: separate;
    border-spacing: 15px 0;
    font-size: 12px;
    min-width: 650px;

    thead {
      font-style: italic;
      & > th {
        font-size: 14px;
        height: 25px;
      }

      ${(props) =>
        props.fixedHeader &&
        `
        position: sticky;
        top: 29px;
        background-color: white;
      `}
    }

    tbody {
      & > tr:first-child {
        border-top: 2px solid transparent;
      }

      tr {
        td:nth-child(1),
        td:nth-child(4),
        td:nth-child(5) {
          font-weight: bold;
        }
        & > td {
          height: 27px;
          font-size: 11px;
        }
      }
    }
  }
`;

export const BSItemTable = styled.tr<{ background?: string; color?: string }>`
  background-color: ${(props) => props.background || "#a6a6a6"};
  color: ${(props) => props.color || "white"};
  min-width: 157.5px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 25px;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 2px;
  justify-content: center;
`;

export const TableGraphics = styled.table`
  tbody > tr > td:last-child div:first-child {
    width: 100%;
  }

  & > thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1000;

    span {
      text-align: left;
      width: 100%;
      font-size: 12px;
      font-weight: 400;
    }

    th:last-child {
      padding-right: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  & > thead > th {
    text-align: center;
    font-size: 12px;
    padding-right: 5px;
    min-width: 164.8px;

    & > div {
      display: grid;
      grid-template-columns: 160px 160px;
    }

    p {
      display: flex;
      align-items: center;
      padding-left: 10px;
      height: 25.5px;
      font-size: 14px;
      white-space: nowrap;
      font-style: italic;
    }

    & > p:first-child {
      background: #e7e6e6;
      height: 29px;
      align-items: center;
      display: flex;
      justify-content: center;
      font-style: normal;
    }

    & > p > span {
      font-size: 9px;
    }
  }

  & > tbody > tr > td {
    min-width: 124px;
    padding: 0;
    padding-right: 5px;
    position: relative;

    div {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 97%;
    }
  }

  & > tbody > tr > td > tr:first-child,
  & > tbody > tr > td > td > tr:first-child {
    margin-top: 2px;
  }

  .doubleColumn {
    display: flex;

    & > td {
      display: flex;
      flex-direction: column;
    }

    & > td:first-child {
      margin-right: 5px;
    }
  }

  & > tbody > tr > td:last-child {
    padding-right: 0;
  }
`;

export const BSConteinerColumn = styled.div`
  height: 100%;
  display: flex;
  min-width: 120px;
  flex-direction: column;
  justify-content: space-between;
`;

export const Item = styled.p`
  background: #a6a6a6;
  color: #fff;
  font-size: 12px;
  text-align: center;
  height: 21.48px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-top: 2px;
  }

  &:last-child {
    margin-bottom: 1px;
  }

  & > img {
    width: 15px;
    margin-right: 3px;
    fill: red;
  }
`;

export const TooltipText = styled.div`
  /* background: rgba(28, 56, 151, 0.9); */
  /* color: #fff; */
  /* width: 200px; */
  /* text-align: center; */
  /* line-height: 44px;
  border-radius: 3px; */
  cursor: pointer;

  .column-subview-header {
    position: absolute;
  }
`;

export const TooltipBox = styled.div`
  position: absolute;

  span {
    text-align: left;
    width: 100%;
    font-size: 12px;
    font-weight: 400;

    /* & + span {
      margin-top: 0.5rem;
    } */
  }

  top: calc(100% + 10px);
  left: -50px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 300px;
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 115px;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;

export const TooltipCard = styled.div`
  position: relative;
  z-index: 100;

  p {
    margin-bottom: 0.5rem;
  }

  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(28, 56, 151, 0.9);
    width: 250px;
    padding: 8px 8px;
    :before {
      border-color: transparent transparent rgba(28, 56, 151, 0.9)
        rgba(28, 56, 151, 0.9);
    }
  }
`;

export const ConteinerSelectProduct = styled.div<{ width?: string }>`
  background-color: #e7e6e6;
  width: ${(props) => props.width};
  margin-left: 10px;
  display: flex;
  align-items: center;

  select {
    margin: 5px 10px;
  }

  img {
    width: 45px;
  }
`;

export const FormDetail = styled.div`
  height: auto;
  width: calc(100% - 5px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
  padding-top: 5px;
`;

export const ButtonDetail = styled.div`
  width: 165px;
  background-color: lightgray;
  display: flex;
  padding: 7px 10px;
  align-items: center;
  position: relative;

  img {
    width: 52px;
    right: 0;
    position: absolute;
  }
`;
