import { Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { useRequestBottomTableYellowPharma } from "../../../services/Hooks/MyExecution/useRequestBottomTableYellowPharma";
import { useRequestYellowPharmaTable } from "../../../services/Hooks/MyExecution/useRequestYellowPharmaTable";
import { INavContent } from "../../../store/Navigation/types";
import { IStateFilters } from "../../../store/SideFilters/types";
import Card from "../../Card";
import SeeDetailButton from "../../SeeDetailButton";
import TableComplements from "../../TableComplements";
import TableWithGraphic from "../../TableWithGraphic";
import { ESelectedFirstLine, ISelectedCards } from "../CustomMainCards";

interface IOwnProps {
  SFilters: IStateFilters;
  selections: ISelectedCards;
}

const YellowPharmaTable: React.FC<IOwnProps> = ({ SFilters, selections }) => {
  const yellowPharmacyTable = useRequestYellowPharmaTable(
    selections.selectPeriod,
    SFilters
  );
  const bottomTableYellowPharm = useRequestBottomTableYellowPharma(
    selections.selectPeriod,
    SFilters
  );

  return (
    <>
      <TableComplements
        noFilter
        titleCard="Farmácia AMARELA"
        subTitleCard="(Mensal)"
        data={yellowPharmacyTable.data}
        isLoading={yellowPharmacyTable.isLoading}
      />
      {selections.selectedCard === ESelectedFirstLine.YELLOW_PHARMA && (
        <Card
          title="Top 20 LOJAS Ofensores - Farmácia AMARELA"
          bg="transparent"
        >
          <Flex w="100%">
            <Stack w="100%" bg="white" mr="5px" pt="40px">
              {/* <TemporalFilter
                      temporalFilter={selections.selectedTemporalFilter}
                      setTemporalFilter={setTemporalFilter}
                      itens={["MAT", "YTD", "U3M", "LP"]}
                    /> */}
              <TableWithGraphic
                isLoading={
                  bottomTableYellowPharm.isLoading &&
                  !bottomTableYellowPharm.data
                }
                tableData={bottomTableYellowPharm.data?.data_1}
              />
            </Stack>
            <Stack w="100%" bg="white">
              <Flex justifyContent="space-between" align="center">
                {/* <TemporalFilter
                        temporalFilter={selections.selectedTemporalFilter}
                        setTemporalFilter={setTemporalFilter}
                        itens={["MAT", "YTD", "U3M", "LP"]}
                      /> */}
                <Flex />
                <Flex>
                  <SeeDetailButton
                    mw="167px"
                    label="Ver detalhe Lojas"
                    goPage={INavContent.ME_DETAIL_STORE}
                  />
                </Flex>
              </Flex>
              <TableWithGraphic
                isLoading={
                  bottomTableYellowPharm.isLoading &&
                  !bottomTableYellowPharm.data
                }
                tableData={bottomTableYellowPharm.data?.data_2}
              />
            </Stack>
          </Flex>
        </Card>
      )}
    </>
  );
};

export default YellowPharmaTable;
