import {
  Box,
  Flex,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ITypeGraphicHorizontalBar } from "../../services/Hooks/OnePage/onePageTypes";
import { theme } from "../../styles/theme";
import HeaderGraphic from "../Graphics/HeaderGraphic";
import MarketShareValue from "../Graphics/MarketShareValue/MarketShareValue";
import LoadingComponent from "../LoadingComponent";
import Column from "../Table/Column";
import HeaderTable from "../Table/Header";

export interface ITableData {
  header: string[];
  data: string[][];
  growth: ITypeGraphicHorizontalBar;
}

interface IOwnProps {
  tableData?: ITableData;
  isLoading: boolean;
}

const TableWithGraphic: React.FC<IOwnProps> = ({
  tableData,
  isLoading,
}: IOwnProps) => {
  return (
    <TableContainer margin="5px !important">
      {(isLoading && <LoadingComponent />) || (
        <Flex>
          <Table style={{ borderCollapse: "separate" }}>
            <Thead>
              <Tr>
                {tableData?.header.map((header, index) => {
                  return (
                    index !== tableData.header.length - 1 && (
                      <HeaderTable
                        key={Math.random()}
                        isTextCenter={index !== 0}
                      >
                        {header}
                      </HeaderTable>
                    )
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {tableData?.data.map((row) => {
                return (
                  <Tr key={Math.random()}>
                    {row.map((columnValue, index) => (
                      <Column
                        mw={
                          (index === 0 && "250px") ||
                          (index === row.length && "500px") ||
                          "110px"
                        }
                        isTextCenter={index !== 0}
                        key={Math.random()}
                        isUppercase={index === 0}
                        bg={index === row.length - 1 ? "#AFABAB" : ""}
                        color={index === row.length - 1 ? "white" : "black"}
                        isBold={index === row.length}
                        value={columnValue}
                        maxCharacter={index === 0 ? 50 : 15}
                      />
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Stack mt="1px">
            <HeaderGraphic mw="170px">
              {tableData?.header[tableData.header.length - 1] || "--------"}
            </HeaderGraphic>
            <Box mt="0px !important">
              <MarketShareValue
                dataGraphic={tableData?.growth}
                color={theme.graphics.light}
                height={343}
              />
            </Box>
          </Stack>
        </Flex>
      )}
    </TableContainer>
  );
};

TableWithGraphic.defaultProps = {
  tableData: {
    data: [],
    header: [],
    growth: {
      label: [],
      x: [],
      y: [],
    },
  },
};

export default TableWithGraphic;
