import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

import {
  ITypeGraphicPeriod,
  ITypeGraphicPeriodBar,
} from "../OnePage/onePageTypes";

interface IShareOfShelf {
  periodBar: ITypeGraphicPeriod;
  rightBar: ITypeGraphicPeriodBar;
  leftBar: ITypeGraphicPeriodBar;
}

const requestShareOfShelf = async (
  period: string,
  filters: IStateFilters
): Promise<IShareOfShelf> => {
  const shareOfShelf = await api.get<{ data: IShareOfShelf }>(
    `share_of_shelf/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  return shareOfShelf.data.data;
};

export const useRequestShareOfShelf = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IShareOfShelf, unknown> => {
  return useQuery(
    ["ShareOfShelf", period, filters],
    () => requestShareOfShelf(period, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
