import { useQuery, UseQueryResult } from "react-query";
import { IDataFormat } from "../../../components/TableComplements";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

const requestYellowPharmaTable = async (
  period: string,
  filters: IStateFilters
): Promise<IDataFormat> => {
  const { data } = await api.get<{ data: IDataFormat }>(
    `yellow_pharmacy_tables/?period=${period}`,
    {
      params: {
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director: filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep: filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  return data.data;
};

export const useRequestYellowPharmaTable = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IDataFormat, unknown> => {
  return useQuery(
    ["yellowPharmaTable", period, filters],
    () => requestYellowPharmaTable(period, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
