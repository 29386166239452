import { Text, View } from "@react-pdf/renderer";
import React from "react";

interface IOwnProps {
  numberCard: string;
  ml?: string;
}

const CardEmpty: React.FC<IOwnProps> = ({ numberCard, ml }: IOwnProps) => {
  return (
    <View
      style={{
        width: "138px",
        height: "240px",
        border: "1px solid #dbdbdb",
        marginTop: "1px",
        backgroundColor: "white",
        justifyContent: "center",
        textAlign: "center",
        marginLeft: ml,
      }}
    >
      <Text style={{ color: "#dbdbdb", fontWeight: "bold", fontSize: "14" }}>
        {`FOTO ${numberCard}`}
      </Text>
    </View>
  );
};

CardEmpty.defaultProps = {
  ml: "0px",
};

export default CardEmpty;
