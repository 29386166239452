import { INavPage } from "../../Navigation/types";

export enum actionsType {
  REQUEST_REFRESH_TOKEN = "@Profile/REQUEST_REFRESH_TOKEN",
  REFRESH_TOKEN_SUCCESS = "@Profile/REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_ERROR = "@Profile/REFRESH_TOKEN_ERROR",
}

export interface IRequestRefreshToken {
  type: actionsType.REQUEST_REFRESH_TOKEN;
  payload: login;
}

export interface ISuccessRefreshToken {
  type: actionsType.REFRESH_TOKEN_SUCCESS;
}

export interface IErrorRefreshToken {
  type: actionsType.REFRESH_TOKEN_ERROR;
}

export interface login {
  email: string;
  password: string;
  navigator: (indexPage: INavPage) => void;
}

export interface tokens {
  access: string;
  refresh: string;
}

export interface stateAuth {
  data: tokens;
  error: boolean;
  isLoading: boolean;
  loaded: boolean;
}
