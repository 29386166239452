import { Flex, Stack, Text } from "@chakra-ui/react";
import { BsArrowUpCircle } from "react-icons/bs";
import React, { useState } from "react";
import { theme } from "../../styles/theme";

interface IOwnProps {
  label: string;
  children: JSX.Element;
  h?: string;
}

const ItemListProfile: React.FC<IOwnProps> = ({
  label,
  children,
  h,
}: IOwnProps): JSX.Element => {
  const [showItem, setShowItem] = useState<boolean>(false);

  return (
    <Stack
      h={showItem ? h : "50px"}
      overflow="hidden"
      border="1px solid lightgray"
      px="10px"
      borderRadius={5}
      transition="0.5s"
    >
      <Flex
        minH="50px"
        flexDirection="row"
        align="center"
        justify="space-between"
        cursor="pointer"
        onClick={() => setShowItem(!showItem)}
      >
        <Text fontSize="14px" color="black">
          {label}
        </Text>
        <BsArrowUpCircle
          style={{
            transform: (showItem && "rotate(0deg)") || "rotate(180deg)",
            transition: "0.3s",
          }}
          size={20}
          color={theme.colors.arrow}
        />
      </Flex>
      <Flex pb="15px">{children}</Flex>
    </Stack>
  );
};

ItemListProfile.defaultProps = {
  h: undefined,
};

export default ItemListProfile;
