/* eslint-disable camelcase */
import { useQuery, UseQueryResult } from "react-query";
import {
  IFilter,
  IStateFilters,
  IFormatFilters,
} from "../../../store/SideFilters/types";
import api from "../../api";

interface ICategorieData {
  image: string;
  names: string[];
}

interface IIndicatorsData {
  mat: string;
  U3M: string;
  lp: string;
  ytd: string;
}

export interface IMultipleFilter {
  brand: IFilter;
  segment: IFormatFilters;
}

interface IResponseFormatData {
  categories: ICategorieData;
  indicators: {
    extra_exposure: IIndicatorsData[];
    presences: IIndicatorsData[];
    sos: IIndicatorsData[];
  };
}

const requestExecutionIndicator = async (
  filters: IStateFilters,
  internalFilter: IFilter
): Promise<IResponseFormatData> => {
  const response = await api.get<IResponseFormatData>(
    "one_page_execution_indicators/",
    {
      params: {
        businessUnit: filters.filter.businessUnitId.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        category: filters.filter.category.selectedFilterId || null,
        subCategory: internalFilter.id || null,
      },
    }
  );

  return response.data;
};

export const useExecutionIndicator = (
  filters: IStateFilters,
  internalFilter: IFilter
): UseQueryResult<IResponseFormatData> => {
  return useQuery(
    ["executionIndicator", filters, internalFilter],
    () => requestExecutionIndicator(filters, internalFilter),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};
