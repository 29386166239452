import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Box, Flex, Grid, Image, Stack, Text } from "@chakra-ui/react";
import { BSLegendTable } from "./datailKPI.element";
import selectABrand from "../../../../../assets/Industria/logo.png";
import * as ANavigator from "../../../../../store/Navigation/actions";
import * as AFilters from "../../../../../store/SideFilters/actions";
import {
  ENavSubMenu,
  filterComponent,
  INavContent,
} from "../../../../../store/Navigation/types";

import { Divisor } from "../../../../../components/Contents/subMenu";

import excel from "../../../../../assets/MyDay/excel.svg";
import lavarageIcon from "../../../../../assets/MyDay/iconLavarage.svg";
import kpIcon from "../../../../../assets/MyDay/kpi.svg";
import calendarIcon from "../../../../../assets/MyDay/calendar.svg";
import Less from "../../../../../assets/img/icons/minus-circle.png";
import More from "../../../../../assets/img/icons/plus-circle.png";
import ShareValue from "../../../../../components/Graphics/MarketShareValue/MarketShareValue";

import { WrapperCardSubMenu } from "../../../../../components/Contents/Resources/SubView/subView.element";
import ListBox, { IItemFilter } from "../../../../../components/ListBox";

import {
  TableSubView,
  SubMenuItem,
} from "../../../../../components/Contents/Resources/SubView/subView";

import { GlobalProps } from "../../../../../store";
import TemporalFilter from "../../../../../components/TemporalFilter";
import {
  filter,
  stateMarketShare,
} from "../../../../../store/SideFilters/RequestFilters/types";
import Column from "../../../../../components/DetailPages/Columns";
import FiltersList, {
  INITIAL_FILTERS,
  IShowFilters,
} from "../../../../../components/SideFilterMenu/Resource/FiltersList";
import {
  IProductFilter,
  IStateFilters,
  TPromoFilter,
} from "../../../../../store/SideFilters/types";
import { ConfigContext } from "../../../../../services/Context/ConfigContext";
import { EPeriod } from "../../../../../components/CardSubMenu";
import { ELavarage } from "../../../../../services/Hooks/MyExecution/useRequestKpiStore";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeTitleMenu(newTitle: string): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeFilters(filters: filterComponent[]): void;
  };

  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };
}

type props = IStateProps & IDispatchProps;

const DetailKPI: React.FC<props> = ({
  DNavigator,
  SAllFilters,
  SFilters,
  DFilters,
}): JSX.Element => {
  const { config } = useContext(ConfigContext);

  const [widthContent, setWidthContent] = useState<number>(0);
  const [limitItem, setLimitItem] = useState<number>(50);
  const refDivContent = useRef<HTMLDivElement>(null);
  const [temporalFilter, setTemporalFilter] = useState<filter>({
    id: EPeriod.MONTHLY,
    label: "Mensal",
  });
  const [lavarageFilter, setLavarageFilter] = useState<filter>({
    id: ELavarage.PRESENCA,
    label: "Presenca",
  });
  const [heightGraphics, setHeightGraphics] = useState<number>(0);
  const refTable = useRef<HTMLTableElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);

  let headers: string[];

  let mockDataTable: any[];

  if (lavarageFilter.id !== ELavarage.PRESENCA) {
    headers = ["#", "Indicador de Performance", "Marca", "Fabricante"];
    mockDataTable = [
      {
        order: 1,
        storesIds: "SOS - Assaduras",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 2,
        storesIds: "SOS - Vitaminas",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 3,
        storesIds: "SOS - Sabonete intimo",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 4,
        storesIds: "SOS - SOS - Cuidados com o rosto",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 5,
        storesIds: "SOS - Assaduras",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 6,
        storesIds: "SOS - Vitaminas",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 7,
        storesIds: "SOS - Repelentes",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 8,
        storesIds: "SOS - Sabonete intimo",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 9,
        storesIds: "SOS - Assaduras",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 10,
        storesIds: "SOS - SOS - Cuidados com o rosto",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 11,
        storesIds: "SOS - Repelentes",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 12,
        storesIds: "SOS - Cuidados com a pele",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 13,
        storesIds: "SOS - Assaduras",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 14,
        storesIds: "SOS - Repelentes",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 15,
        storesIds: "SOS - SOS - Cuidados com o rosto",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 16,
        storesIds: "SOS - Sabonete intimo",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 17,
        storesIds: "SOS - Assaduras",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 18,
        storesIds: "SOS - Vitaminas",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 19,
        storesIds: "SOS - Cuidados com a pele",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 20,
        storesIds: "SOS - Repelentes",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 21,
        storesIds: "SOS - Cuidados com o rosto",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 22,
        storesIds: "SOS - Cuidados com a pele",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 23,
        storesIds: "SOS - Sabonete intimo",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 24,
        storesIds: "SOS - Assaduras",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 25,
        storesIds: "SOS - Repelentes",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 26,
        storesIds: "SOS - Vitaminas",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 27,
        storesIds: "SOS - Cuidados com a pele",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 28,
        storesIds: "SOS - Sabonete intimo",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 29,
        storesIds: "SOS - Repelentes",
        storeNames: "-",
        storeBanners: "-",
      },
      {
        order: 30,
        storesIds: "SOS - Vitaminas",
        storeNames: "-",
        storeBanners: "-",
      },
    ];
  } else {
    headers = ["#", "EAN", "Descricao do produto", "Marca", "Categoria"];
    mockDataTable = [
      {
        order: 1,
        storesIds: "768567574554643453",
        storeNames: "Produto A",
        storeBanners: "Marca 1",
        storeUf: "Categoria 1",
      },
      {
        order: 2,
        storesIds: "767834683746256236",
        storeNames: "Produto B",
        storeBanners: "Marca 2",
        storeUf: "Categoria 2",
      },
      {
        order: 3,
        storesIds: "26456752T684736784",
        storeNames: "Produto C",
        storeBanners: "Marca 3",
        storeUf: "Categoria 3",
      },
      {
        order: 4,
        storesIds: "678684763847685665",
        storeNames: "Produto D",
        storeBanners: "Marca 4",
        storeUf: "Categoria 4",
      },
      {
        order: 5,
        storesIds: "129763874638263382",
        storeNames: "Produto E",
        storeBanners: "Marca 5",
        storeUf: "Categoria 5",
      },
      {
        order: 6,
        storesIds: "476684568376454664",
        storeNames: "Produto F",
        storeBanners: "Marca 6",
        storeUf: "Categoria 6",
      },
      {
        order: 7,
        storesIds: "768567574554643453",
        storeNames: "Produto A",
        storeBanners: "Marca 7",
        storeUf: "Categoria 7",
      },
      {
        order: 8,
        storesIds: "767834683746256236",
        storeNames: "Produto B",
        storeBanners: "Marca 8",
        storeUf: "Categoria 8",
      },
      {
        order: 9,
        storesIds: "26456752T684736784",
        storeNames: "Produto C",
        storeBanners: "Marca 9",
        storeUf: "Categoria 9",
      },
      {
        order: 10,
        storesIds: "678684763847685665",
        storeNames: "Produto D",
        storeBanners: "Marca 10",
        storeUf: "Categoria 10",
      },
      {
        order: 11,
        storesIds: "129763874638263382",
        storeNames: "Produto E",
        storeBanners: "Marca 11",
        storeUf: "Categoria 11",
      },
      {
        order: 12,
        storesIds: "476684568376454664",
        storeNames: "Produto F",
        storeBanners: "Marca 12",
        storeUf: "Categoria 12",
      },
      {
        order: 13,
        storesIds: "768567574554643453",
        storeNames: "Produto A",
        storeBanners: "Marca 13",
        storeUf: "Categoria 13",
      },
      {
        order: 14,
        storesIds: "767834683746256236",
        storeNames: "Produto B",
        storeBanners: "Marca 14",
        storeUf: "Categoria 14",
      },
      {
        order: 15,
        storesIds: "26456752T684736784",
        storeNames: "Produto C",
        storeBanners: "Marca 15",
        storeUf: "Categoria 15",
      },
      {
        order: 16,
        storesIds: "678684763847685665",
        storeNames: "Produto D",
        storeBanners: "Marca 16",
        storeUf: "Categoria 16",
      },
      {
        order: 17,
        storesIds: "129763874638263382",
        storeNames: "Produto E",
        storeBanners: "Marca 17",
        storeUf: "Categoria 17",
      },
      {
        order: 18,
        storesIds: "476684568376454664",
        storeNames: "Produto F",
        storeBanners: "Marca 18",
        storeUf: "Categoria 18",
      },
      {
        order: 19,
        storesIds: "768567574554643453",
        storeNames: "Produto A",
        storeBanners: "Marca 19",
        storeUf: "Categoria 19",
      },
      {
        order: 20,
        storesIds: "767834683746256236",
        storeNames: "Produto B",
        storeBanners: "Marca 20",
        storeUf: "Categoria 20",
      },
      {
        order: 21,
        storesIds: "26456752T684736784",
        storeNames: "Produto C",
        storeBanners: "Marca 21",
        storeUf: "Categoria 21",
      },
      {
        order: 22,
        storesIds: "678684763847685665",
        storeNames: "Produto D",
        storeBanners: "Marca 22",
        storeUf: "Categoria 22",
      },
      {
        order: 23,
        storesIds: "129763874638263382",
        storeNames: "Produto E",
        storeBanners: "Marca 23",
        storeUf: "Categoria 23",
      },
      {
        order: 24,
        storesIds: "476684568376454664",
        storeNames: "Produto F",
        storeBanners: "Marca 24",
        storeUf: "Categoria 24",
      },
      {
        order: 25,
        storesIds: "768567574554643453",
        storeNames: "Produto A",
        storeBanners: "Marca 25",
        storeUf: "Categoria 25",
      },
      {
        order: 26,
        storesIds: "767834683746256236",
        storeNames: "Produto B",
        storeBanners: "Marca 26",
        storeUf: "Categoria 26",
      },
      {
        order: 27,
        storesIds: "26456752T684736784",
        storeNames: "Produto C",
        storeBanners: "Marca 27",
        storeUf: "Categoria 27",
      },
      {
        order: 28,
        storesIds: "678684763847685665",
        storeNames: "Produto D",
        storeBanners: "Marca 28",
        storeUf: "Categoria 28",
      },
      {
        order: 29,
        storesIds: "129763874638263382",
        storeNames: "Produto E",
        storeBanners: "Marca 29",
        storeUf: "Categoria 29",
      },
      {
        order: 30,
        storesIds: "476684568376454664",
        storeNames: "Produto F",
        storeBanners: "Marca 30",
        storeUf: "Categoria 30",
      },
    ];
  }

  useEffect(() => {
    DNavigator.changeTitleMenu("Minha Execucao | Presenca | Visão Loja");
  }, [DNavigator]);

  const montListColor = (data: number[]): string[] => {
    const colorsList = data.map((item) => {
      return item >= 0 ? "rgb(0, 176, 240)" : "rgb(192, 0, 0)";
    });
    return colorsList;
  };

  const handleLimitItem = (e: ChangeEvent<HTMLSelectElement>) => {
    const limitSelected = parseInt(e.currentTarget.value, 10);
    setLimitItem(limitSelected);
  };

  setTimeout(() => {
    if (refDivContent.current !== null) {
      const widthDiv = refDivContent.current.clientWidth - 148.5;
      if (widthDiv !== widthContent) {
        setWidthContent(widthDiv);
      }
    }

    if (refTable.current) {
      const heightDiv = refTable.current.clientHeight - 31;
      setHeightGraphics(heightDiv);
    }
  });

  const generateListFilter = (): IItemFilter[] => {
    return [
      {
        selected: "",
        id: "filterProduct",
        onChange: () => console.log("lol"),
        optionSelect:
          SAllFilters.data.myExecution.subviewOSA.main.executions_type || [],
      },
    ];
  };

  const renderFilter = useCallback(() => {
    const showFilters: IShowFilters = {
      ...INITIAL_FILTERS,
      // category: true,
      // subCategory: true,
      subChannel: true,
      banner: true,
      region: true,
      uf: true,
      mainCategory: true,
      isPromo: true,
      commercialStructureDirector: true,
      commercialStructureManager: true,
      commercialStructureSales: true,
      storeCNPJ: true,
      // merchandising: true,
    };

    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(SAllFilters, SFilters, showFilters, DFilters)
      );
  }, [SAllFilters, SFilters]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  return (
    <Grid
      templateRows="auto 1fr"
      h="calc(100% - 120px)"
      m="5px !important"
      overflowY="hidden"
    >
      <Box w="max-content" minW="100%">
        <Flex
          bg="white"
          color="black"
          h="85px"
          alignItems="center"
          px="4px"
          ref={refSubMenu}
          pr="20px"
        >
          <Image src={selectABrand} width="142px" p={5} />
          <Divisor />
          <BSLegendTable minWidth={`${widthContent || 550}px`}>
            <SubMenuItem
              text="Total de SKUs"
              bold
              value="45"
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="SKUs c/ GAP"
              value="0"
              icon={More}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="SKUs s/ GAP"
              value="200"
              icon={Less}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Performance"
              bold
              noMargin
              value="0.0%"
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
          </BSLegendTable>
          <WrapperCardSubMenu
            gridColumns={`repeat(${
              temporalFilter.id === EPeriod.WEEKLY ? 7 : 5
            }, 1.5fr)`}
            margin="0 12.5px 0 0"
          >
            <SubMenuItem
              font="14px"
              text={`${
                lavarageFilter.id !== ELavarage.PRESENCA
                  ? "#Skus Ativos"
                  : "#Lojas Ativas"
              }`}
              bold
              noMargin
              value="-"
              minWidth="160px"
              subTitleBold
            />
            <SubMenuItem
              font="14px"
              text={`% Presenca (${
                temporalFilter.id === EPeriod.WEEKLY ? "MTD" : "Act"
              })`}
              bold
              noMargin
              value="82,3%"
              minWidth="160px"
              subTitleBold
            />

            <SubMenuItem
              text={`% Presenca (${
                temporalFilter.id === EPeriod.WEEKLY ? "W49" : "LM"
              })`}
              bold
              noMargin
              font="14px"
              value="94,3%"
              minWidth="160px"
              subTitleBold
            />

            <SubMenuItem
              text={`% Presenca (${
                temporalFilter.id === EPeriod.WEEKLY ? "W48" : "L3M"
              })`}
              bold
              noMargin
              font="14px"
              value="88,7%"
              minWidth="160px"
              subTitleBold
            />

            <SubMenuItem
              text={`% Presença (${
                temporalFilter.id === EPeriod.WEEKLY ? "W47" : "YTD"
              })`}
              font="14px"
              bold
              noMargin
              value="45,2%"
              minWidth="160px"
              subTitleBold
            />
            {temporalFilter.id === EPeriod.WEEKLY && (
              <>
                <SubMenuItem
                  text="% Presenca (W46)"
                  bold
                  noMargin
                  font="14px"
                  value="88,7%"
                  minWidth="160px"
                  subTitleBold
                />

                <SubMenuItem
                  text="% Presença (W45)"
                  font="14px"
                  bold
                  noMargin
                  value="45,2%"
                  minWidth="160px"
                  subTitleBold
                />
              </>
            )}
          </WrapperCardSubMenu>
        </Flex>
        <Flex
          position="relative"
          mt="5px !important"
          h="45px"
          bg="#000"
          w="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Flex position="fixed">
            <Text fontSize="20px" fontWeight="bold">
              Detalhamento do MSL % TOP 50 Lojas Ofensoras | Bandeira: Todas |
              Região: Todas
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Stack bg="white" mt="5px !important" p="5px" overflow="hidden">
        <Flex>
          <Flex
            w={`${widthContent + 148}px`}
            p="10px"
            justifyContent="space-between"
          >
            <Flex>
              <Image src={calendarIcon} alt="logo-store" w="40px" />
              <TemporalFilter
                setTemporalFilter={setTemporalFilter}
                temporalFilter={temporalFilter}
                itens={[
                  { id: EPeriod.MONTHLY, label: "Mensal" },
                  { id: EPeriod.WEEKLY, label: "Semanal" },
                ]}
              />
            </Flex>
            <Flex>
              <Image src={kpIcon} alt="logo-store" w="40px" />
              <TemporalFilter
                setTemporalFilter={setLavarageFilter}
                temporalFilter={lavarageFilter}
                itens={[
                  { id: ELavarage.PRESENCA, label: "Presenca" },
                  { id: ELavarage.GONDOLA, label: "Gondola" },
                  { id: ELavarage.PONTO_EXTRA, label: "Ponto Extra" },
                ]}
              />
            </Flex>
          </Flex>
          <Flex
            flex={1}
            justifyContent={
              lavarageFilter.id !== ELavarage.PRESENCA
                ? "space-between"
                : "flex-end"
            }
            pl="20px"
          >
            {lavarageFilter.id !== ELavarage.PRESENCA && (
              <ListBox
                itens={generateListFilter()}
                icon={lavarageIcon}
                iconSize="50px"
                minWidth="200px"
              />
            )}
            <Flex align="center" cursor="pointer">
              <Text fontWeight="bold" pr="15px" color="black">
                Exportar para
              </Text>
              <Image src={excel} w="50px" h="50px" />
            </Flex>
          </Flex>
        </Flex>
        <Flex overflowX="hidden">
          <Stack ref={refDivContent} minW="630px">
            <Flex backgroundColor="#e7e6e6" p="8px 12px" alignItems="center">
              <Text color="#c00000" fontWeight="bold" fontSize="14px">
                Top {limitItem} Lojas OFENSORAS
              </Text>
            </Flex>
            <TableSubView
              refElement={refTable}
              headers={headers}
              dataTable={mockDataTable}
              collapse
            />
          </Stack>
          <Column
            title="-"
            subTitle={`${
              lavarageFilter.id !== ELavarage.PRESENCA
                ? "#Marcas Ativas"
                : "#Lojas Ativas"
            }`}
            itens={[
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
              "12 / 18",
            ]}
          />
          <Column
            title="77,5%"
            subTitle={`% Presença (${
              temporalFilter.id === EPeriod.WEEKLY ? "MTD" : "Act"
            })`}
          >
            <Stack mt="-1px !important">
              <ShareValue
                dataGraphic={{
                  label: [
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                  ],
                  x: [
                    80, 72, 80, 91, 59, 60, 80, 72, 80, 91, 59, 60, 80, 72, 80,
                    91, 59, 60, 80, 72, 80, 91, 59, 60, 80, 72, 80, 91, 59, 60,
                  ],
                  y: [
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "80,0%",
                    "72,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                  ],
                }}
                height={heightGraphics}
                gap={0.07}
                color="#a6a6a6"
              />
            </Stack>
          </Column>
          <Column
            title="88,5%"
            subTitle={`% Presença (${
              temporalFilter.id === EPeriod.WEEKLY ? "W49" : "LM"
            })`}
          >
            <Stack ref={refSubMenu} mt="-1px !important">
              <ShareValue
                dataGraphic={{
                  label: [
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                  ],
                  x: [
                    83, 94, 89, 82, 94, 78, 83, 94, 89, 82, 94, 78, 83, 94, 89,
                    82, 94, 78, 83, 94, 89, 82, 94, 78, 83, 94, 89, 82, 94, 78,
                  ],
                  y: [
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                    "83,0%",
                    "94,0%",
                    "89,0%",
                    "82,0%",
                    "94,0%",
                    "78,0%",
                  ],
                }}
                height={heightGraphics}
                gap={0.07}
                color="#1f497d"
              />
            </Stack>
          </Column>
          <Column
            title="81,0%"
            subTitle={`% Presença (${
              temporalFilter.id === EPeriod.WEEKLY ? "W48" : "L3M"
            })`}
          >
            <Stack ref={refSubMenu} mt="-1px !important">
              <ShareValue
                dataGraphic={{
                  label: [
                    "81,0%",
                    "81,0%",
                    "85,0%",
                    "87,0%",
                    "89,0%",
                    "93,0%",
                    "81,0%",
                    "81,0%",
                    "85,0%",
                    "87,0%",
                    "89,0%",
                    "93,0%",
                    "81,0%",
                    "81,0%",
                    "85,0%",
                    "87,0%",
                    "89,0%",
                    "93,0%",
                    "81,0%",
                    "81,0%",
                    "85,0%",
                    "87,0%",
                    "89,0%",
                    "93,0%",
                    "81,0%",
                    "81,0%",
                    "85,0%",
                    "87,0%",
                    "89,0%",
                    "93,0%",
                  ],
                  x: [
                    81, 81, 85, 87, 89, 93, 81, 81, 85, 87, 89, 93, 81, 81, 85,
                    87, 89, 93, 81, 81, 85, 87, 89, 93, 81, 81, 85, 87, 89, 93,
                  ],
                  y: [
                    "81,0%",
                    "81,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "81,0%",
                    "81,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "81,0%",
                    "81,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "81,0%",
                    "81,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                    "81,0%",
                    "81,0%",
                    "80,0%",
                    "91,0%",
                    "59,0%",
                    "60,0%",
                  ],
                }}
                height={heightGraphics}
                gap={0.07}
              />
            </Stack>
          </Column>
          <Column
            title="90,0%"
            subTitle={`% Presença (${
              temporalFilter.id === EPeriod.WEEKLY ? "W47" : "YTD"
            })`}
          >
            <Stack ref={refSubMenu} mt="-1px !important">
              <ShareValue
                dataGraphic={{
                  label: [
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                  ],
                  x: [
                    82, 98, 94, 91, 89, 83, 82, 98, 94, 91, 89, 83, 82, 98, 94,
                    91, 89, 83, 82, 98, 94, 91, 89, 83, 82, 98, 94, 91, 89, 83,
                  ],
                  y: [
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                    "82,0%",
                    "98,0%",
                    "94,0%",
                    "91,0%",
                    "89,0%",
                    "83,0%",
                  ],
                }}
                height={heightGraphics}
                gap={0.07}
              />
            </Stack>
          </Column>
          {temporalFilter.id === EPeriod.WEEKLY && (
            <>
              <Column title="90,0%" subTitle="% Presença (W46)">
                <Stack ref={refSubMenu} mt="-1px !important">
                  <ShareValue
                    dataGraphic={{
                      label: [
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                      ],
                      x: [
                        82, 98, 94, 91, 89, 83, 82, 98, 94, 91, 89, 83, 82, 98,
                        94, 91, 89, 83, 82, 98, 94, 91, 89, 83, 82, 98, 94, 91,
                        89, 83,
                      ],
                      y: [
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                      ],
                    }}
                    height={heightGraphics}
                    gap={0.07}
                  />
                </Stack>
              </Column>
              <Column title="90,0%" subTitle="% Presença (W45)">
                <Stack ref={refSubMenu} mt="-1px !important">
                  <ShareValue
                    dataGraphic={{
                      label: [
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                      ],
                      x: [
                        82, 98, 94, 91, 89, 83, 82, 98, 94, 91, 89, 83, 82, 98,
                        94, 91, 89, 83, 82, 98, 94, 91, 89, 83, 82, 98, 94, 91,
                        89, 83,
                      ],
                      y: [
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                        "82,0%",
                        "98,0%",
                        "94,0%",
                        "91,0%",
                        "89,0%",
                        "83,0%",
                      ],
                    }}
                    height={heightGraphics}
                    gap={0.07}
                  />
                </Stack>
              </Column>
            </>
          )}
        </Flex>
      </Stack>
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
    DFilters: bindActionCreators(AFilters, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailKPI);
