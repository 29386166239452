import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ConfigContext } from "../../services/Context/ConfigContext";
import { theme } from "../../styles/theme";

export enum ETypeLegend {
  bar = "bar",
  period = "period",
}

interface IOwnProps {
  typeLegend: string;
  children: string;
  ml?: string;
}

const LegendIcon: React.FC<IOwnProps> = ({
  typeLegend,
  ml,
  children,
}: IOwnProps) => {
  const { config } = useContext(ConfigContext);

  return (
    <>
      {(typeLegend === ETypeLegend.bar && (
        <Box w="36px" h="15px" bg={theme.graphics.light} />
      )) || (
        <Flex
          w="36px"
          position="relative"
          h="30px"
          align="center"
          justify="center"
          marginLeft={ml}
        >
          <Box w="36px" h="5px" bg={theme.graphics.period} />
          <Flex
            w="13px"
            h="13px"
            position="absolute"
            __css={{ transform: "rotate(45deg)" }}
            bg={theme.graphics.period}
          />
        </Flex>
      )}
      <Text
        color="black"
        as="span"
        mx="6px !important"
        fontSize={config.fonts.legends}
      >
        {children}
      </Text>
    </>
  );
};

LegendIcon.defaultProps = {
  ml: "0px",
};

export default LegendIcon;
