import styled from "styled-components";

export const ConteinerMonth = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  min-height: 91px;
  height: calc(100% - 5px);
`;

export const RowMonth = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 4px 0;

  & > :nth-child(-n + 3) {
    width: 33.3%;
  }

  & > :last-child {
    width: 34%;
  }
`;
