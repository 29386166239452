import React, { useState, useEffect, ReactNode } from "react";
import ReactDom from "react-dom";
import { FiAlertTriangle, FiCheckCircle, FiInfo } from "react-icons/fi";

import { ToastContainer, ToastFooterContainer, ToastContent } from "./styles";

interface ToastProps {
  showToast: boolean;
  setShowToast: () => void;
  toastType: string;
  width?: string;
  margin?: string;
  text: string;
  timeOnScreen?: number;
}

interface ToastFooterProps {
  children: ReactNode;
  margin: string;
  border: string;
}

export interface useToastHookProps {
  showToast: boolean;
  setShowToast: () => void;
}

const Toast: React.FC<ToastProps> = ({
  showToast,
  setShowToast,
  timeOnScreen,
  width,
  margin,
  text,
  toastType,
}) => {
  const [bgColorType, setBgColorType] = useState<string>();
  const [toastIcon, setToastIcon] = useState<JSX.Element>(<FiInfo />);

  useEffect(() => {
    const listner = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        e.preventDefault();
        e.stopPropagation();

        if (showToast) {
          setShowToast();
        }
      }
    };

    window.addEventListener("keyup", listner);

    return () => {
      window.removeEventListener("keyup", listner);
    };
  }, [showToast, setShowToast]);

  useEffect(() => {
    const switchToastTypes = async () => {
      switch (toastType) {
        case "info":
          setToastIcon(<FiInfo size={22} />);
          setBgColorType("#3699FF");

          break;

        case "error":
          setToastIcon(<FiAlertTriangle size={22} />);
          setBgColorType("#F64E60");

          break;
        case "success":
          setToastIcon(<FiCheckCircle size={22} />);
          setBgColorType("#1BC5BD");

          break;
        default:
          setToastIcon(<FiInfo size={22} />);
          setBgColorType("#3699FF");
      }
    };

    switchToastTypes();
  }, [toastType]);

  useEffect(() => {
    const closeToastAfterAppearWithMessage = () => {
      let timeOut = timeOnScreen;
      if (!timeOnScreen) {
        timeOut = 3500;
      }

      if (showToast) {
        setTimeout(() => {
          setShowToast();
        }, timeOut);
      }
    };

    closeToastAfterAppearWithMessage();
  }, [showToast, setShowToast, timeOnScreen]);

  return showToast
    ? ReactDom.createPortal(
        <ToastContainer bgColor={bgColorType} width={width} margin={margin}>
          <ToastContent>
            <div>
              <div>{toastIcon}</div>
              <div>
                <p>{text}</p>
              </div>
            </div>
          </ToastContent>
        </ToastContainer>,
        document.body
      )
    : null;
};

// Fazer o loading aqui no footer depois passando ele como children opcional do MODAL

export const ToastFooter: React.FC<ToastFooterProps> = ({
  children,
  margin,
  border,
}: ToastFooterProps) => (
  <ToastFooterContainer margin={margin} border={border}>
    {children}
  </ToastFooterContainer>
);

export const useToast = (): useToastHookProps => {
  const [showToast, setshowToast] = useState(false);

  function setShowToast() {
    setshowToast(!showToast);
  }

  return {
    showToast,
    setShowToast,
  };
};

export default Toast;
