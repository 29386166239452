export enum AProfileType {
  REQUEST_PROFILE = '@Profile/REQUEST_PROFILE',
  PROFILE_SUCCESS = '@Profile/PROFILE_SUCCESS',
  PROFILE_FAIL = '@Profile/PROFILE_FAIL',
}

export interface IProfileError {
  type: AProfileType.PROFILE_FAIL;
}

export interface IProfileSuccess {
  type: AProfileType.PROFILE_SUCCESS;
  payload: dataProfile;
}

export interface IRequestProfile {
  type: AProfileType.REQUEST_PROFILE;
}

export interface dataProfile {
  id: string;
  firstName: string;
  email: string;
  avatar: string;
  industrie: string;
  lastName: string;
  phoneNumber: string;
}

export interface stateProfile {
  data: dataProfile;
  isLoading: boolean;
  error: boolean;
}
