import React, { useContext } from "react";
import PlotlyChart from "react-plotlyjs-ts";
import { ConfigContext } from "../../../services/Context/ConfigContext";

// types
import { ITypeGraphicPeriod } from "../../../services/Hooks/OnePage/onePageTypes";

// configiguration platform
import { theme } from "../../../styles/theme";

interface ownProps {
  dataGraphic?: ITypeGraphicPeriod;
  ml?: number;
  mr?: number;
  h?: number;
  lineWidth?: boolean;
  autosize?: boolean;
}

const MarketSharePeriodLtyYtd: React.FC<ownProps> = ({
  dataGraphic,
  ml,
  mr,
  lineWidth,
  autosize,
  h,
}: ownProps) => {
  const { label, y } = dataGraphic ?? { label: [], y: [] };
  const xMax = Math.max.apply(null, y);
  const xMin = Math.min.apply(null, y);

  const { config } = useContext(ConfigContext);

  let graphicLegend: number[] = [];

  if (Math.abs(xMax) > Math.abs(xMin)) {
    graphicLegend = y.map(() => {
      return xMax + xMax * 0.3;
    });
  } else {
    const range = xMin * 0.3;
    graphicLegend = y.map((itemGraphic) => {
      return itemGraphic + Math.abs(range);
    });
  }

  const trace = {
    line: { width: (lineWidth && 5) || 0 },
    text: label,
    meta: {
      columnNames: {
        x: "A",
        y: "B",
        text: "C",
      },
    },
    mode: "markers+lines+text",
    type: "scatter",
    x: y.map((itens, index) => index),
    y,
    marker: {
      line: { width: 0 },
      size: 14,
      color: theme.graphics.period,
      symbol: "diamond",
      opacity: 1,
    },
    opacity: 1,
    hoverinfo: "x+text",
    textfont: { size: config.fonts.graphics, color: "#404040" },
    textposition: "top center",
  };

  const trace2 = {
    x: y.map((itens, index) => index),
    y: graphicLegend,
    line: { width: 0 },
    meta: {
      columnNames: {
        x: "A",
        y: "B",
        text: "C",
      },
    },
    mode: "markers+lines+text",
    marker: {
      line: { width: 0 },
      size: 14,
      color: "rgb(192, 0, 0)",
      symbol: "diamond",
      opacity: 0,
    },
    opacity: 1,
    hoverinfo: "skip",
  };

  const layout = {
    height: h,
    width: (!autosize && y.length === 1 && 56) || y.length * 56,
    font: { size: config.fonts.graphics },
    xaxis: {
      side: "bottom",
      type: "category",
      anchor: "y",
      showgrid: false,
      showline: false,
      zeroline: false,
      autorange: true,
      fixedrange: true,
      showticklabels: false,
    },
    yaxis: {
      type: "linear",
      anchor: "x",
      showgrid: false,
      showline: false,
      zeroline: false,
      autorange: true,
      fixedrange: true,
      showspikes: false,
      showticklabels: false,
    },
    margin: {
      b: 0,
      l: ml,
      r: mr,
      t: 0,
      pad: 0,
    },
    autosize: false,
    showlegend: false,
    clickmode: "event",
    uniformtext: { mode: false },
  };

  const data = [trace, trace2];
  const configGraphic = {
    displayModeBar: false,
  };

  return <PlotlyChart data={data} layout={layout} config={configGraphic} />;
};

MarketSharePeriodLtyYtd.defaultProps = {
  ml: 0,
  mr: 0,
  lineWidth: true,
  dataGraphic: {
    label: [],
    y: [],
  },
  autosize: false,
  h: 100,
};

export default MarketSharePeriodLtyYtd;
