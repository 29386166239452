import React, { useState } from "react";
import { RiDownloadLine, RiCloseLine } from "react-icons/ri";
import {
  BookCardContent,
  BookCardContentTitle,
  BookCardContentBody,
  BookCardContainerImage,
  BookCardContentImage,
  SubContainerImg,
  AclopButton,
} from "./styles";

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "../../../../../components/Modal";

import noImage from "../../../../../assets/img/icons/notImage.png";

import storeImg from "../../../../../assets/img/icons/small_store.png";

export interface ICardExecutionProps {
  before: string[]; // isso aq era so uma string
  after: string[]; // isso aq era so uma string
  cnpj: string;
  storeName: string;
  orderName: string;
  banner: string;
  uf: string;
  visitDate: string;
  brandName: string;
  nameLever: string;
  objectNumber: number;
}

export const CardExecution: React.FC<ICardExecutionProps> = ({
  before,
  after,
  cnpj,
  storeName,
  banner,
  uf,
  visitDate,
  // brandName,
  orderName,
  nameLever,
  objectNumber,
}: ICardExecutionProps): JSX.Element => {
  const { show, setShow } = useModal();

  const [imageModal, setImageModal] = useState<string>("");
  const [titleModal, setModalTitle] = useState<string>("");

  const handleImgOpen = (imageTitle: string, image: string) => {
    if (imageTitle === "before") {
      setImageModal(image);
      setModalTitle("Antes");
    }

    if (imageTitle === "after") {
      setImageModal(image);
      setModalTitle("Depois");
    }

    setShow();
  };

  const completeDefaultImage = (images: string[]) => {
    const imagesFull = [...images];
    for (let index = images.length; index < 4; index += 1) {
      imagesFull.push(noImage);
    }
    return imagesFull;
  };

  return (
    <>
      <Modal {...{ show, setShow }} isToast={false}>
        <ModalHeader
          {...{ setShow }}
          border="1px solid #ddd"
          color="var(--base-text)"
          position="center"
          showCloseButton={false}
          fontSize=""
          isToast={false}
        >
          {titleModal}
        </ModalHeader>
        <ModalBody height="80vh">
          <BookCardContentImage
            src={imageModal}
            alt=""
            width="100%"
            height="100%"
            margin="0 auto"
            shadown="rgba(0, 0, 0, 0.24) 0px 3px 8px"
          />
        </ModalBody>
        <ModalFooter margin="0" border="none">
          <AclopButton>
            <a href={imageModal} download>
              <button type="button">
                <RiDownloadLine />
                Baixar
              </button>
            </a>

            <button type="button" onClick={setShow}>
              <RiCloseLine />
              Fechar
            </button>
          </AclopButton>
        </ModalFooter>
      </Modal>

      <BookCardContent margin="5px 0 0 5px">
        <BookCardContentTitle margin=".5rem 0 0 0">
          <h1>{objectNumber}</h1>
          <img src={storeImg} alt="Loja" />
          <div>
            <p>
              Loja:
              <b>
                {cnpj} | {storeName}
              </b>
            </p>
            <span>
              Bandeira <b>{banner}</b> | UF <b>{uf}</b>
            </span>
          </div>
        </BookCardContentTitle>
        <BookCardContentBody>
          <span>
            <BookCardContentTitle margin="1rem 0 0 12px">
              <div>
                <p>
                  <b>Data da Visita:</b>
                </p>
                <span>{visitDate}</span> <br />
              </div>
            </BookCardContentTitle>

            {/*
            <BookCardContentTitle margin="1rem 0 0 12px">
              <div>
                <p>
                  <b>Marca:</b>
                </p>
                <span>Brand Name {brandName}</span> <br />
              </div>
            </BookCardContentTitle>
            */}

            <BookCardContentTitle margin="1rem 0 0 12px">
              <div>
                <p>
                  <b>Ordem:</b>
                </p>
                <span>{orderName}</span> <br />
              </div>
            </BookCardContentTitle>

            <BookCardContentTitle margin="1rem 0 0 12px">
              <div>
                <p>
                  <b>Execução:</b>
                </p>
                <span>{nameLever}</span>
              </div>
            </BookCardContentTitle>
          </span>

          <BookCardContainerImage
            width="65%"
            height="100%"
            position="relative"
            margin="0 0 0 auto;"
            grid
          >
            <SubContainerImg>
              <p>Antes</p>
              <div>
                {completeDefaultImage(before).map((beforeItem) => (
                  <BookCardContentImage
                    onClick={() => {
                      handleImgOpen("before", beforeItem); // Ver na interface
                    }}
                    src={beforeItem}
                    alt="Depois"
                    width="100%"
                    height="108px"
                    shadown="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                    key={Math.random()}
                  />
                ))}
              </div>
            </SubContainerImg>

            <SubContainerImg>
              <p>Depois</p>
              <div>
                {completeDefaultImage(after).map((afterItem) => (
                  <BookCardContentImage
                    onClick={() => {
                      handleImgOpen("after", afterItem); // Ver na interface
                    }}
                    src={afterItem}
                    alt="Depois"
                    width="100%"
                    height="108px"
                    shadown="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                    key={Math.random()}
                  />
                ))}
              </div>
            </SubContainerImg>
          </BookCardContainerImage>
        </BookCardContentBody>
      </BookCardContent>
    </>
  );
};
