import styled, { keyframes } from "styled-components";
import { theme } from "../../styles/theme";

interface IStyleProps {
  img?: string;
  imgHover?: string;
  active?: boolean;
  position?: string;
  height?: string;
}

export const WrapperContent = styled.div<IStyleProps>`
  background-color: var(--light-gray);
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;
`;

export const NavItem = styled.button<IStyleProps>`
  font-size: 10.5px;
  width: 150px;
  height: 43px;
  color: ${(props) => (props.active ? "#000000" : "#ffffff")};
  background-color: ${(props) => (props.active ? "#e2e2e2" : "transparent")};
  border-radius: 10px 10px 0 0;
  border: 1px solid var(--light-gray);
  background-image: url(${(props) => props.img});
  background-position: 7px;
  background-repeat: no-repeat;
  background-size: 30px;
  transition: 0.3s;
  text-align: left;
  padding-left: 45px;
  margin-right: 5px;
  font-weight: 300;

  &:hover {
    background-color: var(--light-gray);
    color: black;
    background-image: url(${(props) => props.imgHover});
    background-position: 7px;
    background-repeat: no-repeat;
    background-size: 30px;
  }
`;

const loadingIcon = keyframes`
  from {
    margin-left: -20px;
  }
  to{
    margin-left: 102%;
  }
`;

export const IconLogin = styled.span`
  background-color: #ccc;
  width: 30px;
  height: 30px;
  position: absolute;
  margin: -14px -35px;
  padding: 0px;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 40px;
    background-image: linear-gradient(to left, #ccc, #d9d9d9, #ccc);
    margin-left: -15px;
    animation: ${loadingIcon} 1.5s linear infinite;
  }
`;

const loadingLabel = keyframes`
  from {
    margin-left: -35px;
  }
  to{
    margin-left: 102%;
  }
`;

export const LabelLogin = styled.span`
  background-color: #ccc;
  width: 90px;
  height: 30px;
  position: absolute;
  margin: -14px 0px;
  padding: 0px;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 40px;
    background-image: linear-gradient(to left, #ccc, #d9d9d9, #ccc);
    margin-left: 30px;
    animation: ${loadingLabel} 1.5s linear infinite;
  }
`;

export const NavMenu = styled.nav<{ marginTop?: string }>`
  .nav-icon {
    width: 30px;
    float: left;
    font-size: 24px;
    right: 10px;
    display: none;
    margin-left: 10px;

    img {
      width: 24px;
    }
  }
  .nav-link {
    color: var(--light-gray) !important;
    padding: 6px 6px;
    float: left;
    border: 1px solid var(--light-gray);
    background-color: var(--primary);
    border-radius: 10px 10px 0px 0px;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    padding-left: 50px;
    margin-left: 0px;
    margin-bottom: -1px;
    width: 170px;
    margin-right: -5px;
    text-decoration: none;
    text-align: left;
  }

  .nav-link-margin-left {
    color: var(--light-gray) !important;
    padding: 6px 6px;
    float: left;
    border: 1px solid var(--light-gray);
    background-color: var(--primary);
    border-radius: 10px 10px 0px 0px;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    padding-left: 50px;
    margin-left: 10px;
    margin-bottom: -1px;
    width: 170px;
    margin-right: -5px;
    text-decoration: none;
    text-align: left;
  }

  .nav-back {
    font-size: 16px;
    padding: 8px 10px;
    padding-left: 42px;
  }
  .nav-link-icon,
  .nav-link-icon-hover {
    width: 38px;
    position: absolute;
    display: block;
  }

  .nav-back .nav-link-icon-hover {
    width: 30px;
  }
  .nav-link-icon-hover {
    display: none;
  }

  .nav-link-icon.store,
  .nav-link-icon-hover.store {
    top: 8px;
  }

  .nav-link-icon.checklist,
  .nav-link-icon-hover.checklist {
    top: 6px;
  }

  .link-icon,
  .link-icon-hover {
    width: 50px;
    top: 5px;
    left: 5px;
    display: block;
  }

  .bar {
    margin-left: 12px;
    margin-bottom: 10px;
  }

  .bar.active {
    background: var(--light-gray);
    border-radius: 8px;
    padding: 1px 10px 1px 10px;
  }

  .no-padding-right {
    padding-right: 0px;
  }

  .padding-left-baner {
    padding-left: 10px;
  }

  .bar.text {
    align-items: center;
    display: flex;
    font-weight: 600;
    font-size: 30px;
    color: black;
    text-decoration: none;
  }

  .margin-top-14 {
    margin-top: 34px;
    width: 95px;
    height: auto;
    z-index: 999;
  }

  .content-bar {
    align-items: center;
    display: flex;
    background-color: #fff;
    padding: 10px 10px 0px 10px;
    margin-bottom: 10px;
  }

  .nav-link:hover,
  .nav-link.active {
    color: black !important;
    background-color: var(--light-gray);
    text-decoration: none;
  }
  .nav-link-margin-left:hover,
  .nav-link-margin-left.active {
    color: black !important;
    background-color: var(--light-gray);
    text-decoration: none;
  }

  .nav-link:hover .nav-link-icon,
  .nav-link.active .nav-link-icon,
  .nav-link-margin-left:hover .nav-link-icon,
  .nav-link-margin-left.active .nav-link-icon {
    display: none;
  }
  .nav-link:hover .nav-link-icon-hover,
  .nav-link.active .nav-link-icon-hover,
  .nav-link-margin-left:hover .nav-link-icon-hover,
  .nav-link-margin-left.active .nav-link-icon-hover {
    display: block;
  }

  .select2-results__option[aria-selected] {
    cursor: pointer;
    color: #0e0e06;
  }

  .select2-container {
    margin-bottom: 10px !important;
  }

  .detail-branth {
    margin-left: 88%;
    margin-top: -1%;
    margin-bottom: 10px;
  }

  @media (max-width: 830px) {
    .nav-link {
      display: inline-block;
      float: none;
    }
    .nav-link-margin-left {
      display: inline-block;
      float: none;
    }
  }

  // @media (max-width: 767px) {
  //   .nav-link {
  //     display: none;
  //     float: none;
  //     width: 280px;

  //     margin-left: 0px;

  //     border-radius: 6px;
  //     margin-bottom: 10px;
  //   }
  //   .nav-link-margin-left{
  //     display: none;
  //     float: none;
  //     width: 280px;

  //     margin-left: 0px;

  //     border-radius: 6px;
  //     margin-bottom: 10px;
  //   }
  // }

  @media (max-width: 767px) {
    .nav-icon {
      display: block;
    }
  }

  @media (max-width: 830px) {
    body > nav {
      padding-left: 0;
      text-align: center;
      margin-top: 10px;
    }

    body > header > span {
      font-size: 13px;
    }

    .content-bar {
      padding: 10px 10px 0px 0px !important;
      margin-left: 0%;
    }

    #linha-horizontal {
      display: none;
    }

    .margin-top-14 {
      margin-top: 3px !important;
      width: 57px !important;
      margin-right: 8%;
    }

    .box-detail {
      margin-left: 38% !important;
      margin-bottom: 4% !important;
      margin-top: -17% !important;
    }

    .link-icon,
    .link-icon-hover {
      width: 46px !important;
    }

    .no-padding-right {
      padding-right: 15px !important;
    }

    .market-item > strong,
    .market-item > i {
      font-size: 11px !important;
      line-height: 13px !important;
      margin-bottom: 10px;
    }

    .button-filter {
      float: right;
      margin-top: -8px;
    }

    .img-filter {
      display: none;
    }
  }

  @media (max-width: 1280px) {
    width: 740px;
  }

  @media (max-width: 767px) {
    body > nav.show a {
      display: inline-block;
    }
  }
`;

export const Submenu = styled.div<{
  background?: string;
  width?: string;
  margin?: string;
}>`
  color: #000;
  height: 80px;
  background-color: ${(props) => props.background || "#fff"};
  margin: ${(props) => (props.margin ? props.margin : "5px auto")};
  width: ${(props) => (props.width ? props.width : "calc(100% - 10px)")};

  @media screen and (max-width: 1120px) {
    margin: 5px 5px 0 5px;
  }
`;

const loadingSubMenu = keyframes`
  from{
    margin-left: -350px;
  }
  to{
    margin-left: 105%;
  }
`;

export const Loader = styled.span`
  position: absolute;
  background-image: linear-gradient(to left, #ccc, #d9d9d9, #ccc);
  width: 250px;
  height: 100%;
  margin-left: -100px;
  animation: ${loadingSubMenu} 2.5s linear infinite;
`;

export const LoaderHeader = styled.span`
  background-color: red;
  position: absolute;
  width: 30px;
  height: 100%;
`;

export const PageContent = styled.div<{
  overflow?: string;
  margin?: string;
  width?: string;
}>`
  transition: 0.3s;
  position: relative;
  height: calc(100% - 150px);
  width: ${(props) => props.width || "calc(100% - 5px)"};
  overflow: ${(props) => (props.overflow ? props.overflow : "auto")};
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  & > div + div {
    margin-top: 5px;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    width: 99%;
    margin: 0 auto;
  }

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }

  & ::-moz-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  & ::-moz-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }
`;
