import { all, AllEffect, ForkEffect, takeLatest } from "redux-saga/effects";

import { typeCategory } from "./SideFilters/RequestFilters/types";
import { actionsType } from "./Authentication/Login/types";
import { AProfileType } from "./Profile/types";
import { ALogout } from "./Authentication/Logout/types";
import { load } from "./SideFilters/RequestFilters/saga";
import { loadLogin } from "./Authentication/Login/saga";
import { loadProfile } from "./Profile/saga";
import { loadLogout } from "./Authentication/Logout/saga";

// Importar o saga aq
// import {
//   RequestDataProduct,
//   RequestDataSC,
//   RequestDataBanner,
//   RequestDataMSME,
//   RequestDataRegion,
// } from './Graphics/MarketShare/saga';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(typeCategory.LOAD_REQUEST, load),
    // takeLatest(actionsType.REQUEST_TOKEN, loadToken),
    takeLatest(ALogout.REQUEST_LOGOUT, loadLogout),
    takeLatest(actionsType.REQUEST_REFRESH_TOKEN, loadLogin),
    takeLatest(AProfileType.REQUEST_PROFILE, loadProfile),
  ]);
}
