import styled from "styled-components";

export const ThCustom = styled.th<{ minW?: string }>`
  min-width: ${(props) => props.minW};
  padding: 0;
  text-align: center;
  background-color: rgb(231, 230, 230);
`;

export const HeaderMonth = styled.div<{ grid: string | undefined }>`
  display: grid;
  grid-template-columns: ${(props) => props.grid};
  justify-content: center;
  align-items: center;

  & > :nth-child(-n + 3) {
    line-height: 12px;
  }

  & > span {
    background-color: rgb(166, 166, 166);
    padding: 2px 0;
    font-size: 11px;
    min-height: 28px;
    display: flex;
    font-weight: normal;
    align-items: center;
    justify-content: center;
  }

  & > span + span {
    margin-left: 1px;
  }
`;
