import { Flex, Image, Select } from "@chakra-ui/react";
import React, { ChangeEvent, memo } from "react";
import filterIcon from "../../assets/OnePage/funnel_black.png";
import { IFilter } from "../../store/SideFilters/types";

export interface IItemFilter {
  optionSelect: IFilter[];
  id: string;
  selected: string;
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
}

interface IOwnProps {
  itens: IItemFilter[];
  justify?: string;
  icon?: string;
  iconSize?: string;
  disableAllOptions?: boolean;
  minWidth?: string;
  marginLeft?: string;
}

const ListBox: React.FC<IOwnProps> = (props: IOwnProps) => {
  const allFilters: IFilter = {
    id: "",
    label: "",
  };

  return (
    <Flex
      marginLeft={props.marginLeft}
      justifyContent={props.justify || "flex-end"}
      align="center"
    >
      <Image
        src={(props.icon && props.icon) || filterIcon}
        alt="icon-funnel"
        width={props.iconSize}
      />
      {props.itens?.map((list) => (
        <Select
          ml="5px"
          p="0"
          borderRadius="5px"
          as="select"
          className="selectForm"
          borderColor="black"
          h="28px"
          minW={props.minWidth}
          fontSize="12px"
          key={list.id}
          color="black"
          onChange={(e) => list.onChange(e)}
        >
          {!props.disableAllOptions && (
            <option value={JSON.stringify(allFilters)}>Todas</option>
          )}
          {list.optionSelect.map((item) => (
            <option
              selected={list.selected === item.id}
              value={JSON.stringify(item)}
              key={Math.random()}
            >
              {item.label}
            </option>
          ))}
        </Select>
      ))}
    </Flex>
  );
};

ListBox.defaultProps = {
  justify: "flex-end",
  icon: "",
  iconSize: "30px",
  disableAllOptions: false,
  minWidth: "auto",
  marginLeft: "0px",
};

export default memo(ListBox);
