import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { Flex, Select } from "@chakra-ui/react";
import {
  BSMainConteiner,
  BSSubMenu,
  BSLegendTable,
  BSConteinerData,
  BSCard,
  BSHeader,
  BSBoxSelection,
  BSWrapperGraphics,
  BSContainer,
  TableGraphics,
  TooltipCard,
  TooltipBox,
  TooltipText,
  ButtonDetail,
  FormDetail,
} from "./DatailBanner.element";
import selectABrand from "../../../../../assets/img/icons/farmacia.png";
import * as ANavigator from "../../../../../store/Navigation/actions";
import {
  ENavSubMenu,
  INavContent,
} from "../../../../../store/Navigation/types";

import { Divisor, ItemAtivo } from "../../../../../components/Contents/subMenu";

import Less from "../../../../../assets/img/icons/minus-circle.png";
import More from "../../../../../assets/img/icons/plus-circle.png";
import ShareValue from "../../../../../components/Graphics/MarketShareValue/MarketShareValue";
import store from "../../../../../assets/Menu/store.png";
import magnifyingImg from "../../../../../assets/img/icons/magnifying_glass_black.png";

import {
  Container,
  WrapperCardSubMenu,
} from "../../../../../components/Contents/Resources/SubView/subView.element";

import {
  TableSubView,
  SubMenuItem,
} from "../../../../../components/Contents/Resources/SubView/subView";

import { GlobalProps } from "../../../../../store";
import { IStateFilters } from "../../../../../store/SideFilters/types";
import HeaderOrdering, {
  IStateOrder,
} from "../../../../../components/HeaderOrdering/HeaderOrdering";
import { BSColumn } from "./BSColumn";
import TemporalFilter from "../../../../../components/TemporalFilter";
import { filter } from "../../../../../store/SideFilters/RequestFilters/types";

interface IStateProps {
  SFilters: IStateFilters;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeTitleMenu(newTitle: string): void;
    changeSubMenu(index: ENavSubMenu): void;
  };
}

type props = IStateProps & IDispatchProps;

const DetailBanner: React.FC<props> = ({
  DNavigator,
  SFilters,
}: props): JSX.Element => {
  const [orderColumn, setOrderColumn] = useState<IStateOrder>({
    orderName: "",
    orderLabel: "Vendas",
    orderValue: 0,
  });
  const [widthContent, setWidthContent] = useState<string>("");
  const [limitItem, setLimitItem] = useState<number>(50);
  const refDivContent = useRef<HTMLDivElement>(null);
  const [temporalFilter, setTemporalFilter] = useState<filter>({
    id: "",
    label: "",
  });
  const [heightGraphics, setHeightGraphics] = useState<number>(0);
  const refTable = useRef<HTMLTableElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);

  const headers = ["#", "Cod PDV", "Nome da Loja", "Bandeira", "UF"];

  useEffect(() => {
    DNavigator.changeTitleMenu("One Page | Visão Banner");
  }, [DNavigator]);

  // useEffect(() => {
  //   DBannerStore.bannerStoreLoad({
  //     subChannel: SFilters.subChannel.selectedFilterId,
  //     banner: SFilters.banner.selectedFilterId,
  //     region: SFilters.region.selectedFilterId,
  //     uf: SFilters.uf.selectedFilterId,
  //     category: SFilters.category.selectedFilterId,
  //     segment: SFilters.segments.selectedFilterId,
  //     subCategory: SFilters.brand.selectedFilterId,
  //     ordering: orderColumn.orderName,
  //     orderingType: orderColumn.orderValue.toString(),
  //     numberOfRecords: limitItem,
  //   });
  // }, [DBannerStore, SFilters, limitItem, orderColumn]);

  // useEffect(() => {
  //   if (body.table.storeCnpj.length === 0 && !SBannerStore.loading) {
  //     setToastText('Não existem dados para os filtros selecionados');
  //     setShowToast();
  //   }
  // }, [SBannerStore.loading, body.table.storeCnpj.length, setShowToast]);

  const montListColor = (data: number[]): string[] => {
    const colorsList = data.map((item) => {
      return item >= 0 ? "rgb(0, 176, 240)" : "rgb(192, 0, 0)";
    });
    return colorsList;
  };

  const handleLimitItem = (e: ChangeEvent<HTMLSelectElement>) => {
    const limitSelected = parseInt(e.currentTarget.value, 10);
    setLimitItem(limitSelected);
  };

  setTimeout(() => {
    if (refDivContent.current !== null) {
      const widthDiv = refDivContent.current.clientWidth - 148.5;
      const currentWidth = parseInt(widthContent.replace("px", ""), 10);
      if (widthDiv !== currentWidth) {
        setWidthContent(`${widthDiv}px`);
      }
    }

    if (refTable.current) {
      const heightDiv = refTable.current.clientHeight + 28;
      setHeightGraphics(heightDiv);
    }
  });

  return (
    <Flex w="100%" h="100%">
      {/* <Toast
        {...{ showToast, setShowToast }}
        width="400px"
        margin="25% auto 0 auto"
        toastType={toastType}
        text={toastText}
        timeOnScreen={toastTimeOnScreen}
      /> */}
      {/* <SpinnerLoader show={SBannerStore.loading} /> */}
      <BSMainConteiner>
        <BSSubMenu ref={refSubMenu}>
          <ItemAtivo
            icon={selectABrand}
            noMargin
            active={false}
            width="150px"
          />
          <Divisor />
          <BSLegendTable minWidth={widthContent}>
            <SubMenuItem
              text="Skus Ativos"
              bold
              value="500"
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Market Share"
              value="300"
              icon={More}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Market Share"
              value="200"
              icon={Less}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Performance"
              bold
              noMargin
              value="60,0%"
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
          </BSLegendTable>
          <WrapperCardSubMenu
            gridColumns="repeat(12, 1.5fr)"
            margin="0 12.5px 0 0"
          >
            <SubMenuItem
              font="14px"
              text="% Share Valor"
              bold
              noMargin
              value="-4,6%"
              minWidth="160px"
              subTitleBold
            />
            <SubMenuItem
              font="14px"
              text="% Var. Share"
              bold
              noMargin
              value="35,5%"
              minWidth="160px"
              subTitleBold
            />

            <SubMenuItem
              text="R$ Sell Out ('000)"
              bold
              noMargin
              font="14px"
              value="R$ 1.351,8"
              minWidth="160px"
              subTitleBold
            />

            <SubMenuItem
              text="% Cresc.Sell"
              bold
              noMargin
              font="14px"
              value="-26,9%"
              minWidth="160px"
              subTitleBold
            />

            <TooltipCard>
              <TooltipText>
                <SubMenuItem
                  text="% Presença"
                  font="14px"
                  bold
                  noMargin
                  value="45,2%"
                  minWidth="160px"
                  subTitleBold
                />
              </TooltipText>
              <TooltipBox>
                <p>
                  Crescimentos acima de 100% e abaixo de -100% travados em
                  escalas de até 100%.
                </p>
                <p>Cálculos com divisão por 0, fixados em 0%.</p>
              </TooltipBox>
            </TooltipCard>

            <TooltipCard>
              <TooltipText>
                <SubMenuItem
                  text="% Var. Presença"
                  font="14px"
                  bold
                  noMargin
                  value="-35,4%"
                  minWidth="160px"
                  subTitleBold
                />
              </TooltipText>
              <TooltipBox>
                <p>
                  Crescimentos acima de 100% e abaixo de -100% travados em
                  escalas de até 100%.
                </p>
                <p>Cálculos com divisão por 0, fixados em 0%.</p>
              </TooltipBox>
            </TooltipCard>
            <SubMenuItem
              text="Pontos Extras"
              font="14px"
              bold
              value="0 Lojas"
              minWidth="160px"
              subTitleBold
            />
            <SubMenuItem
              text="% Var. Ponto Extras"
              font="14px"
              bold
              value="-35,4%"
              minWidth="160px"
              subTitleBold
            />
            <SubMenuItem
              text="Pontos Loja"
              font="14px"
              bold
              value="0 Lojas"
              minWidth="160px"
              subTitleBold
            />
            <SubMenuItem
              text="% Var. Pontos"
              font="14px"
              bold
              value="-35,4%"
              minWidth="160px"
              subTitleBold
            />
          </WrapperCardSubMenu>
        </BSSubMenu>
        <BSConteinerData>
          <BSHeader>
            <div className="header-msl-products">
              <h1>
                Detalhamento de Market Share e Evolução do Mercado por loja |
                Bandeira
                {` ${SFilters.promoFilter.banner.selectedFilterName} `}| Região
                {` ${SFilters.promoFilter.uf.selectedFilterName}`}
              </h1>
            </div>
          </BSHeader>
          <BSCard>
            <FormDetail>
              <BSBoxSelection>
                <img src={store} alt="logo-store" />
                <TemporalFilter
                  setTemporalFilter={setTemporalFilter}
                  temporalFilter={temporalFilter}
                  itens={[
                    { id: "MAT", label: "MAT" },
                    { id: "YTD", label: "YTD" },
                    { id: "U3M", label: "U3M" },
                    { id: "LP", label: "LP" },
                  ]}
                />
                <span>Exibir</span>
                <Select
                  as="select"
                  className="selectForm"
                  onChange={handleLimitItem}
                >
                  <option>25</option>
                  <option selected>50</option>
                  <option>100</option>
                </Select>
                <span>Lojas</span>
              </BSBoxSelection>
              <ButtonDetail>
                Ver detalhe Loja
                <img src={magnifyingImg} alt="icon-lupa" />
              </ButtonDetail>
            </FormDetail>
            <BSWrapperGraphics>
              <BSContainer width="fit-content" ref={refDivContent} fixedHeader>
                <div>
                  <p>
                    Top {limitItem} Lojas OFENSORAS em {orderColumn.orderLabel}
                  </p>
                </div>
                <TableSubView
                  refElement={refTable}
                  headers={headers}
                  dataTable={[]}
                />
              </BSContainer>
              <Container
                inline
                width="100%"
                height={`${heightGraphics}px`}
                display="grid"
                color="#000"
              >
                <TableGraphics>
                  <thead>
                    <tr>
                      <th>
                        <p>
                          <HeaderOrdering
                            orderColumn={orderColumn}
                            title="-25,3%"
                            filterOrdering="share_var"
                            onClick={setOrderColumn}
                          />
                        </p>
                        <p>% Share Valor</p>
                      </th>
                      <th>
                        <p>26,6%</p>
                        <p>% Var. Share</p>
                      </th>
                      <th>
                        <p>
                          <HeaderOrdering
                            orderColumn={orderColumn}
                            title="R$ 267,4"
                            filterOrdering="market_current_month"
                            onClick={setOrderColumn}
                          />
                        </p>
                        <p>R$ Sell Out (000)</p>
                      </th>
                      <th>
                        <TooltipCard>
                          <TooltipText>
                            <p>-10,2%</p>
                            <p>% Cresc.Sell</p>
                          </TooltipText>
                          <TooltipBox>
                            <span>
                              Crescimentos acima de 100% e abaixo de -100%
                              travados em escalas de até 100%.
                            </span>
                            <br />
                            <br />
                            <span>
                              Cálculos com divisão por 0, fixados em 0%.
                            </span>
                          </TooltipBox>
                        </TooltipCard>
                      </th>
                      <th>
                        <TooltipCard>
                          <TooltipText>
                            <p>38,9%</p>
                            <p>% Presença</p>
                          </TooltipText>
                          <TooltipBox>
                            <span>
                              Crescimentos acima de 100% e abaixo de -100%
                              travados em escalas de até 100%.
                            </span>
                            <br />
                            <br />
                            <span>
                              Cálculos com divisão por 0, fixados em 0%.
                            </span>
                          </TooltipBox>
                        </TooltipCard>
                      </th>
                      <th>
                        <p>
                          <HeaderOrdering
                            orderColumn={orderColumn}
                            title="0,0%"
                            filterOrdering="weight_share_contribution"
                            onClick={setOrderColumn}
                          />
                        </p>
                        <p>% Var. Presença</p>
                      </th>
                      <th>
                        <p>9 P. Extras</p>
                        <p>Pontos Extras</p>
                      </th>
                      <th>
                        <p>0,0%</p>
                        <p>% Var. Presença</p>
                      </th>
                      <th>
                        <p>9 Pontos</p>
                        <p>% Var. Pontos Extras</p>
                      </th>
                      <th>
                        <p>0,0%</p>
                        <p>Pontos Loja</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <BSColumn
                          data={{
                            text: [
                              "-123,5%",
                              "-24,9%",
                              "-49,5%",
                              "-33,1%",
                              "-10,3%",
                              "-70,0%",
                            ],
                            total: 0,
                            x: [-123.5, -24.9, -49.5, -33.1, -10.3, -70.0],
                            y: [],
                          }}
                        />
                      </td>
                      <td>
                        <ShareValue
                          color="#A6A6A6"
                          dataGraphic={{
                            label: [
                              "100,0%",
                              "0,0%",
                              "0,0%",
                              "22,1%",
                              "20,7%",
                              "0,0%",
                            ],
                            x: [100, 0, 0.0001, 0.0002, 22.1, 20.7, 0.0003],
                            y: ["100.0", "0.0", "0.0", "22.1", "20.7", "0.0"],
                          }}
                          minRenge={0}
                          maxRenge={0}
                          height={heightGraphics - 50}
                        />
                      </td>
                      <td>
                        <BSColumn>
                          <ShareValue
                            color="#BF9000"
                            dataGraphic={{
                              label: ["6", "24", "9", "5", "15", "2"],
                              x: [6, 24, 9, 5, 15, 2],
                              y: ["6", "24", "9", "5", "15", "2"],
                            }}
                            height={heightGraphics - 50}
                          />
                        </BSColumn>
                      </td>
                      <td>
                        <ShareValue
                          color={montListColor([227, 954, -72, 6, 42, -80])}
                          dataGraphic={{
                            label: [
                              "227%",
                              "954%",
                              "-72%",
                              "6%",
                              "42%",
                              "-80%",
                            ],
                            x: [227, 954, -72, 6, 42, -80],
                            y: ["227", "954", "-72", "6", "42", "-80"],
                          }}
                          minRenge={500}
                          maxRenge={500}
                          height={heightGraphics - 50}
                          alignCenter
                        />
                      </td>
                      <td>
                        <BSColumn
                          data={{
                            text: [
                              "30,0%",
                              "32,0%",
                              "34,0%",
                              "36,0%",
                              "38,0%",
                              "40,0%",
                            ],
                            total: 0,
                            x: [],
                            y: [],
                          }}
                          bg="#E7E6E6"
                          color="black"
                        />
                      </td>
                      <td>
                        <ShareValue
                          color={montListColor([18, 21, -5, -12, 9, 4])}
                          dataGraphic={{
                            label: ["18%", "21%", "-5%", "-12%", "9%", "4%"],
                            x: [18, 21, -5, -12, 9, 4],
                            y: ["18", "21", "-5", "-12", "9", "4"],
                          }}
                          minRenge={500}
                          maxRenge={500}
                          height={heightGraphics - 50}
                          alignCenter
                        />
                      </td>
                      <td>
                        <BSColumn
                          data={{
                            text: ["1,0", "2,0", "1,0", "2,0", "1,0", "2,0"],
                            total: 0,
                            x: [],
                            y: [],
                          }}
                          bg="#E7E6E6"
                          color="black"
                        />
                      </td>
                      <td>
                        <ShareValue
                          color={montListColor([18, 21, -5, -12, 9, 4])}
                          dataGraphic={{
                            label: ["18%", "21%", "-5%", "-12%", "9%", "4%"],
                            x: [18, 21, -5, -12, 9, 4],
                            y: ["18", "21", "-5", "-12", "9", "4"],
                          }}
                          minRenge={500}
                          maxRenge={500}
                          height={heightGraphics - 50}
                          alignCenter
                        />
                      </td>
                      <td>
                        <BSColumn
                          data={{
                            text: ["1,0", "2,0", "1,0", "2,0", "1,0", "2,0"],
                            total: 0,
                            x: [],
                            y: [],
                          }}
                          bg="#E7E6E6"
                          color="black"
                        />
                      </td>
                      <td>
                        <ShareValue
                          color={montListColor([18, 21, -5, -12, 9, 4])}
                          dataGraphic={{
                            label: ["18%", "21%", "-5%", "-12%", "9%", "4%"],
                            x: [18, 21, -5, -12, 9, 4],
                            y: ["18", "21", "-5", "-12", "9", "4"],
                          }}
                          minRenge={500}
                          maxRenge={500}
                          height={heightGraphics - 50}
                          alignCenter
                        />
                      </td>

                      {/* <td>
                        <ShareValue
                          color={montListColor(body.growthIndustry.x)}
                          dataGraphic={{
                            label: [],
                            x: [],
                            y: [],
                          }}
                          minRenge={500}
                          maxRenge={500}
                          height={heightGraphics - 50}
                          alignCenter
                        />
                      </td>
                      <td>
                        <BSColumn
                          icons={body.ReckittPromo.values}
                          bg="#E7E6E6"
                          color="black"
                        />
                      </td> */}
                    </tr>
                  </tbody>
                </TableGraphics>
              </Container>
            </BSWrapperGraphics>
          </BSCard>
        </BSConteinerData>
      </BSMainConteiner>
    </Flex>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailBanner);
