import React, { ReactNode, RefObject } from "react";
import { Table, Tbody, Thead, Tr } from "@chakra-ui/react";
import HeaderOrdering, {
  IStateOrder,
} from "../../../HeaderOrdering/HeaderOrdering";
import success from "../../../../assets/img/icons/checkmark-circle.svg";
import error from "../../../../assets/img/icons/close-circle.svg";

import {
  Title,
  ConteinerColumn,
  ItemColumn,
  MenuItem,
} from "./subView.element";
import { IFormatData } from "../../../../services/Hooks/OnePage/useMarketShareContribution";
import HeaderTable from "../../../Table/Header";
import { IColumnsData } from "../../../../pages/Private/OnePage/NoPromo/Banner/BSColumn";
import Column, { IData, IMonTable } from "../../../Table/Column";

export interface IProduct {
  id: number;
  cod: number;
  describe: string;
  brand?: string;
  segment: string;
}

interface IDataTable {
  headers?: string[] | undefined;
  dataTable: IMonTable[] | IProduct[] | IData[];
  children?: ReactNode;
  alignColumn?: number[];
  refElement?: RefObject<HTMLTableElement>;
  topFixedHeader?: string;
  collapse?: boolean;
}

export const TableSubView: React.FC<IDataTable> = ({
  headers,
  dataTable,
  children,
  refElement,
  alignColumn,
  topFixedHeader,
  collapse,
}: IDataTable): JSX.Element => {
  const defineColumnAlign = (current: number): boolean => {
    let alignCenter = false;
    alignColumn?.forEach((column) => {
      if (column === current) alignCenter = true;
    });

    return alignCenter;
  };

  return (
    <Table
      ref={refElement}
      style={{ borderCollapse: collapse ? "separate" : "separate" }}
      mt="5px !important"
      fontSize="12px"
      minWidth="650px"
    >
      <Thead
        position="sticky"
        zIndex={99}
        top={topFixedHeader || "0px"}
        bg="white"
      >
        <Tr>
          {headers?.map((header, index) => {
            return (
              <HeaderTable
                isTextCenter={defineColumnAlign(index)}
                bg="transparent"
                key={Math.random()}
              >
                {header}
              </HeaderTable>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {(dataTable &&
          dataTable.length > 0 &&
          dataTable?.map((itens) => {
            return (
              <Tr key={Math.random()}>
                {Object.values(itens).map((item: IMonTable | IData, index) => {
                  return (
                    <Column
                      isTextCenter={defineColumnAlign(index)}
                      border="none"
                      key={Math.random()}
                    >
                      {item}
                    </Column>
                  );
                })}
              </Tr>
            );
          })) ||
          children}
      </Tbody>
    </Table>
  );
};

TableSubView.defaultProps = {
  headers: [""],
  children: [],
  refElement: undefined,
  alignColumn: [],
  topFixedHeader: undefined,
  collapse: false,
};

interface IDataColumn {
  title: string | number;
  data?: IColumnsData;
  icons?: number[];
  background?: string;
  color?: string;
  children?: React.ReactNode;
  subTitle: string;
  width?: string;
  font?: string;
  margin?: string;
  height?: string;
  titleHeight?: string;
  minW?: string;
  heightItem?: string;
  padding?: string;
  marginItem?: string;
  marginBox?: string;
  widthTitle?: string;
  isOrder?: boolean;
  padHeader?: string;
  stateOrder?: IStateOrder;
  orderingBy?: string;
  rows?: number;
  gap?: string;
  noSubTitle?: boolean;
  changeOrder?: (data: IStateOrder) => void;
}

export const ColumnsSubView: React.FC<IDataColumn> = (
  props: IDataColumn
): JSX.Element => {
  return (
    <ConteinerColumn
      margin={props.marginBox}
      width={props.width}
      minW={props.minW}
      height={props.height}
      padding={props.padding}
      rows={props.rows}
      gridGap={props.gap}
    >
      <Title
        font={props.font}
        margin={props.margin}
        width={props.widthTitle}
        padding={props.padHeader}
        height={props.titleHeight}
      >
        {props.isOrder === true ? (
          <HeaderOrdering
            orderColumn={props.stateOrder}
            title={props.title}
            filterOrdering={props.orderingBy}
            onClick={props.changeOrder}
          />
        ) : (
          props.title
        )}
      </Title>
      <div>
        {!props.noSubTitle && <p>{props.subTitle}</p>}
        {(props.data &&
          props.data.text.length > 0 &&
          props.data.text.map((item: string | number) => (
            <ItemColumn
              heightItem={props.heightItem}
              key={Math.random()}
              background={props.background}
              color={props.color}
              padding={props.padding}
              font={props.font}
              marginItem={props.marginItem}
            >
              {item}
            </ItemColumn>
          ))) ||
          (props.icons &&
            props.icons?.length > 0 &&
            props.icons?.map((item) => {
              return (
                <ItemColumn
                  heightItem={props.heightItem}
                  key={Math.random()}
                  background={props.background}
                  color={props.color}
                  padding={props.padding}
                  font={props.font}
                  marginItem={props.marginItem}
                >
                  <img
                    key={Math.random()}
                    src={(item === 1 && success) || error}
                    alt="icons-store"
                  />
                </ItemColumn>
              );
            })) || <span>{props.children}</span>}
      </div>
    </ConteinerColumn>
  );
};

ColumnsSubView.defaultProps = {
  width: "100%",
  font: "0.8rem",
  margin: "10px 0 3px",
  height: "auto",
  heightItem: "auto",
  minW: "114px",
  padding: "0.617px",
  marginItem: "0",
  marginBox: "0 5px 0 0",
  widthTitle: "100%",
  isOrder: false,
  padHeader: "5px",
  titleHeight: "auto",
  orderingBy: "",
  stateOrder: {
    orderName: "",
    orderValue: 0,
    orderLabel: "Vendas",
  },
  changeOrder: undefined,
  rows: 0,
  gap: "1.84px",
  noSubTitle: false,
};

interface ownProps {
  text: string;
  value: string | number;
  noMargin?: boolean;
  bold?: boolean;
  icon?: string;
  font?: string;
  width?: string;
  minWidth?: string;
  marginP?: boolean;
  titleBoxAlign?: string;
  legend?: string;
  children?: ReactNode;
  subTitleBold?: boolean;
}

export const SubMenuItem: React.FC<ownProps> = (props: ownProps) => {
  return (
    <MenuItem
      width={props.width}
      noMargin={props.noMargin}
      bold={props.bold}
      fontSize={props.font}
      minWidth={props.minWidth}
      noMarginP={props.marginP}
      titleBoxAlign={props.titleBoxAlign}
      spanBold={props.subTitleBold}
    >
      <p>
        {props.icon && <img src={props.icon} alt="icon subMenu" />}
        {props.text}
        {props.legend && <span>{props.legend}</span>}
      </p>
      {(props.value === "" && props.children) || <span>{props.value}</span>}
    </MenuItem>
  );
};

interface IOwnProps {
  data?: IFormatData;
  background: string;
  padding: string;
  margin: string;
  textAign?: string;
  fontSize: string;
  fontColor?: string;
}

export const RenderList: React.FC<IOwnProps> = ({
  data,
  background,
  padding,
  margin,
  textAign,
  fontSize,
  fontColor,
}: IOwnProps) => {
  return (
    <>
      {data?.label.map((item) => {
        return (
          <ItemColumn
            background={background}
            padding={padding}
            key={Math.random()}
            marginItem={margin}
            textAlign={textAign}
            font={fontSize}
            color={fontColor}
          >
            {item}
          </ItemColumn>
        );
      })}
    </>
  );
};

SubMenuItem.defaultProps = {
  noMargin: false,
  bold: false,
  icon: "",
  font: "1rem",
  minWidth: "122px",
  width: "100%",
  marginP: false,
  titleBoxAlign: "flex-start",
  legend: "",
  subTitleBold: false,
  children: <></>,
};

ColumnsSubView.defaultProps = {
  background: "#fff",
  data: { text: [], total: 0, x: [], y: [] },
  icons: [],
  color: "black",
  children: <></>,
  height: "min-content",
};

RenderList.defaultProps = {
  textAign: "center",
  fontColor: "black",
  data: {
    label: [],
    x: [],
    y: [],
  },
};
