import { put, call, CallEffect, PutEffect } from 'redux-saga/effects';
import api from '../../../services/api';
import { stateMarketShare, ILoadError, ILoadSuccess } from './types';
import { loadSuccess, loadFailure } from './actions';

export function* load(): Generator<
  CallEffect<unknown> | PutEffect<ILoadSuccess> | PutEffect<ILoadError>,
  void,
  stateMarketShare
> {
  try {
    const response: stateMarketShare = yield call(api.get, 'filters/');
    yield put(loadSuccess(response));
  } catch (e) {
    yield put(loadFailure());
  }
}
