import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

import {
  ITypeGraphicPeriod,
  ITypeGraphicPeriodBar,
} from "../OnePage/onePageTypes";

interface IYellowPharmacy {
  periodBar: ITypeGraphicPeriod;
  rightBar: ITypeGraphicPeriodBar;
  leftBar: ITypeGraphicPeriodBar;
}

const requestYellowPharmacy = async (
  period: string,
  filters: IStateFilters
): Promise<IYellowPharmacy> => {
  const yellowPharma = await api.get<{ data: IYellowPharmacy }>(
    `yellow_pharmacy/?period=${period}`,
    {
      params: {
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director: filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep: filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  return yellowPharma.data.data;
};

export const useRequestYellowPharmacy = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IYellowPharmacy, unknown> => {
  return useQuery(
    ["yellowPharmacy", period, filters],
    () => requestYellowPharmacy(period, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
