import React, { useContext } from "react";
import PlotlyChart from "react-plotlyjs-ts";

// types
import { ITypeGraphicPeriodBar } from "../../../services/Hooks/OnePage/onePageTypes";
import { formatedLegendName } from "../Resource/GraphicsFunctions";
import { theme } from "../../../styles/theme";
import { ConfigContext } from "../../../services/Context/ConfigContext";

interface ownProps {
  dataGraphic?: ITypeGraphicPeriodBar;
  bgColor?: string;
  gap?: number;
  b?: number;
  t?: number;
  l?: number;
  r?: number;
  h?: number;
  showSideLegend?: boolean;
  autosize?: boolean;
  target?: number;
}

const MarketSharePeriodBarLtyYtd: React.FC<ownProps> = ({
  dataGraphic,
  bgColor = theme.graphics.dark,
  gap,
  b,
  t,
  r,
  l,
  h,
  showSideLegend,
  autosize,
  target,
}: ownProps) => {
  const { x, y, label } = dataGraphic ?? { label: [], x: [], y: [] };
  const rangeY = Math.max.apply(null, y);
  const rangeYFinal = rangeY * 0.3 + rangeY;

  const { config } = useContext(ConfigContext);

  const color = bgColor;

  const trace = {
    meta: {
      columnNames: {
        x: "A",
        y: "B",
        text: "C",
        marker: { color: "C" },
      },
    },
    mode: "markers",
    type: "bar",
    x: formatedLegendName(x),
    y,
    text: label,
    marker: {
      meta: { columnNames: { color: "C" } },
      opacity: 1,
      color,
    },
    error_x: {
      type: "percent",
      color: "#444",
      value: 10,
      width: 4,
      visible: false,
      symmetric: true,
      thickness: 2,
    },
    textfont: { color: "#404040", size: config.fonts.graphics },
    hoverinfo: "x+text",
    showlegend: false,
    orientation: "v",
    textposition: "outside",
  };
  const data = [trace];
  const configGraphic = {
    displayModeBar: false,
  };

  const layout = {
    annotations: target && [
      {
        x: x.length * 0.82,
        y: target,
        xref: "x",
        yref: "y",
        showarrow: true,
        arrowhead: 3,
        ax: x.length * -45,
        ay: 0,
        arrowsize: 0.8,
        borderradius: 6,
        arrowwidth: 3.5,
        arrowcolor: theme.graphics.target,
      },
    ],
    xaxis: {
      side: "bottom",
      type: "category",
      ticks: "",
      title: { font: { color: "rgb(0, 0, 0)" } },
      showgrid: false,
      showline: true,
      tickfont: { color: "#595959", size: config.fonts.graphics },
      tickmode: "auto",
      zeroline: true,
      linecolor: "#c0c0c0",
      autorange: true,
      tickangle: "auto",
      automargin: true,
      fixedrange: true,
      showspikes: false,
      tickprefix: "",
      showticklabels: true,
    },
    yaxis: {
      type: "linear",
      range: [0, target && target > rangeYFinal ? target + 5 : rangeYFinal],
      showgrid: false,
      zeroline: false,
      autorange: false,
      fixedrange: true,
      showspikes: false,
      zerolinewidth: 2,
      showticklabels: showSideLegend,
    },
    bargap: gap,
    height: h,
    width: !autosize && ((x.length === 1 && 56) || x.length * 56),
    legend: { title: { text: "<br>" } },
    margin: {
      b,
      l,
      r,
      t,
      pad: 0,
    },
    barmode: "group",
    barnorm: "",
    modebar: { orientation: "h" },
    autosize: true,
    dragmode: "zoom",

    clickmode: "event",
    hovermode: "closest",
    hoverlabel: { font: { size: 12 } },
    separators: ",.",
    showlegend: true,
    bargroupgap: 0,
    uniformtext: { mode: false },
  };

  return <PlotlyChart data={data} layout={layout} config={configGraphic} />;
};

MarketSharePeriodBarLtyYtd.defaultProps = {
  bgColor: theme.graphics.dark,
  gap: 0.23,
  b: 0,
  t: 0,
  l: 0,
  r: 0,
  h: 187,
  showSideLegend: false,
  autosize: false,
  target: undefined,
  dataGraphic: {
    label: [],
    x: [],
    y: [],
  },
};

export default MarketSharePeriodBarLtyYtd;
