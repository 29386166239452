/* eslint-disable camelcase */
import { useQuery, UseQueryResult } from "react-query";
import { ITableData } from "../../../components/TableWithGraphic";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

interface IResponseFormat {
  data_1: ITableData;
  data_2: ITableData;
}

const requestBottomTableYellowPharma = async (
  period: string,
  filters: IStateFilters
): Promise<IResponseFormat> => {
  const topStore = await api.get<{ data: IResponseFormat }>(
    `yellow_pharmacy_top_stores/?period=${period}`,
    {
      params: {
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  return topStore.data.data;
};

export const useRequestBottomTableYellowPharma = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IResponseFormat, unknown> => {
  return useQuery(
    ["BottomTablesYellowPharm", period, filters],
    () => requestBottomTableYellowPharma(period, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
