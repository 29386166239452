import React from "react";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { connect } from "react-redux";
import { GlobalProps } from "../../store";
import { WrapperHeader } from "./HeaderOrdering.element";

export interface IStateOrder {
  orderName: string;
  orderValue: number;
  orderLabel: string;
}

interface props {
  title: string | number;
  filterOrdering?: string;
  orderColumn?: IStateOrder;
  onClick?: (data: IStateOrder) => void;
}

const HeaderOrdering: React.FC<props> = ({
  title,
  filterOrdering,
  orderColumn,
  onClick,
}: props): JSX.Element => {
  const requestOrder = (orderList: number) => {
    switch (filterOrdering) {
      case "share_var":
        if (onClick)
          onClick({
            orderName: "share_var",
            orderValue:
              orderColumn?.orderValue === orderList &&
              orderColumn.orderName === filterOrdering
                ? 0
                : orderList,
            orderLabel: "vendas",
          });
        break;
      case "market_current_month":
        if (onClick)
          onClick({
            orderName: "market_current_month",
            orderValue:
              orderColumn?.orderValue === orderList &&
              orderColumn.orderName === filterOrdering
                ? 0
                : orderList,
            orderLabel: "variação de share",
          });
        break;
      case "weight_share_contribution":
        if (onClick)
          onClick({
            orderName: "weight_share_contribution",
            orderValue:
              orderColumn?.orderValue === orderList &&
              orderColumn.orderName === filterOrdering
                ? 0
                : orderList,
            orderLabel: "variação de sell out",
          });
        break;
      default:
        break;
    }
  };

  HeaderOrdering.defaultProps = {
    orderColumn: {
      orderName: "",
      orderValue: 0,
      orderLabel: "Vendas",
    },
    filterOrdering: "",
    onClick: undefined,
  };

  return (
    <>
      <WrapperHeader>
        <div>{title}</div>
        <div>
          <TiArrowSortedUp
            style={{
              color:
                orderColumn?.orderValue === 1 &&
                orderColumn.orderName === filterOrdering
                  ? "#a144ac"
                  : "#acaaaa",
            }}
            onClick={() => requestOrder(1)}
          />
          <TiArrowSortedDown
            style={{
              color:
                orderColumn?.orderValue === -1 &&
                orderColumn.orderName === filterOrdering
                  ? "#a144ac"
                  : "#acaaaa",
            }}
            onClick={() => requestOrder(-1)}
          />
        </div>
      </WrapperHeader>
    </>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFiltersBanner: state.RSubFilter,
});

export default connect(mapStateToProps)(HeaderOrdering);
