import { action } from 'typesafe-actions';
import {
  menuTypes,
  IMainMenu,
  filterComponent,
  IChangeFilters,
  IChangeMain,
  IChangeMenu,
  IChangeSubMenu,
  IChangeDash,
  INavPage,
  INavContent,
  ENavSubMenu,
  IChangeTitleMenu,
} from './types';

export const changeMenu = (menuItem: IMainMenu): IChangeMenu =>
  action(menuTypes.CHANGE_MENU, menuItem);

export const changeTitleMenu = (newTitle: string): IChangeTitleMenu =>
  action(menuTypes.CHANGE_TITLE_MENU, newTitle);

export const changeSubMenu = (index: ENavSubMenu): IChangeSubMenu =>
  action(menuTypes.CHANGE_SUBMENU, index);

export const changeFilters = (filters: filterComponent[]): IChangeFilters =>
  action(menuTypes.CHANGE_FILTERS, filters);

export const changeMain = (indexPage: INavPage): IChangeMain =>
  action(menuTypes.CHANGE_PAGE, indexPage);

export const changeDash = (content: INavContent): IChangeDash =>
  action(menuTypes.CHANGE_CONTENT, content);
