import React, { useContext, useState } from "react";
import { getMonthName } from "../../../Resource/functions";
import { ConfigContext } from "../../../services/Context/ConfigContext";
import { useRequestComplianceExtraExposureTable } from "../../../services/Hooks/MyExecution/useRequestComplianceExtraExposureTable";
import { useRequestSubComplianceExtraExposure } from "../../../services/Hooks/MyExecution/useRequestSubComplianceExtraExposure";
import { INavContent } from "../../../store/Navigation/types";
import { IStateFilters } from "../../../store/SideFilters/types";
import SubTableComplements from "../../subTableComplements";
import TableComplements from "../../TableComplements";

interface IOwnProps {
  SFilters: IStateFilters;
}

const ComplianceExtraExposureTable: React.FC<IOwnProps> = ({ SFilters }) => {
  const [filterSection, setFilterSection] = useState<string>("category");
  const { config } = useContext(ConfigContext);

  const referenceDate = getMonthName(config.general.referenceDateMyExecution);

  const subComplianceExtraExposure =
    useRequestSubComplianceExtraExposure(SFilters);

  const complianceExtraExposureTable = useRequestComplianceExtraExposureTable(
    filterSection,
    SFilters
  );

  return (
    <>
      <SubTableComplements
        isLoading={subComplianceExtraExposure.isLoading}
        data={subComplianceExtraExposure.data}
        period={referenceDate || "-----"}
        subCardSelected={filterSection}
        setSubCardSelected={setFilterSection}
      />
      <TableComplements
        noFilter
        titleCard="Cumprimento dos acordos comerciais"
        subTitleCard="(Mensal)"
        subTitle="Detalhe ALAVANCAS / MARCAS"
        data={complianceExtraExposureTable.data}
        isLoading={complianceExtraExposureTable.isLoading}
        goDetail={{
          detailPage: INavContent.MN_DETAIL_COMPLIANCE,
          label: "Detalhe loja",
        }}
      />
    </>
  );
};

export default ComplianceExtraExposureTable;
