import styled from "styled-components";
import { theme } from "../../../../styles/theme";

interface CardProps {
  w: string;
  h?: string;
  margin?: string;
  minW?: string;
}

interface ContentProps {
  margin?: string;
  padding?: string;
  h?: string;
  toggle?: boolean;
}

interface ContainerGraphicsProps {
  mxw?: string;
  margin?: string;
  mt?: string;
}

interface CardBodyProps {
  display?: string;
  h?: string;
  border?: boolean;
  margin?: string;
  padding?: string;
  class?: string;
}

export const Container = styled.div`
  min-width: 1435px;
  margin-right: 5px;
`;

export const CardHeader = styled.div`
  background-color: "#000040";
  color: ${(props) => props.color || "var(--white)"};
  margin: "0";
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: "center";
  align-items: center;
  justify-content: center;
  position: "relative";
  overflow: hidden;

  background-color: #000040;

  h3 {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;

    i {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
`;

export const CardBody = styled.div<CardBodyProps>`
  background-color: white;
  display: ${(props) => props.display || "block"};
  height: ${(props) => props.h || "100%"};
  grid-template-columns: 15% 85%;
  margin-top: ${(props) => (props.margin ? props.margin : "5px")};
  padding: ${(props) => props.padding || "0"};
  position: relative;
  padding: 5px;

  ${(props) =>
    props.border &&
    `
    border: 4px solid #6ec1e4;
    :after {
      content: ' ';
      width: 0;
      height: 0;
      border-top: solid 15px #6ec1e4;
      border-left: solid 50px transparent;
      border-right: solid 50px transparent;
      position: absolute;
      left: calc(50% - 48px);
      bottom: -17px;
      z-index: 1;
    }
    `}

  div:first-child {
    button {
      margin-right: 1px;
    }
  }

  div:nth-child(3) {
    grid-column: 1 / span 2;
  }
`;

export const ContainerGraphics = styled.div<ContainerGraphicsProps>`
  max-width: ${(props) => props.mxw || "100%"};
  margin: ${(props) => props.margin || "0"};
  overflow: hidden;
  margin-top: ${(props) => props.mt || ""};
`;

export const Content = styled.div<ContentProps>`
  height: ${(props) => props.h || "100%"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.padding || "0"};

  margin: ${(props) => props.margin || 0};
  transition: display 0.8s;

  ${(props) =>
    props.toggle &&
    `
     display: none;
    `}
`;

export const Card = styled.div<CardProps>`
  color: var(--base-text);
  height: ${(props) => props.h || "100%"};
  width: ${(props) => props.w};
  margin: ${(props) => props.margin || 0};
  ${(props) =>
    props.minW &&
    `
    min-width: ${props.minW}
  `}
`;

export const ContainerTopTable = styled.div`
  padding: 3px;
  display: flex;
  margin-top: 4px;
  overflow: auto;
  height: calc(100% - 40px);
  width: 100%;

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
    margin: 5px 0;
  }

  .xy {
    width: 100%;
  }

  table {
    border-collapse: separate;
    border-spacing: 3px;
    min-width: 615px;
    width: 100%;
    height: fit-content;

    thead {
      th {
        background-color: #e7e6e6;
        height: 25px;
        padding: 0 5px;

        & + th {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        height: 25px;
      }

      td:nth-child(3) {
        background-color: #afabab;
        border: none;
        font-weight: 600;
        color: #fff;
        width: 90px;
      }
      td {
        border: 1px solid #e7e6e6;
        white-space: nowrap;
        padding: 0 10px;
        height: 25px;

        & + td {
          text-align: center;
          min-width: 110px;
          white-space: nowrap;
        }
      }
    }
  }
`;

export const GraphicRupture = styled.div`
  width: 130px;
  margin-top: 3px;
  div {
    text-align: center;
    p {
      background-color: #e7e6e6;
      width: 130px;
      margin-bottom: 0;
      height: 25px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
