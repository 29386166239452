import { useQuery, UseQueryResult } from "react-query";
import { IMonTable } from "../../../../components/Table/Column";
import { IContentColumn } from "../../../../pages/Private/MyExecution/NPD/detailYellowPharma/Stores/ColumnInfoYellowPharma";
import { IStateFilters } from "../../../../store/SideFilters/types";
import api from "../../../api";

export interface IDetailYelloPharma {
  table: IMonTable[];
  content: IContentColumn[];
}

const requestDetailStore = async (
  filters: IStateFilters
): Promise<IDetailYelloPharma> => {
  const presence = await api.get<{ data: IDetailYelloPharma }>(
    `yellow_pharmacy_top_stores_details/`,
    {
      params: {
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
      },
    }
  );

  return presence.data.data;
};

export const useRequestDetailStore = (
  filters: IStateFilters
): UseQueryResult<IDetailYelloPharma, unknown> => {
  return useQuery(["detailStore", filters], () => requestDetailStore(filters), {
    refetchOnWindowFocus: false,
  });
};
