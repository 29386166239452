import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  Flex,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  BSMainConteiner,
  BSSubMenu,
  BSLegendTable,
  BSConteinerData,
  BSCard,
  BSHeader,
  ConteinerSelectProduct,
  FormDetail,
} from "./MyDayDatailProducts.element";
import { WrapperContent } from "../../../../../components/MainContent/MainContent.elements";
import selectABrand from "../../../../../assets/img/icons/farmacia.png";
import * as ANavigator from "../../../../../store/Navigation/actions";
import {
  ENavSubMenu,
  IMainMenu,
  INavContent,
  stateType,
} from "../../../../../store/Navigation/types";

import { Divisor, ItemAtivo } from "../../../../../components/Contents/subMenu";

import Less from "../../../../../assets/img/icons/minus-circle.png";
import More from "../../../../../assets/img/icons/plus-circle.png";
import product from "../../../../../assets/OnePage/products_black.png";
import store from "../../../../../assets/Menu/store-white.png";

import { SubMenuItem } from "../../../../../components/Contents/Resources/SubView/subView";

import { GlobalProps } from "../../../../../store";
import { IStateFilters } from "../../../../../store/SideFilters/types";
import Column from "../../../../../components/Table/Column";
import TemporalFilter from "../../../../../components/TemporalFilter";
import HeaderTable from "../../../../../components/Table/Header";
import { Form } from "../../../../Public/Login/Login.element";
import { filter } from "../../../../../store/SideFilters/RequestFilters/types";

interface IStateProps {
  navigation: stateType;
  SFilters: IStateFilters;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
  };
}

enum ELineFilter {
  TOP = "TOP",
  ESG = "ESG",
  CLN_PROMO = "CLN PROMO",
  FCIA_AMARELA = "FCIA AMARELA",
}

type props = IStateProps & IDispatchProps;

const MyDayDetailProducts: React.FC<props> = ({
  navigation,
  DNavigator,
  SFilters,
}: props): JSX.Element => {
  const [widthContent, setWidthContent] = useState<string>("");
  const refDivContent = useRef<HTMLTableElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);
  const [filterKPI, setFilterKPI] = useState<filter>({
    id: ELineFilter.ESG,
    label: ELineFilter.ESG,
  });

  const headers = ["#", "Cnpj", "Descrição do Produto", "Marca", "Segmento"];

  useEffect(() => {
    DNavigator.changeMenu({
      index: navigation.menuItem.index,
      name: "Minha Execução | Presença - Visão Loja",
    });
  }, []);

  setTimeout(() => {
    if (refDivContent.current !== null) {
      const widthDiv = refDivContent.current.clientWidth - 148.5;
      const currentWidth = parseInt(widthContent.replace("px", ""), 10);
      if (widthDiv !== currentWidth) {
        setWidthContent(`${widthDiv}px`);
      }
    }
  });

  const setTemporalFilter = (filterSelected: filter) => {
    setFilterKPI(filterSelected);
  };

  return (
    <WrapperContent>
      <BSMainConteiner>
        <BSSubMenu ref={refSubMenu}>
          <ItemAtivo
            icon={selectABrand}
            noMargin
            active={false}
            width="150px"
          />
          <Divisor />
          <BSLegendTable minWidth={widthContent}>
            <SubMenuItem
              text="Total de Itens"
              bold
              value="50"
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Itens s/ GAP"
              value="0"
              icon={More}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Itens c/ GAP"
              value="50"
              icon={Less}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Performance"
              bold
              noMargin
              value="0,0%"
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
          </BSLegendTable>
        </BSSubMenu>
        <BSConteinerData>
          <BSHeader>
            <div className="header-msl-products">
              <img src={store} alt="icon-page" />
              <h1>
                Detalhamento do MSL%TOP 50 Lojas Ofensoras | Bandeira
                {` ${
                  SFilters.promoFilter.banner.selectedFilterName || "Todas"
                } `}
                | Região
                {` ${SFilters.promoFilter.uf.selectedFilterName || "Todas"}`}
              </h1>
            </div>
          </BSHeader>
          <BSCard>
            {/* <BSBoxSelection>
              <img src={product} alt="logo-store" />
              <span>Exibir</span>
              <Form.Control
                as="select"
                className="selectForm"
                onChange={handleLimitItem}
              >
                <option>25</option>
                <option selected>50</option>
                <option>100</option>
              </Form.Control>
              <span>Produtos</span>
            </BSBoxSelection> */}
            <FormDetail>
              <ConteinerSelectProduct
                width={
                  refDivContent.current
                    ? `${refDivContent.current.clientWidth - 10}px`
                    : ""
                }
              >
                <img src={product} alt="icon-product" />
                <Form as="select" className="selectForm">
                  <option>Todos</option>
                </Form>
              </ConteinerSelectProduct>
              <TemporalFilter
                itens={[
                  { id: ELineFilter.TOP, label: "TOP 50" },
                  { label: ELineFilter.ESG, id: "ESG" },
                  { id: ELineFilter.CLN_PROMO, label: "CLN PROMO" },
                ]}
                setTemporalFilter={setTemporalFilter}
                temporalFilter={filterKPI}
              />
            </FormDetail>
            <TableContainer>
              <Flex bg="#E7E6E6" fontWeight="bold" p="10px" mx="5px">
                <Text fontSize="14px" color="#C00000">
                  Top 50 Lojas OFENSORAS no MSL
                </Text>
              </Flex>
              <Flex>
                <Table
                  style={{ borderCollapse: "separate" }}
                  ref={refDivContent}
                  minW="610px"
                >
                  <Thead>
                    <Tr>
                      {headers.map((headerText) => {
                        return (
                          <HeaderTable
                            isTextCenter={false}
                            bg="#fff"
                            color="black"
                          >
                            {headerText}
                          </HeaderTable>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="1" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="78912345678910"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="Produto A"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Termometro"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Vitaminas"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="2" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="78912345678910"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="Produto B"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Termometro"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Vitaminas"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="3" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="78912345678910"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="Produto C"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Kmed"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Sexual"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="4" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="78912345678910"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="Produto D"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Kmed"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Sexual"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="5" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="78912345678910"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="Produto E"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Termometro"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Vitaminas"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="6" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="78912345678910"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="Produto F"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Kmed"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="Sexual"
                      />
                    </Tr>
                  </Tbody>
                </Table>
                <Stack mt="2px" minW="90px" mx="5px">
                  <Flex
                    fontSize="12px"
                    fontWeight="bold"
                    h="30px"
                    mb="4px"
                    align="center"
                    justifyContent="center"
                  >
                    <Text># Skus Ativos</Text>
                  </Flex>
                  {[
                    "12 / 18",
                    "12 / 18",
                    "12 / 18",
                    "12 / 18",
                    "12 / 18",
                    "12 / 18",
                  ].map((item, index) => {
                    return (
                      <Flex
                        bg="#A6A6A6"
                        h="30px"
                        fontSize="11px"
                        mt="0px !important"
                        mb={(index !== 5 && "3px !important") || "0 !important"}
                        align="center"
                        justifyContent="center"
                        fontWeight="bold"
                        color="white"
                      >
                        {item}
                      </Flex>
                    );
                  })}
                </Stack>
                <Table
                  style={{ borderCollapse: "separate" }}
                  borderRight="1px solid #E7E6E6"
                  pr="10px"
                >
                  <Thead>
                    <Tr>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Ult. 3 M
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Ult. 3 Mês
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Atual
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Ult. 3M %
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Ult.Mês %
                      </HeaderTable>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                  </Tbody>
                </Table>
                <Table style={{ borderCollapse: "separate" }} pl="10px">
                  <Thead>
                    <Tr>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 22
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 23
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 24
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 25
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 26
                      </HeaderTable>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                  </Tbody>
                </Table>
              </Flex>
            </TableContainer>
          </BSCard>
        </BSConteinerData>
      </BSMainConteiner>
    </WrapperContent>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDayDetailProducts);
