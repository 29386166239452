import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

import {
  ITypeGraphicPeriod,
  ITypeGraphicPeriodBar,
} from "../OnePage/onePageTypes";

export interface IPresence {
  periodBar: ITypeGraphicPeriod;
  rightBar: ITypeGraphicPeriodBar;
  leftBar: ITypeGraphicPeriodBar;
}

const requestPresence = async (
  period: string,
  filters: IStateFilters
): Promise<IPresence> => {
  const presence = await api.get<{ data: IPresence }>(
    `presence/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        // segment: filters.segments.selectedFilterId || null,
      },
    }
  );

  return presence.data.data;
};

export const useRequestPresence = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IPresence, unknown> => {
  return useQuery(
    ["presence", period, filters],
    () => requestPresence(period, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
