import styled from 'styled-components';

export const CustomRadioContainer = styled.span`
  border: 1px solid #000;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: block;
  margin: 0 auto;
  padding: 1px;
  cursor: pointer;

  button {
    border-radius: 50%;
    border: none;
    width: 100%;
    height: 100%;
    background: #8ccfb9;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
