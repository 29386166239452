import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

export interface IEvidences {
  executionsEvidences: {
    date: string;
    promoter: string;
    // eslint-disable-next-line camelcase
    banner_logo: string;
    storeCity: string;
    storeName: string;
    storeCnpj: string;
    storeBanner: string;
    storeUf: string;
    executionLeverage: string;
    typeExecutionLeverage: string;
    orderName: string;
    evidencesBefore: string[];
    evidencesAfter: string[];
  }[];
}

async function requestCategory(filters: IStateFilters, industryId?: string) {
  try {
    const response = await api.get<IEvidences>("/evidences/", {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        subChannel:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.subChannel.selectedFilterId || null
            : null,
        banner:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.banner.selectedFilterId || null
            : null,
        region:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.region.selectedFilterId || null
            : null,
        uf:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.uf.selectedFilterId || null
            : null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        industry: industryId,
        startDate:
          filters.promoFilter.dateInitial === "dd/mm/aaaa"
            ? null
            : filters.promoFilter.dateInitial,
        endDate:
          filters.promoFilter.dateFinal === "dd/mm/aaaa"
            ? null
            : filters.promoFilter.dateFinal,
        executionType:
          filters.promoFilter.executionType.selectedFilterId || null,
        executionLeverage:
          filters.promoFilter.executionLeverage.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    });

    return response;
  } catch (error) {
    return null;
  }
}

export function useFetchEvidences(
  filters: IStateFilters,
  industryId?: string
): UseQueryResult<AxiosResponse<IEvidences> | null, unknown> {
  return useQuery(
    ["promoEvidences", filters, industryId],
    () => requestCategory(filters, industryId),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
}
