import { call, CallEffect, put, PutEffect } from "redux-saga/effects";
import axios from "axios";
import { urlRequestApi } from "../../../services/api";
import { stateAuth, ISuccessRefreshToken, IErrorRefreshToken } from "./types";
import {
  errorRefreshToken,
  requestRefreshToken,
  successRefreshToken,
} from "./actions";
import { INavPage } from "../../Navigation/types";

export function* loadLogin({
  payload,
}: ReturnType<typeof requestRefreshToken>): Generator<
  | CallEffect<unknown>
  | PutEffect<ISuccessRefreshToken>
  | PutEffect<IErrorRefreshToken>,
  void,
  stateAuth
> {
  const { navigator } = payload;
  try {
    const newToken: stateAuth = yield call(
      axios.post,
      `${urlRequestApi}login/`,
      payload
    );
    localStorage.setItem("AccessTokenBipWeb", `Bearer ${newToken.data.access}`);
    navigator(INavPage.MAIN_PAGE);
    yield put(successRefreshToken());
  } catch (error) {
    yield put(errorRefreshToken());
  }
}
