import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: 0;
  font-family: 'Roboto';
}

:root {
  --base-text: #000000;
	--primary: #000040;
	--red: #e2173d;
	--blue: #2988b5;
	--white: #ffffff;
	--light-gray: #e2e2e2;
	--gray: #666666;
}



html {
	font-size: 16px;
}

body {
	background: var(--light-gray);
	color: var(--white);
	-webkit-font-smoothing: antialiased;
}

body, input, button {
	font-size: 12px;
}

button, a {
	cursor: pointer;
}
.d-flex-one {
  display:flex;
  flex-direction:row;
}
.content {
	flex: 1;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e2e2e2 !important;
    border:none;
}
.btn-outline-secondary:hover{
     color: #fff;
    background-color: #e2e2e2 !important;
    border:none;
}
/* TOPO */
body>header {
	display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  background: #012060;
  padding: 35px;
  box-shadow: 0 1px 1px rgba(0, 0 , 0 , 0.25);
  padding-right: 46px;
}

body>header>span {
	color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

}

#linha-vertical {
  height: 45px;
  margin-left: 12px;
  border-right: 3px solid var(--light-gray);
  margin-top: -9px;
}

body>header>span>span {
  font-weight: 700;
  margin-left: 7px;
}

body>header>span>div {
	padding-right: 5px;
	display: flex;

}
body>header>span>div>img {
	width: 40px;
}

/* MENU */
body>nav {
  width: 100%;
  float: left;
  padding-left: 240px;
  background-color: var(--primary);
  position: relative;

}
@media(max-width: 830px) {

	body>nav {
		padding-left: 0;
		text-align: center;
    margin-top: 10px;
	}

  body>header>span {
    font-size: 13px;
  }

  .content-bar {
    padding: 10px 10px 0px 0px !important;
    margin-left: 0%;
  }

  #linha-horizontal {
    display:none;
  }

  .margin-top-14 {
    margin-top: 3px !important;
    width: 57px !important;
    margin-right: 8%;
  }

  .box-detail {
    margin-left: 38% !important;
    margin-bottom: 4% !important;
    margin-top: -17% !important;
  }

  .link-icon, .link-icon-hover {
    width: 46px !important;
  }

  .no-padding-right {
    padding-right: 15px !important;
  }

  .market-item>strong, .market-item>i {
    font-size: 11px !important;
    line-height: 13px !important;
    margin-bottom: 10px;
  }

  .button-filter {
    float: right;
    margin-top: -8px;
  }

  .img-filter {
    display:none;
  }

}
@media(max-width: 767px) {
	body>nav.show a  {
		display: inline-block;
	}
}

.nav-icon {
  width: 30px;
  float: left;
  font-size: 24px;
  position: absolute;
  right: 10px;
	top: -57px;
	display: none;
}
.nav-icon>img {
  width: 24px;
}

@media(max-width: 767px) {
	.nav-icon {
		display: block;
	}
}

.icon-div {
  margin-top: 10px; margin-left:20px;
}

.nav-link {
  color: var(--light-gray)!important;
  font-weight: 300;
  padding: 5px 10px;
  float: left;
  border: 1px solid var(--light-gray);
  background-color: var(--primary);
  border-radius: 6px 6px 0px 0px;
  font-size: 10px;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-left: 10px;
	margin-bottom: -1px;
  width: 128px;
  margin-right: -5px;
  font-weight: 600;
}
.nav-back {
  font-size: 16px;
  padding: 8px 10px;
  padding-left: 42px;
}
.nav-link-icon, .nav-link-icon-hover{
	width: 24px;
	position: absolute;
	top: 5px;
	left: 5px;
	display: block;
}

.nav-back .nav-link-icon-hover {
	width: 30px;
}
.nav-link-icon-hover {
	display: none;
}

.nav-link-icon.store, .nav-link-icon-hover.store{
	top: 8px;
}

.nav-link-icon.checklist, .nav-link-icon-hover.checklist{
	top: 6px;
}

.link-icon, .link-icon-hover{
	width: 50px;
	top: 5px;
	left: 5px;
	display: block;
}

.bar {
  margin-left: 12px;
  margin-bottom: 0px;
}

.bar.active {
  background: var(--light-gray);
  border-radius: 8px;
  padding: 1px 10px 1px 10px;
}

.no-padding-right {
  padding-right: 0px;
}

.padding-left-baner {
  padding-left: 10px;
}

.bar.text {
  align-items: center;
  display: flex;
  font-weight: 600;
  font-size: 30px;
  color: black;
  text-decoration: none;
}

.margin-top-14 {
  margin-top: 34px;
  width: 95px;
  height: auto;
  z-index: 999;
}

.content-bar {
  align-items: center;
  display: flex;
  background-color: #fff;
  padding: 10px 10px 0px 10px;
  margin-bottom: 10px;
}


.nav-link:hover, .nav-link.active {
	color: black!important;
	background-color: var(--light-gray);
	text-decoration: none;
}
.nav-link:hover .nav-link-icon, .nav-link.active  .nav-link-icon{
	display: none;
}
.nav-link:hover  .nav-link-icon-hover, .nav-link.active  .nav-link-icon-hover{
	display: block;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
  color: #0e0e06;
}

.select2-container {
  margin-bottom: 10px !important;
}

.detail-branth {
  margin-left: 88%;
  margin-top: -1%;
  margin-bottom: 10px;
}

@media(max-width: 830px) {
	.nav-link {
    display: inline-block;
    float: none;
	}
}

@media(max-width: 767px) {
	.nav-link {
    display: none;
    float: none;
    width: 280px;

    margin-left: 0px;

    border-radius: 6px;
    margin-bottom: 10px;
	}

}
/* FILTRO */
.sidebar {
  width: 250px;
  background-color: var(--primary);
  padding: 30px 15px;
  font-size: 0.8rem;
  min-height: 100vh;
}

@media(max-width: 767px) {
	.sidebar {
    width: 100%;
    min-height: 100px;
	}
	.sidebar>.toggle {
		display: none;
	}
	.sidebar.show >.toggle {
		display: block;
    min-height: 384px !important;
	}
}
.sidebar-title {
  color: var(--white);
  font-size: 1.8rem;
  margin-bottom: 24px;
}

.sidebar-toggle {
  width: 100%;
  float: left;
  font-size: 0.8rem;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 8px 15px;
  text-align: center;
  margin-bottom: 15px;
  display: none;
}
.sidebar-toggle img{
	width: 12px;
	margin-right: 15px
}
@media(max-width: 767px) {
	.sidebar-toggle {
		display: block;
	}
}
.filter-icon {
	width: 14px;
	margin-bottom: 3px;
}

/* dashboard */

.box-title {
  color: var(--white);
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
	background-color: #BF9000;
	display: block;
  position: relative;
  line-height: 1.5;
}

.box-title span {
  font-size: 15px;
}

.box-detail {
  margin-left: 74%;
  margin-top: -3%;
  margin-bottom: 1%;
}

.box-title-icon {
  position: absolute;
  width: 36px;
  left: 10px;
  top: 0;
}
@media(max-width: 768px) {
	.box-title {
    padding: 5px 10px;
    font-size: 1rem;
	}
}
.box {
  width: 100%;
  background-color: var(--white);
  margin-bottom: 15px;
  padding: 10px;
  min-height: 330px;
  margin-top: -3px;
  position: relative;
  color: #000;
}

.box-60 {
  width: 60%;
  background-color: var(--white);
  margin-bottom: 15px;
  padding: 10px;
  min-height: 330px;
  margin-top: -3px;
  position: relative;
}

.box-40 {
  width: 40%;
  background-color: var(--white);
  margin-bottom: 15px;
  padding: 10px;
  min-height: 330px;
  margin-top: -3px;
  position: relative;
}

.form-control {
  height: calc(1.5em + 0.45rem + 2px);
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #495057;
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #495057;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "";
}

#linha-horizontal {
  border: 1px solid #fff;
  position: absolute;
  margin-top: -31px;
  margin-left: -9px;
  width: 239px;
}

.button-filter {
  float: right;
}



/* Detalhe */

.resume{

  background-color: var(--white);
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.resume-icon {
  text-align: center;
}
.resume-icon>img {
  width: 70%;
}
.resume-icon>small {
  width: 2px;
  background-color: #eee;
  height: 100%;
  float: right;
  margin-right: 6px;
}

.resume-item {
  color: #000;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
}
.resume-item.ml {
  margin-left: 3px;
}
.resume-item.mr {
  margin-right: 16px;
}
.resume-item-icon {
  width: 18px;
  margin-top: -3px;
}
.resume-item>strong, .resume-item>small {
  display: block;
  font-style: italic;
  font-size: 12px;
  line-height: 24px;
}
.resume-featured {
  background-color: #eee;
  border-radius: 4px;
}
.resume-item>b {
  background-color: #eee;
  display: block;
  padding: 8px 0;
}
.resume-featured> .resume-item>b {
  font-size: 2rem;
  padding: 0;
}

.detail-filter {
  margin-bottom: 6px;
}
.detail-filter-select {
  position: relative;
  padding-left: 60px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #eee;
  margin-left: 25px;
}
.detail-filter-icon {
  width: 40px;
  position: absolute;
  left: 10px;
  top: 0;
}
.detail-filter-link {
  position: relative;
  padding-right: 60px;
  width: 100% !important;
  background-color: #eee;
  margin-right: 25px;
  color: #000;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
}
.detail-filter-link-icon {
  width: 50px;
  position: absolute;
  right: 10px;
  top: -10px;
}

.detail-table {
  color: #000;
  border-collapse: separate;
  border-spacing: 10px 1px;
  width: 100%;
}
.table-dashboard {
  border-spacing: 5px 2px;
}
.table-dashboard td {
  border: 1px solid #eee;
}
.table-dashboard td.no-border {
  border: none;
}

.table-dashboard h3 {
  font-size: 16px;
  color: #22497d;
  font-weight: bolder;
}

.font-size-14 {
  font-size: 14px;
}

.detail-resume {
}

.text-weight {
  font-weight: 700
}

.detail-table  th {
  font-weight: 700;
  font-style: italic;
  font-size: 12px;
  padding: 6px 6px;
}

.detail-resume th {
  padding: 6px 6px;
  background-color: #eee;
  text-align: center;
  font-style: normal;
  font-size: 15px;
}

.detail-resume th span {
  color: #c30909;
}

.detail-table  td {
  font-size: 12px;
  padding: 2px 6px;
  position: relative;
}

.detail-variation {
  display: block;
  background-color: #bbb;
  text-align: center;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}

.detail-share {
  display: block;
  width: 100%;
  position: absolute;
  height: calc(100% + 1px);
  left: 0;
  top: 0px;
  border-left: 1px solid #000;
  z-index: 0;
}
.detail-share>div {
  display: block;
  background-color: #bbb;
  position: absolute;
  height: 16px;
  left: 0;
  top: calc(50% - 8px);
  z-index: 0;
}
.detail-share-text{
  position: relative;
  font-size: 12px;
  font-weight: bolder;
}

.detail-value {
  display: block;
  width: 100%;
  position: absolute;
  height: calc(100% + 1px);
  left: 0;
  top: 0;
  border-left: 1px solid #000;
  z-index: 0;
}

.table-dashboard .detail-value {
  height: calc(100% + 2px);
  top: -1px;
}

.detail-value>div {
  display: block;
  background-color: #20497d;
  color: #fff;
  position: absolute;
  height: 16px;
  left: 0;
  top: calc(50% - 8px);
  z-index: 0;
  font-size: 12px;
  font-weight: bolder;
  overflow: hidden;
}

.detail-value-color>div {
  display: block;
  background-color: #65a0ea;
  color: #fff;
  position: absolute;
  height: 16px;
  left: 0;
  top: calc(50% - 8px);
  z-index: 0;
  font-size: 12px;
  font-weight: bolder;
  overflow: hidden;
}


.detail-value>div {
  padding-left: 6px;
}
.detail-value-text{
  font-size: 12px;
  font-weight: bolder;
}

.detail-growth {
  display: block;
  width: 70%;
  position: absolute;
  height: calc(100% + 1px);
  left: 30%;
  top: 0;
  border-left: 1px solid #000;
  z-index: 0;
}

.detail-growth>div {
  display: block;
  background-color: #03b0f0;
  position: absolute;
  height: 16px;
  left: 0%;
  top: calc(50% - 8px);
  z-index: 0;
}
.detail-growth-negative {
  display: block;
  width: 30%;
  position: absolute;
  height: 100%;
  right: 70%;
  top: 0px;
  z-index: 0;
}
.detail-growth-negative.w-100 {
  right: 0%;
}
.detail-growth-negative>div {
  display: block;
  background-color: #c00000;
  position: absolute;
  height: 16px;
  right: 0%;
  top: calc(50% - 8px);
  z-index: 0;
}

.font-data-title {
  font-size: larger;
}

.detail-growth-text, .detail-growth-text-left{
  position: relative;
  padding-left: 30%;
  font-size: 12px;
  font-weight: bolder;
}
.detail-growth-text-left {
  padding-left: 0%;
}
.detail-growth-negative-text{
  position: relative;
  padding-left: 0%;
  font-size: 12px;
  font-weight: bolder;
}
.detail-bg {
  background-color: #eee;
  font-weight: bolder;
  text-align: center;
  font-size: 12px!important;
}
.pilar-icon {
  width: 60px;
  color: #000;
  text-align: center;
  font-size: 12px;
  position: absolute;
  left: 10px;
  top: 20px;
}

.pilar-icon>img {
  width: 100%;
  margin-top: -5px;
}
.pilar-icon>b {
  font-weight: 400;
}

.pilar-logo{
  position: absolute;
  left: calc(50% - 60px);
  z-index: 1000;
  top: 170px;
  width: 120px;
}
.pilar-logo>img {
  width: 100%;
}

.market-item {
  color: #000;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
}
.market-item>strong, .market-item>i {
  display: block;
  font-size: 16px;
  line-height: 30px;
}
.market-item>b {
  background-color: #eee;
  display: block;
  padding: 8px 0;
  font-size: 16px;
}

.promo-text {
  float: left;
  clear: left;
  width: 60%;
  padding-right: 20px;
  margin-top: 0px;
  margin-bottom: 30px;
  color: #999;
}

.promo-title {
  width: 100%;
  color: #999;
}

.promo-imagem {
  float: left;
  width: 40%;
}

.button-filter {
  font-size: 12px;
  font-weight: bold;
}

.promo-title-number {
  font-size: 2.9rem;
  color: #a144ac;
  font-weight: 700;
  float: left;
}
.promo-title-icon {
  width: 40px;
  float: left;
  color: #001059;
  margin: 10px 5px 0 0;
}
.promo-title-text {
  padding-top: 16px;
}
strong {
  font-weight: bolder;
}

.promo-content {
  margin-top: 0px;
  float: left;
  width: 100%;
  padding-right: 20px;
  padding-left: 10px;
}

.promo-content strong {
  color:#888;
}

.promo-btn {
  background-color: #a144ac;
  color: #fff;
  font-weight: 600;
  padding: 12px 30px;
  border-radius: 10px;
  border: none;
}

.promo-export-title {
  font-weight: 400;
  font-size: 14px;

}

.promo-export {
  font-weight: 700;
  margin-top: 4px;
}
.promo-export-icon {
  width: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.promo-export-pdf {
  width: 22px;
  margin-top: 4px;
}

.promo-row {
  padding: 0 70px;
  position: relative;
}
.promo-item {
 padding: 0;
 padding-left: 10px;
 padding-right: 10px;
}
.promo-item.i1 {
  border-right: 2px solid #e2e2e2;
  border-bottom: 2px solid #e2e2e2;
  padding-right: 30px;
}

.promo-item.i2 {
  border-left: 2px solid #e2e2e2;
  border-bottom: 2px solid #e2e2e2;
}
.promo-item.i3 {
  border-right: 2px solid #e2e2e2;
  border-top: 2px solid #e2e2e2;
  padding-right: 30px;
}
.promo-item.i4 {
  border-left: 2px solid #e2e2e2;
  border-top: 2px solid #e2e2e2;
}

.promo-text.evidence   {
  width: 50%;
}
.box-list {
  width: 100%;
  float: left;
}

.box-description {
  width: 50%;
  float: left;
}
.box-status {
  width: 50%;
  float: left;
}
.box-shadow {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, .15);
}
.img-fluid {
  width: 100%;
  height: auto;
  float: left;
}
.box-imagem-before {
  position: relative;
  margin-left: -20px;
  margin-right: 20px;
  float: left;
  width: 25%;
  margin-bottom: 30px;
}
.box-imagem-after {
  position: relative;
  float: left;
  width: 25%;
  margin-bottom: 30px;
}

.box-imagem-before img,.box-imagem-after img {
  width:100%;
  height: auto;
}

.box-imagem-before>small, .box-imagem-after>small {
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color:#888;
}

.promo-prev, .promo-next {
  width: 50px;
  height: calc(100% - 10px);
  background-color: #e2e2e2;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.promo-next {
  left: auto;
  right: 10px;
}

.promo-prev>img, .promo-next>img {
  width: 40px;
  position: absolute;
  left: 5px;
  top: calc(50% - 32px);
}


@media(max-width: 500px) {
  .promo-row {
    padding: 0 40px;
  }

  .promo-title {
    position: relative;
    padding-left: 50px;
  }

  .promo-title-number {
    font-size: 2rem;
    position: absolute;
    left: 0px;
    top: 0;
  }
  .promo-title-icon {
    width: 30px;
    float: left;
    position: absolute;
    left: 16px;
    top: 0;
  }
  .promo-title-text {
    padding-top: 10px;
    font-size: 13px;
  }

  .promo-text.evidence {
    width: 100%;
    margin-top: 10px;
  }
  .box-imagem-before {
    width: calc(50% - 5px);
    margin-left: 0px;
    margin-right: 10px;
  }
  .box-imagem-after {
    width: calc(50% - 5px);
  }


  .promo-btn {
    font-weight: 600;
    font-size: 10px;
    padding: 10px 20px;
  }

  .promo-export-title {
    margin-bottom: 10px;

  }

  .icon-div {
    margin-top: 20px;
    margin-left: 122px;
}

  .promo-export {
    margin-top: -3px;
    font-size: 7px;
  }

  .promo-export-icon {
    width: 26px !important;
  }

  .promo-export-pdf {
    width: 20px !important;
  }

  .promo-item.i1 {
    border-right: none;
    padding-right: 10px;
  }

  .promo-item.i2 {
    border-left: none;
    border-bottom: 2px solid #e2e2e2;
  }
  .promo-item.i3 {
    border-right: none;
    border-top: none;
    border-bottom: 2px solid #e2e2e2;
    padding-right: 10px;
  }
  .promo-item.i4 {
    border-left: none;
    border-top: none;
  }

  .detail-branth {
    margin-left: 41% !important;
    margin-top: -1% !important;
    margin-bottom: 10px !important;
}


  .promo-prev, .promo-next {
    width: 30px;
    top: 0px;
    height: 100%;
  }

  .promo-prev>img, .promo-next>img {
    width: 20px;
    left: 5px;
    top: calc(50% - 16px);
  }

}

.img-filter {
  margin-left: 18px;
  margin-top: 5px;
}

.margin-filter {
  margin-top: -17px;
}

.sidebar-right {
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  padding: 20px 15px;
  color: #333;
  display: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.sidebar-right.show{
  display: block;
}

.sidebar-right>h3 {
  font-size: 1.4rem;
}

.sidebar-right>h3>small {
  font-size: .8rem;
  color: #bbb!important;
}

.sidebar-right-close {
  height: 24px;
  width: 24px;
  background-color: #f3f6f9;
  position: absolute;
  top: 20px;
  right: 15px;
  border-radius: 5px;
  text-align: center;
  line-height: 24px;
}
.sidebar-right-close:hover {
  background-color: #eee;
}

.sidebar-right-close>img {
  width: 18px;
}

.sidebar-right-profile {
  width: 100%;
  padding-top: 15px;

}

.sidebar-right-profile>img {
  width: 100px;
  float: left;
}
.sidebar-right-text {

  width: calc(100% - 100px);
  float: left;
  padding-left: 10px;

}
.sidebar-right-text>h2 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: 20px;

}
.sidebar-right-text>h3 {
  font-size: 0.8rem;
  color: #999;
  margin-bottom: 4px;

}
.sidebar-right-text>p {
  font-size: 0.8rem;
  color: #999;

}
.sidebar-right-text>p>img {
  margin-right: 6px;
  margin-top: 1px;
}

.sidebar-right-btn {
  color: #3699ff;
    background-color: #e1f0ff;
    cursor: pointer;
    font-weight: 600;
    padding: 8px 15px;
    font-size: .925rem;
    line-height: 1.35;
    border-radius: .42rem;
}
.sidebar-right-btn:hover {
  color: #3699ff;
  background-color: #eee;
  text-decoration: none;
}

.sidebar-open {
  color: #3699ff!important;
    background-color: #e1f0ff;
    cursor: pointer;
    font-weight: 600;
    padding: 8px 15px;
    font-size: .925rem;
    line-height: 1.35;
    border-radius: .42rem;
    position: absolute;
    top: 30px;
    right: 160px;
}
.sidebar-open:hover {
  color: #3699ff;
  background-color: #eee;
  text-decoration: none;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{
  padding-right:2px;
  padding-left:2px;
}
@media(max-width: 450px) {

  body>header {
    align-items: start;
    height: 104px;
    padding: 10px 35px;
  }
  .nav-icon {
    top: -99px;
}
	.sidebar-open {
    top: 60px;
    right: calc(50% - 44px);
    padding: 8px 10px;
	}
}

.promo-page {
  display: none;
}

.promo-page.show {
  display: block;
}

.display-none {
  display: none
}

.disabled{
  cursor: initial;
  opacity: .4;
}
.promo-page-current {
  display: inline;
}
`;

export default GlobalStyle;
