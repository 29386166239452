import { useQuery, UseQueryResult } from "react-query";
import { ITableData } from "../../../components/TableWithGraphic";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

interface IResponseFormat {
  topProduct: ITableData & { period: string };
  topStore: ITableData & { period: string };
}

const requestBottomTables = async (
  period: string,
  filters: IStateFilters
): Promise<IResponseFormat> => {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const topProduct = await api.get<IResponseFormat>(
    `presence_top_products/?period=${period}`,
    {
      params: {
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  const topStore = await api.get<IResponseFormat>(
    `presence_top_store/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        segment: filters.promoFilter.segments.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  const qtdItem = topProduct.data.topProduct.data.length;
  if (qtdItem < 10) {
    for (let index = qtdItem; index < 10; index += 1) {
      topProduct.data.topProduct.data.push(["-", "-", "-"]);
      topProduct.data.topProduct.growth.label.push(" ");
      topProduct.data.topProduct.growth.x.push(0);
      topProduct.data.topProduct.growth.y.push(index.toString());
    }
  }

  topStore.data.topStore.period =
    months[parseInt(topStore.data?.topStore.period, 10) - 1];

  topProduct.data.topProduct.period =
    months[parseInt(topProduct.data.topProduct.period, 10) - 1];
  return {
    topProduct: topProduct.data.topProduct,
    topStore: topStore.data.topStore,
  };
};

export const useRequestBottomTables = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IResponseFormat, unknown> => {
  return useQuery(
    ["BottomTables", filters],
    () => requestBottomTables(period, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
