import { Reducer } from "redux";
import { stateAuth, actionsType } from "./types";

const INITIAL_STATE: stateAuth = {
  data: {
    access: "",
    refresh: "",
  },
  isLoading: false,
  error: false,
  loaded: false,
};

const reducerProfile: Reducer<stateAuth> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsType.REQUEST_REFRESH_TOKEN:
      return { ...state, isLoading: true, loaded: false };
    case actionsType.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        data: action.payload,
        loaded: true,
      };
    case actionsType.REFRESH_TOKEN_ERROR:
      return { ...state, isLoading: false, error: true, loaded: true };
    default:
      return state;
  }
};

export default reducerProfile;
