import { Td, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

export interface IMonTable {
  order: number;
  storesIds: string;
  storeNames: string;
  storeBanners: string;
  storeUf: string;
}

export interface IData {
  descProd: string;
  selecionado: JSX.Element;
  tpr: JSX.Element;
  prateleiras: JSX.Element;
  observacoes: JSX.Element;
}

interface IOwnProps {
  children?: IMonTable | IData | JSX.Element;
  isBold?: boolean;
  bg?: string;
  isTextCenter?: boolean;
  mw?: string;
  rs?: number;
  color?: string;
  w?: string;
  isUppercase?: boolean;
  border?: string;
  value?: string;
  h?: string;
  borderLeft?: string;
  maxCharacter?: number;
}

const Column: React.FC<IOwnProps> = ({
  children,
  value,
  isBold,
  bg,
  isTextCenter,
  mw,
  rs,
  color,
  w,
  isUppercase,
  border,
  h,
  borderLeft,
  maxCharacter,
}: IOwnProps): JSX.Element => {
  const formatedValueTable = (): JSX.Element => {
    let returnText = "0";

    if (value !== undefined && value !== null) {
      const quantityCharacter = value.length;

      if (maxCharacter && maxCharacter <= quantityCharacter)
        return (
          <Tooltip label={value}>
            <Text
              fontSize="12px"
              fontWeight={(isBold && "bold") || "normal"}
              color={color}
            >
              {`${value.substring(0, maxCharacter)}...`}
            </Text>
          </Tooltip>
        );

      returnText = value;
    }

    return (
      <Text
        fontSize="12px"
        fontWeight={(isBold && "bold") || "normal"}
        color={color}
      >
        {returnText}
      </Text>
    );
  };

  return (
    <Td
      h={h || "31px"}
      p="0px 5px"
      bg={bg}
      border={border}
      whiteSpace="nowrap"
      minW={mw}
      rowSpan={rs}
      w={w}
      textTransform={(isUppercase && "uppercase") || "none"}
      borderLeft={borderLeft}
      textAlign={(isTextCenter && "center") || "left"}
      color="black"
    >
      {children || formatedValueTable()}
    </Td>
  );
};

Column.defaultProps = {
  isBold: false,
  bg: "white",
  isTextCenter: true,
  mw: "",
  rs: 1,
  color: "black",
  w: "",
  isUppercase: false,
  border: "1px solid #E7E6E6",
  value: "",
  children: undefined,
  h: "",
  borderLeft: undefined,
  maxCharacter: undefined,
};

export default Column;
