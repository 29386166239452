import { Th } from "@chakra-ui/react";
import React from "react";

interface IOwnProps {
  children: string | JSX.Element;
  color?: string;
  colSpan?: number;
  rowSpan?: number;
  isTextCenter?: boolean;
  bg?: string;
  fs?: string;
  mw?: string;
  p?: string;
  h?: string;
  italic?: boolean;
  br?: string;
  w?: string;
}

const HeaderTable: React.FC<IOwnProps> = (props: IOwnProps): JSX.Element => {
  return (
    <Th
      minW={props.mw}
      color={props.color}
      p={props.p || "5px"}
      textAlign={(props.isTextCenter && "center") || "left"}
      bg={props.bg}
      h={props.h || "31px"}
      fontSize={props.fs}
      colSpan={props.colSpan}
      rowSpan={props.rowSpan}
      textTransform="inherit"
      whiteSpace="nowrap"
      fontStyle={(props.italic && "italic") || "normal"}
      borderRight={props.br}
      w={props.w}
    >
      {props.children}
    </Th>
  );
};

HeaderTable.defaultProps = {
  color: "black",
  colSpan: 1,
  rowSpan: 1,
  fs: "12px",
  mw: "",
  bg: "#E7E6E6",
  isTextCenter: true,
  p: "5px",
  h: "31px",
  italic: false,
  br: "",
  w: undefined,
};

export default HeaderTable;
