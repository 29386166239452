import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Grid,
  Image,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import {
  BSSubMenu,
  BSLegendTable,
  BSHeader,
  TooltipCard,
  TooltipText,
  TooltipBox,
  WrapperCardSubMenu,
} from "./DatailStores.element";

// Images
// import store from "../../../../assets/OnePage/store_black.png";
import product from "../../../../assets/OnePage/products_white.png";
import shopping from "../../../../assets/OnePage/banner Store/store_color.png";
import Less from "../../../../assets/OnePage/banner Store/minus-circle.png";
import More from "../../../../assets/OnePage/banner Store/plus-circle.png";
import open from "../../../../assets/OnePage/banner Store/checkmark-circle.svg";
import close from "../../../../assets/OnePage/banner Store/close-circle.svg";

// graphics
import MarketShareValue from "../../../../components/Graphics/MarketShareValue/MarketShareValue";

// Actions
import * as ANavigator from "../../../../store/Navigation/actions";

import {
  ENavSubMenu,
  filterComponent,
  IMainMenu,
  INavContent,
  stateType,
} from "../../../../store/Navigation/types";

import { Divisor, ItemAtivo } from "../../../../components/Contents/subMenu";

import {
  SubMenuItem,
  TableSubView,
} from "../../../../components/Contents/Resources/SubView/subView";

import { GlobalProps } from "../../../../store";
import { IStateFilters } from "../../../../store/SideFilters/types";

// config system
import { stateMarketShare } from "../../../../store/SideFilters/RequestFilters/types";
import { theme } from "../../../../styles/theme";
import HeaderTable from "../../../../components/Table/Header";
import { ITypeGraphicHorizontalBar } from "../../../../services/Hooks/OnePage/onePageTypes";
import Column, { IMonTable } from "../../../../components/Table/Column";
import { ConfigContext } from "../../../../services/Context/ConfigContext";
import FiltersList, {
  INITIAL_FILTERS,
} from "../../../../components/SideFilterMenu/Resource/FiltersList";

interface IStateProps {
  navigation: stateType;
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const DetailProducts: React.FC<props> = ({
  navigation,
  DNavigator,
  SFilters,
  SAllFilters,
}: props): JSX.Element => {
  const [widthContent, setWidthContent] = useState<string>("");
  // const [temporalFilter, setTemporalFilter] = useState<string>("MAT");
  const [limitItem, setLimitItem] = useState<number>(50);
  const refTable = useRef<HTMLTableElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);

  // const detail = useRequestDetailStore(SFilters);

  const headers = ["#", "Cnpj", "Nome da Loja", "Bandeira", "UF"];

  const { config } = useContext(ConfigContext);

  useEffect(() => {
    DNavigator.changeMenu({
      index: navigation.menuItem.index,
      name: "My Execution | Detalhe Loja",
    });
  }, []);

  const handleLimitItem = (e: ChangeEvent<HTMLSelectElement>) => {
    const limitSelected = parseInt(e.currentTarget.value, 10);
    setLimitItem(limitSelected);
  };

  setTimeout(() => {
    if (refTable.current !== null) {
      const widthDiv = refTable.current.clientWidth - 148;
      const currentWidth = parseInt(widthContent.replace("px", ""), 10);
      if (widthDiv !== currentWidth) {
        setWidthContent(`${widthDiv}px`);
      }
    }
  });

  interface IHeaderDP {
    value: string;
    label: string;
  }

  interface IContentDP {
    shareValue: string;
    presence: string;

    extraPoint: string;

    storePoint: string;

    perfectStore: boolean;
  }

  interface IDetailProduct {
    headers: IHeaderDP[];
    content: IContentDP[];
    varShare: ITypeGraphicHorizontalBar;
    industryValue: ITypeGraphicHorizontalBar;
    growthIndustry: ITypeGraphicHorizontalBar;
    growthStore: ITypeGraphicHorizontalBar;
    varPresence: ITypeGraphicHorizontalBar;
    varExtraPoint: ITypeGraphicHorizontalBar;
    varPointYTD: ITypeGraphicHorizontalBar;
  }

  const mockDetailProduct: IDetailProduct = {
    headers: [
      {
        value: "28,0%",
        label: "% Share Valor",
      },
      {
        value: "12,4%",
        label: "% Var. Share",
      },
      {
        value: "R$ 267,4",
        label: `R$ ${config.general.industryName} ('000)`,
      },
      {
        value: "18,3%",
        label: `% Cres. ${config.general.industryName}`,
      },
      {
        value: "12,3%",
        label: "% Cresc. Mercado",
      },
      {
        value: "38,9%",
        label: "% Presença",
      },
      {
        value: "12,0%",
        label: "% Var. Presenca",
      },
      {
        value: "2",
        label: "Pontos Extras",
      },
      {
        value: "50,0%",
        label: "% Var. Pontos Extras",
      },
      {
        value: "64",
        label: "Pontos Loja",
      },
      {
        value: "4,8%",
        label: "% Var. Pontos",
      },
      {
        value: "11",
        label: "Loja Perfeita",
      },
    ],
    content: [
      {
        shareValue: "30,0%",
        presence: "30,0%",
        extraPoint: "1,0",
        storePoint: "48",
        perfectStore: false,
      },
      {
        shareValue: "25,0%",
        presence: "34,0%",
        extraPoint: "2,0",
        storePoint: "54",
        perfectStore: false,
      },
      {
        shareValue: "27,0%",
        presence: "35,0%",
        extraPoint: "1,0",
        storePoint: "72",
        perfectStore: true,
      },
      {
        shareValue: "33,0%",
        presence: "38,0%",
        extraPoint: "2,0",
        storePoint: "85",
        perfectStore: true,
      },
      {
        shareValue: "29,0%",
        presence: "40,0%",
        extraPoint: "1,0",
        storePoint: "60",
        perfectStore: false,
      },
      {
        shareValue: "33,0%",
        presence: "42,0%",
        extraPoint: "2,0",
        storePoint: "78",
        perfectStore: true,
      },
      {
        shareValue: "33,0%",
        presence: "44,0%",
        extraPoint: "1,0",
        storePoint: "88",
        perfectStore: true,
      },
      {
        shareValue: "29,0%",
        presence: "46,0%",
        extraPoint: "2,0",
        storePoint: "59",
        perfectStore: false,
      },
      {
        shareValue: "33,0%",
        presence: "48,0%",
        extraPoint: "1,0",
        storePoint: "66",
        perfectStore: true,
      },
      {
        shareValue: "32,0%",
        presence: "50,0%",
        extraPoint: "2,0",
        storePoint: "56",
        perfectStore: false,
      },
      {
        shareValue: "30,0%",
        presence: "52,0%",
        extraPoint: "1,0",
        storePoint: "76",
        perfectStore: true,
      },
      {
        shareValue: "27,0%",
        presence: "54,0%",
        extraPoint: "2,0",
        storePoint: "79",
        perfectStore: true,
      },
      {
        shareValue: "32,0%",
        presence: "56,0%",
        extraPoint: "1,0",
        storePoint: "83",
        perfectStore: true,
      },
      {
        shareValue: "33,0%",
        presence: "58,0%",
        extraPoint: "2,0",
        storePoint: "62",
        perfectStore: true,
      },
      {
        shareValue: "27,0%",
        presence: "60,0%",
        extraPoint: "1,0",
        storePoint: "61",
        perfectStore: true,
      },
      {
        shareValue: "29,0%",
        presence: "62,0%",
        extraPoint: "2,0",
        storePoint: "55",
        perfectStore: false,
      },
      {
        shareValue: "32,0%",
        presence: "64,0%",
        extraPoint: "1,0",
        storePoint: "50",
        perfectStore: false,
      },
      {
        shareValue: "32,0%",
        presence: "66,0%",
        extraPoint: "2,0",
        storePoint: "76",
        perfectStore: false,
      },
      {
        shareValue: "27,0%",
        presence: "68,0%",
        extraPoint: "1,0",
        storePoint: "80",
        perfectStore: true,
      },
      {
        shareValue: "33,0%",
        presence: "70,0%",
        extraPoint: "2,0",
        storePoint: "42",
        perfectStore: false,
      },
    ],
    varShare: {
      label: [
        "38,0%",
        "35,0%",
        "9,0%",
        "19,0%",
        "14,0%",
        "33,0%",
        "27,0%",
        "20,0%",
        "37,0%",
        "17,0%",
        "25,0%",
        "28,0%",
        "10,0%",
        "6,0%",
        "36,0%",
        "11,0%",
        "8,0%",
        "12,0%",
        "32,0%",
        "23,0",
      ],
      x: [
        38.0, 35.0, 9.0, 19.0, 14.0, 33.0, 27.0, 20.0, 37.0, 17.0, 25.0, 28.0,
        10.0, 6.0, 36.0, 11.0, 8.0, 12.0, 32.0, 23, 0,
      ],
      y: [
        "38.0",
        "35.0",
        "9.0",
        "19.0",
        "14.0",
        "33.0",
        "27.0",
        "20.0",
        "37.0",
        "17.0",
        "25.0",
        "28.0",
        "10.0",
        "6.0",
        "36.0",
        "11.0",
        "8.0",
        "12.0",
        "32.0",
        "23.0",
      ],
    },
    industryValue: {
      label: [
        "6",
        "24",
        "9",
        "5",
        "15",
        "2",
        "15",
        "9",
        "6",
        "7",
        "4",
        "14",
        "4",
        "2",
        "5",
        "6",
        "1",
        "5",
        "4",
        "4",
      ],
      x: [6, 24, 9, 5, 15, 2, 15, 9, 6, 7, 4, 14, 4, 2, 5, 6, 1, 5, 4, 4],
      y: [
        "6",
        "24",
        "9",
        "5",
        "15",
        "2",
        "15",
        "9",
        "6",
        "7",
        "4",
        "14",
        "4",
        "2",
        "5",
        "6",
        "1",
        "5",
        "4",
        "4",
      ],
    },
    growthIndustry: {
      label: [
        "31,0%",
        "21,0%",
        "43,0%",
        "22,0%",
        "39,0%",
        "-14,0%",
        "25,0%",
        "-18,0%",
        "28,0%",
        "48,0%",
        "44,0%",
        "24,0%",
        "27,0%",
        "-16,0%",
        "19,0%",
        "37,0%",
        "26,0%",
        "34,0%",
        "32,0%",
        "-13,0%",
      ],
      x: [
        31.0, 21.0, 43.0, 22.0, 39.0, -14.0, 25.0, -18.0, 28.0, 48.0, 44.0,
        24.0, 27.0, -16.0, 19.0, 37.0, 26.0, 34.0, 32.0, -13.0,
      ],
      y: [
        "21",
        "43",
        "22",
        "39",
        "-14",
        "25",
        "-18",
        "28",
        "48",
        "44",
        "24",
        "27",
        "-16",
        "19",
        "37",
        "26",
        "34",
        "32",
        "-13",
      ],
    },
    growthStore: {
      label: [
        "31,0%",
        "30,0%",
        "-24,0%",
        "47,0%",
        "49,0%",
        "27,0%",
        "25,0%",
        "41,0%",
        "35,0%",
        "-17,0%",
        "43,0%",
        "-18,0%",
        "-19,0%",
        "36,0%",
        "26,0%",
        "-21,0%",
        "48,0%",
        "45,0%",
        "-23,0%",
        "-20,0%",
      ],
      x: [
        31.0, 30.0, -24.0, 47.0, 49.0, 27.0, 25.0, 41.0, 35.0, -17.0, 43.0,
        -18.0, -19.0, 36.0, 26.0, -21.0, 48.0, 45.0, -23.0, -20.0,
      ],
      y: [
        "31.0",
        "30.0",
        "-24.0",
        "47.0",
        "49.0",
        "27.0",
        "25.0",
        "41.0",
        "35.0",
        "-17.0",
        "43.0",
        "-18.0",
        "-19.0",
        "36.0",
        "26.0",
        "-21.0",
        "48.0",
        "45.0",
        "-23.0",
        "-20.0",
      ],
    },
    varExtraPoint: {
      label: [
        "-57,0%",
        "73,0%",
        "84,0%",
        "82,0%",
        "85,0%",
        "98,0%",
        "76,0%",
        "83,0%",
        "65,0%",
        "-54,0%",
        "72,0%",
        "-44,0%",
        "87,0%",
        "-63,0%",
        "94,0%",
        "-46,0%",
        "69,0%",
        "70,0%",
        "89,0%",
        "81,0%",
      ],
      x: [
        -57.0, 73.0, 84.0, 82.0, 85.0, 98.0, 76.0, 83.0, 65.0, -54.0, 72.0,
        -44.0, 87.0, -63.0, 94.0, -46.0, 69.0, 70.0, 89.0, 81.0,
      ],
      y: [
        "-57",
        "73",
        "84",
        "82",
        "85",
        "98",
        "76",
        "83",
        "65",
        "-54",
        "72",
        "-44",
        "87",
        "-63",
        "94",
        "-46",
        "69",
        "70",
        "89",
        "81",
      ],
    },
    varPresence: {
      label: [
        "-2,0%",
        "23,0%",
        "-3,0%",
        "20,0%",
        "-4,0%",
        "5,0%",
        "18,0%",
        "15,0%",
        "16,0%",
        "-8,0%",
        "19,0%",
        "7,0%",
        "-9,0%",
        "17,0%",
        "14,0%",
        "10,0%",
        "12,0%",
        "11,0%",
        "13,0%",
        "-6,0%",
      ],
      x: [
        -2.0, 23.0, -3.0, 20.0, -4.0, 5.0, 18.0, 15.0, 16.0, -8.0, 19.0, 7.0,
        -9.0, 17.0, 14.0, 10.0, 12.0, 11.0, 13.0, -6.0,
      ],
      y: [
        "-2",
        "23",
        "-3",
        "20",
        "-4",
        "5",
        "18",
        "15",
        "16",
        "-8",
        "19",
        "7",
        "-9",
        "17",
        "14",
        "10",
        "12",
        "11",
        "13",
        "-6",
      ],
    },
    varPointYTD: {
      label: [
        "-24,0%",
        "29,0%",
        "-20,0%",
        "-26,0%",
        "47,0%",
        "31,0%",
        "45,0%",
        "35,0%",
        "34,0%",
        "-23,0%",
        "27,0%",
        "42,0%",
        "25,0%",
        "38,0%",
        "36,0%",
        "-21,0%",
        "39,0%",
        "30,0%",
        "37,0%",
        "-34,0%",
      ],
      x: [
        -24.0, 29.0, -20.0, -26.0, 47.0, 31.0, 45.0, 35.0, 34.0, -23.0, 27.0,
        42.0, 25.0, 38.0, 36.0, -21.0, 39.0, 30.0, 37.0, -34.0,
      ],
      y: [
        "-24",
        "29",
        "-20",
        "-26",
        "47",
        "31",
        "45",
        "35",
        "34",
        "-23",
        "27",
        "42",
        "25",
        "38",
        "36",
        "-21",
        "39",
        "30",
        "37",
        "-34",
      ],
    },
  };

  const mockMainTable: IMonTable[] = [
    {
      order: 1,
      storesIds: "06057223050276",
      storeNames: "ARENA-SP-São Paulo-Bela Alianca-0276",
      storeBanners: "ARENA",
      storeUf: "SP",
    },
    {
      order: 2,
      storesIds: "45543915001234",
      storeNames: "CARREFOUR-SP-São Paulo-Saude-1234",
      storeBanners: "CARREFOUR",
      storeUf: "SP",
    },
    {
      order: 3,
      storesIds: "75315333026922",
      storeNames: "ASSAI-SP-São Paulo-Saude-6922",
      storeBanners: "ASSAI",
      storeUf: "SP",
    },
    {
      order: 4,
      storesIds: "01937635001669",
      storeNames: "SONDA-SP-São Paulo-Pompeia-1669",
      storeBanners: "SONDA",
      storeUf: "SP",
    },
    {
      order: 5,
      storesIds: "06057223025670",
      storeNames: "ARENA-SP-São Paulo-Jardim Planalto-5670",
      storeBanners: "ARENA",
      storeUf: "SP",
    },
    {
      order: 6,
      storesIds: "09525900000399",
      storeNames: "ROSSI-SP-São Paulo-Jardim Adutora-0399",
      storeBanners: "ROSSI",
      storeUf: "SP",
    },
    {
      order: 7,
      storesIds: "03920751000467",
      storeNames: "NAGUMO-SP-São Paulo-Sao Mateus-0467",
      storeBanners: "NAGUMO",
      storeUf: "SP",
    },
    {
      order: 8,
      storesIds: "06057223025166",
      storeNames: "ARENA-SP-São Paulo-Vila Nova Curuca-5166",
      storeBanners: "ARENA",
      storeUf: "SP",
    },
    {
      order: 9,
      storesIds: "45543915003440",
      storeNames: "CARREFOUR-SP-São Paulo-Limao-3440",
      storeBanners: "CARREFOUR",
      storeUf: "SP",
    },
    {
      order: 10,
      storesIds: "10194833000194",
      storeNames: "GIGA-SP-São Paulo-Limao-0194",
      storeBanners: "GIGA",
      storeUf: "SP",
    },
    {
      order: 11,
      storesIds: "06057223023899",
      storeNames: "ARENA-SP-São Paulo-Freguesia do O-3899",
      storeBanners: "ARENA",
      storeUf: "SP",
    },
    {
      order: 12,
      storesIds: "01937635000182",
      storeNames: "SONDA-SP-São Paulo-Itaberaba-0182",
      storeBanners: "SONDA",
      storeUf: "SP",
    },
    {
      order: 13,
      storesIds: "47508411028913",
      storeNames: "ARENA-SP-São Paulo-LIMAO-8913",
      storeBanners: "ARENA",
      storeUf: "SP",
    },
    {
      order: 14,
      storesIds: "06057223024518",
      storeNames: "ARENA-SP-São Paulo-Casa Verde-4518",
      storeBanners: "ARENA",
      storeUf: "SP",
    },
    {
      order: 15,
      storesIds: "75315333025101",
      storeNames: "ASSAI-SP-São Paulo-Vila Rica-5101",
      storeBanners: "ASSAI",
      storeUf: "SP",
    },
    {
      order: 16,
      storesIds: "45543915034400",
      storeNames: "CARREFOUR-SP-São Paulo-Casa Verde-4400",
      storeBanners: "CARREFOUR",
      storeUf: "SP",
    },
    {
      order: 17,
      storesIds: "06057223036605",
      storeNames: "ARENA-SP-São Paulo-Tucuruvi-6605",
      storeBanners: "ARENA",
      storeUf: "SP",
    },
    {
      order: 18,
      storesIds: "62545579001369",
      storeNames: "CARREFOUR-SP-São Paulo-Vila Guilherme-1369",
      storeBanners: "CARREFOUR",
      storeUf: "SP",
    },
    {
      order: 19,
      storesIds: "49673023000156",
      storeNames: "PRECITO-SP-São Paulo-Jardim Japao-0156",
      storeBanners: "PRECITO",
      storeUf: "SP",
    },
    {
      order: 20,
      storesIds: "75315333000613",
      storeNames: "ASSAI-SP-São Paulo-Parada De Taipas-0613",
      storeBanners: "ASSAI",
      storeUf: "SP",
    },
  ];

  const renderFilter = useCallback(() => {
    DNavigator.changeFilters(
      FiltersList(SAllFilters, SFilters, {
        ...INITIAL_FILTERS,
        category: true,
        subCategory: true,
        banner: true,
        subChannel: true,
        region: true,
        uf: true,
        storeCNPJ: true,
      })
    );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter, SFilters]);

  return (
    <Grid
      templateRows="auto 50px 1fr"
      m="5px"
      h="calc(100% - 130px)"
      color="black"
      overflowY="hidden"
      css={`
        &::-webkit-scrollbar {
          background-color: darkgray;
          border-radius: 20px;
          width: 12px;
          height: 12px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background-color: ${theme.scrollColor};
        }
      `}
    >
      <BSSubMenu ref={refSubMenu}>
        <ItemAtivo icon={shopping} noMargin active={false} width="150px" />
        <Divisor />
        <>
          <BSLegendTable minWidth={widthContent || "500px"}>
            <SubMenuItem
              text="Lojas Ativadas"
              bold
              value="1600"
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Market Share"
              value="980"
              icon={More}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Market Share"
              value="620"
              icon={Less}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Performance"
              bold
              noMargin
              value="61,2%"
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
          </BSLegendTable>
          <WrapperCardSubMenu>
            <SubMenuItem
              font="14px"
              text="% Share Valor"
              bold
              noMargin
              value="32,0%"
              minWidth="160px"
              subTitleBold
              titleBoxAlign="center"
            />
            <SubMenuItem
              font="14px"
              text="% Var. Share"
              bold
              noMargin
              value="9,3%"
              minWidth="160px"
              titleBoxAlign="center"
              subTitleBold
            />

            <SubMenuItem
              text={`R$ ${config.general.industryName} ('000)`}
              bold
              noMargin
              font="14px"
              value="R$ 498,9"
              minWidth="160px"
              titleBoxAlign="center"
              subTitleBold
            />

            <SubMenuItem
              text={`% Cresc. ${config.general.industryName}`}
              bold
              noMargin
              font="14px"
              value="4,7%"
              minWidth="160px"
              titleBoxAlign="center"
              subTitleBold
            />

            <TooltipCard>
              <TooltipText>
                <SubMenuItem
                  text="% Cresc. Mercado"
                  font="14px"
                  bold
                  noMargin
                  value="1,4%"
                  titleBoxAlign="center"
                  minWidth="160px"
                  subTitleBold
                />
              </TooltipText>
              <TooltipBox>
                <p>
                  Crescimentos acima de 100% e abaixo de -100% travados em
                  escalas de até 100%.
                </p>
                <p>Cálculos com divisão por 0, fixados em 0%.</p>
              </TooltipBox>
            </TooltipCard>

            <TooltipCard>
              <TooltipText>
                <SubMenuItem
                  text="% Presença"
                  font="14px"
                  bold
                  noMargin
                  value="45,2%"
                  minWidth="160px"
                  titleBoxAlign="center"
                  subTitleBold
                />
              </TooltipText>
              <TooltipBox>
                <p>
                  Crescimentos acima de 100% e abaixo de -100% travados em
                  escalas de até 100%.
                </p>
                <p>Cálculos com divisão por 0, fixados em 0%.</p>
              </TooltipBox>
            </TooltipCard>

            <SubMenuItem
              text="% Var. Presença"
              font="14px"
              bold
              noMargin
              value="7,4%"
              minWidth="160px"
              subTitleBold
              titleBoxAlign="center"
            />
            <SubMenuItem
              text="Pontos Extras"
              font="14px"
              bold
              value="3"
              minWidth="160px"
              subTitleBold
              titleBoxAlign="center"
            />
            <SubMenuItem
              text="% Var. Pontos Extras"
              font="14px"
              bold
              value="25,0%"
              minWidth="160px"
              subTitleBold
              titleBoxAlign="center"
            />
            <SubMenuItem
              text="Pontos Loja"
              font="14px"
              bold
              value="59"
              minWidth="160px"
              subTitleBold
              titleBoxAlign="center"
            />
            <SubMenuItem
              text="% Var. Pontos"
              font="14px"
              bold
              value="3,9%"
              minWidth="160px"
              subTitleBold
              titleBoxAlign="center"
            />
            <SubMenuItem
              titleBoxAlign="center"
              text="Loja Perfeita"
              font="14px"
              bold
              value="930"
              minWidth="160px"
              subTitleBold
            />
          </WrapperCardSubMenu>
        </>
      </BSSubMenu>
      <Flex>
        <BSHeader>
          <div className="header-msl-products">
            <img src={product} alt="icon-page" />
            <h1>
              Detalhamento de Market Share e Evolução do Mercado por loja |
              Bandeira:
              {` ${SFilters.promoFilter.banner.selectedFilterName || "Todas"} `}
              | Região:
              {` ${SFilters.promoFilter.region.selectedFilterName || "Todas"}`}
            </h1>
          </div>
        </BSHeader>
      </Flex>
      {
        <Flex
          h="calc(100% - 5px)"
          mt="5px !important"
          bg="white"
          overflowX="hidden"
          w="fit-content"
          css={`
            &::-webkit-scrollbar {
              background-color: darkgray;
              border-radius: 20px;
              width: 12px;
              height: 12px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 20px;
              background-color: ${theme.scrollColor};
            }
          `}
        >
          <Stack pt="16px" mr="4px" bg="white" zIndex={1000}>
            <Flex alignItems="center" minW={600}>
              {/* <TemporalFilter
                setTemporalFilter={setTemporalFilter}
                temporalFilter={temporalFilter}
                itens={["MAT", "YTD", "U3M", "LP"]}
              /> */}
              <Text ml="12px !important">Exibir</Text>
              <Select w="90px" ml="10px" h="30px" onChange={handleLimitItem}>
                <option selected>20</option>
                <option>50</option>
                <option>100</option>
              </Select>
            </Flex>
            <Text
              bg="#E7E6E6"
              color="#c00000"
              fontSize="14px"
              fontWeight="bold"
              p="10px"
              mt="8px !important"
            >
              Top {limitItem} Lojas OFENSORAS em Market share
            </Text>
            <TableSubView
              refElement={refTable}
              headers={headers}
              dataTable={mockMainTable}
            />
          </Stack>
          <Table h="fit-content" mt="54px">
            <Thead>
              <Tr>
                {mockDetailProduct.headers.map((header) => (
                  <HeaderTable h="41px" br="5px solid white" mw="160px">
                    {header.value}
                  </HeaderTable>
                ))}
              </Tr>
              <Tr>
                {mockDetailProduct.headers.map((header) => (
                  <Th
                    h="31px"
                    color="black"
                    fontStyle="italic"
                    textTransform="none"
                    mw="160px"
                    textAlign="center"
                    p="13px 0 9px 0"
                  >
                    {header.label}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {mockDetailProduct.content.map((value, index) => (
                <Tr>
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.shareValue}
                  />
                  {index === 0 && (
                    <Td
                      border="none"
                      rowSpan={20}
                      p={0}
                      pr="2px"
                      position="relative"
                    >
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={mockDetailProduct.varShare}
                          color="#A6A6A6"
                          height={mockDetailProduct.varShare.y.length * 31}
                          w={165}
                        />
                      </Box>
                    </Td>
                  )}
                  {index === 0 && (
                    <Td
                      border="none"
                      rowSpan={20}
                      p={0}
                      pr="2px"
                      position="relative"
                    >
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={mockDetailProduct.industryValue}
                          color="#1F497D"
                          height={mockDetailProduct.varShare.y.length * 31}
                          w={165}
                        />
                      </Box>
                    </Td>
                  )}
                  {index === 0 && (
                    <Td
                      border="none"
                      rowSpan={20}
                      p={0}
                      pr="2px"
                      position="relative"
                    >
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={mockDetailProduct.growthIndustry}
                          height={mockDetailProduct.varShare.y.length * 31}
                          w={165}
                        />
                      </Box>
                    </Td>
                  )}
                  {index === 0 && (
                    <Td border="none" rowSpan={20} p="0" position="relative">
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={mockDetailProduct.growthStore}
                          height={mockDetailProduct.varShare.y.length * 31}
                          w={165}
                        />
                      </Box>
                    </Td>
                  )}
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.presence}
                  />
                  {index === 0 && (
                    <Td border="none" rowSpan={20} p="0" position="relative">
                      <MarketShareValue
                        dataGraphic={mockDetailProduct.varPresence}
                        height={mockDetailProduct.varShare.y.length * 31}
                        w={165}
                      />
                    </Td>
                  )}
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.extraPoint}
                  />
                  {index === 0 && (
                    <Td border="none" rowSpan={20} p="0" position="relative">
                      <MarketShareValue
                        dataGraphic={mockDetailProduct.varExtraPoint}
                        height={mockDetailProduct.varShare.y.length * 31}
                        w={165}
                      />
                    </Td>
                  )}
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.storePoint}
                  />
                  {index === 0 && (
                    <Td border="none" rowSpan={20} p="0" position="relative">
                      <MarketShareValue
                        dataGraphic={mockDetailProduct.varPointYTD}
                        height={mockDetailProduct.varShare.y.length * 31}
                        w={165}
                      />
                    </Td>
                  )}
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.storePoint}
                  >
                    <Image
                      src={value.perfectStore ? open : close}
                      w="22px"
                      mx="auto"
                    />
                  </Column>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      }
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailProducts);
