import React from "react";
import { ChakraProvider, Container } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import GlobalStyle from "./styles/global";
import RenderPage from "./pages/RenderPage";
import store from "./store";
import ContextConfiguration from "./services/Context/ConfigContext";

const queryClient = new QueryClient();

const App: React.FC = () => {
  // useEffect(() => {
  //   const showNotification = localStorage.getItem("AccessTokenBipWeb");
  //   if (showNotification) localStorage.removeItem("AccessTokenBipWeb");
  // }, []);
  return (
    <Container w="100vw" h="100vh">
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ChakraProvider>
            <ContextConfiguration>
              <RenderPage />
            </ContextConfiguration>
          </ChakraProvider>
          <GlobalStyle />
        </Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Container>
  );
};

export default App;
