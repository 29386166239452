export const formatedDateTimeToBr = (
  date: string,
  withHour?: boolean
): string => {
  const separatedDate = date.split("T");
  const dateFormatBr = separatedDate[0].split("-").reverse();
  if (withHour) {
    const hours = separatedDate[1].split(".")[0];

    return `${dateFormatBr[0]}/${dateFormatBr[1]}/${dateFormatBr[2]} ${hours}`;
  }
  return `${dateFormatBr[0]}/${dateFormatBr[1]}/${dateFormatBr[2]}`;
};

export const convertDate = (dateReference: string, format: string): string => {
  let finishDateUSAFormat = "";
  const separator = format === "br" ? "-" : "/";
  const joinSeparator = format === "br" ? "/" : "-";
  dateReference
    .split(separator)
    .reverse()
    .forEach((date, index) => {
      if (index !== 2) {
        finishDateUSAFormat += date + joinSeparator;
      } else {
        finishDateUSAFormat += date;
      }
    });
  return finishDateUSAFormat;
};

export const getMonthName = (date: string): string => {
  const monthsName = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const numberOfMonth = new Date(date).getMonth();
  return monthsName[numberOfMonth];
};

export const getFirstItemList = (list?: string[]): string => {
  if (list) {
    return list[0];
  }
  return "-------";
};

export const getCurrentQuarterMonths = (months: any[], navigation: number) => {
  switch (navigation) {
    case 1:
      return months.slice(0, 3);
    case 2:
      return months.slice(3, 6);
    case 3:
      return months.slice(6, 9);
    case 4:
      return months.slice(9, 12);
    default:
      return months.slice(0, 3);
  }
};
