import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { stateProfile, IProfileSuccess, IProfileError } from './types';
import api from '../../services/api';
import { profileSuccess, profileError } from './actions';

export function* loadProfile(): Generator<
  CallEffect<unknown> | PutEffect<IProfileSuccess> | PutEffect<IProfileError>,
  void,
  stateProfile
> {
  try {
    const resposeData: stateProfile = yield call(api.get, 'profile/');
    yield put(profileSuccess(resposeData.data));
  } catch (error) {
    yield put(profileError());
  }
}
