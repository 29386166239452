import {
  Box,
  Flex,
  Grid,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import HeaderGraphic from "../../../../../components/Graphics/HeaderGraphic";
import MarketShareValue from "../../../../../components/Graphics/MarketShareValue/MarketShareValue";
import Column from "../../../../../components/Table/Column";
import HeaderTable from "../../../../../components/Table/Header";

interface IContent {
  segmento: string;
  values: string[];
}

interface ITable {
  firstTable: {
    headers: string[];
    content: IContent[];
  };
  secondTable: {
    headers: string[];
    content: IContent[];
  };
}

interface IOwnProps {
  image: string;
  label: string;
  data: ITable;
  hasLine?: boolean;
}

const ItemTable: React.FC<IOwnProps> = (props: IOwnProps): JSX.Element => {
  const selectColorByValue = (value: string): string => {
    const valueFormated = parseFloat(value.replace(",", "."));
    if (valueFormated > 0) return "#00B050";
    if (valueFormated < 0) return "#C00000";

    return "black";
  };

  return (
    <Grid
      templateColumns="auto 1fr"
      borderBottom={(props.hasLine && "1px solid #A5A5A5") || "none"}
      pb="10px"
    >
      <Box w="204px" p="3px 3px 0">
        <HeaderGraphic>{`Marca ${props.label}`}</HeaderGraphic>

        <Flex justify="center" align="center" height="calc(100% - 33px)">
          <Image src={props.image} alt="categoria" w="135px" />
        </Flex>
      </Box>
      <TableContainer mt="2px">
        <Flex textAlign="center">
          <Table style={{ borderCollapse: "separate" }}>
            <Thead>
              <Tr>
                {props.data.firstTable.headers.map((header, idx) => (
                  <HeaderTable key={Math.random()} isTextCenter={idx !== 0}>
                    {header}
                  </HeaderTable>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {props.data.firstTable.content.map((item, idx) => (
                <>
                  <Tr key={Math.random()}>
                    <Column isTextCenter={false} value={item.segmento} />
                    {item.values.map((value, index) => (
                      <Column
                        key={Math.random()}
                        color={index === 1 ? selectColorByValue(value) : ""}
                        value={value}
                      />
                    ))}
                    {idx === 0 && (
                      <Td rowSpan={4} position="relative" p="4px !important">
                        <Box w="180px" position="absolute" left={0} top={-1}>
                          <MarketShareValue
                            dataGraphic={{
                              label: ["100,0%", "0,0%", "0,0%", "0,0%"],
                              x: [100, 0.01, 0.02, 0.03],
                              y: ["100.1", "0.01", "0.02", "0.03"],
                            }}
                            color="#18387e"
                          />
                        </Box>
                      </Td>
                    )}
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
          <Table style={{ borderCollapse: "separate" }} zIndex={1} ml="-2px">
            <Thead>
              <Tr>
                {props.data.secondTable.headers.map((header) => (
                  <HeaderTable key={Math.random()}>{header}</HeaderTable>
                ))}
              </Tr>
            </Thead>
            <Tbody fontWeight="bold">
              {props.data.secondTable.content.map((item) => (
                <>
                  <Tr key={Math.random()}>
                    {item.values.map((value) => (
                      <Column
                        key={Math.random()}
                        color={selectColorByValue(value)}
                        value={value}
                      />
                    ))}
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </TableContainer>
    </Grid>
  );
};

ItemTable.defaultProps = {
  hasLine: false,
};

export default ItemTable;
