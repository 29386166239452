import styled from "styled-components";

export const ContainerSubMenu = styled.div<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : "99%")};
  height: 100%;
  margin: auto auto;
  display: flex;
  align-items: center;
  justify-content: left;

  .content-bar {
    align-items: center;
    display: flex;
    background-color: #fff;
    padding: 1px;
    margin-bottom: 10px;
  }

  .link-icon,
  .link-icon-hover {
    width: 56px !important;
  }
`;

export const Divisor = styled.div`
  height: 65px;
  border-right: 3px solid var(--light-gray);
  margin-top: 0px;
`;

export const ButtonAtivo = styled.div`
  .ativo {
    background-color: "#444";
  }
`;

export const ItemAtivo = styled.button<{
  icon?: string;
  iconSize?: string;
  active: boolean;
  noMargin?: boolean;
  width?: string;
  text?: string;
}>`
  min-width: ${(props) => (props.width ? props.width : "70px")};
  height: 70px;
  outline: none;
  margin-left: ${(props) => (props.noMargin ? "0" : "5px")};
  border: 1px solid white;
  border-radius: 6px;
  ${(props) =>
    (props.icon &&
      `
    background-image: url(${props.icon});
  `) ||
    `
    font-size: 28px;
    font-weight: bolder;
  `};
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65px;
  transition: 0.3s;
`;

export const SubItem = styled(ItemAtivo)`
  background-color: ${(props) =>
    props.active === true ? "var(--light-gray)" : "#fff"};
  width: 85px;
  height: 70px;
  cursor: pointer;
  background-size: ${(props) => props.iconSize || "55px"};

  &:hover {
    background-color: var(--light-gray);
  }
`;
