import React, { useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import iconNPD from "../../../assets/myExecution/iconsBox.svg";

import NPD from "./NPD/main";

import * as ANavigator from "../../../store/Navigation/actions";
import { stateType, ENavSubMenu } from "../../../store/Navigation/types";
import { GlobalProps } from "../../../store";

import {
  ContainerSubMenu,
  SubItem,
} from "../../../components/Contents/subMenu";
import { ConfigContext } from "../../../services/Context/ConfigContext";

interface ownProp {
  indexSubMenu: ENavSubMenu;
  changeSubMenu: (data: ENavSubMenu) => void;
}

export const SubMenuMyExecutions = ({
  indexSubMenu,
  changeSubMenu,
}: ownProp): JSX.Element => {
  const { config } = useContext(ConfigContext);

  return (
    <>
      <ContainerSubMenu>
        {config.general.hasPromo && (
          <SubItem
            icon={iconNPD}
            onClick={() => changeSubMenu(ENavSubMenu.ME_NPD)}
            active={indexSubMenu === ENavSubMenu.ME_NPD}
          />
        )}
        {/* <SubItem
          icon={store}
          onClick={() => changeDash(INavContent.ME_DETAIL_STORE)}
          active={indexSubMenu === ENavSubMenu.ME_DETAIL_STORE}
        /> */}
      </ContainerSubMenu>
    </>
  );
};

interface stateProps {
  navigation: stateType;
}

type props = stateProps;

const ContentMyExecutions: React.FC<props> = (props: props) => {
  const { indexSubMenu } = props.navigation;

  switch (indexSubMenu) {
    case ENavSubMenu.ME_NPD:
      return <NPD />;
    default:
      break;
  }

  return null;
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ANavigation: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentMyExecutions);
