import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import api from "../../api";
import { IConfigurationSystem } from "./types";

const requestConfig = async (): Promise<
  AxiosResponse<IConfigurationSystem>
> => {
  const response = await api.get<IConfigurationSystem>("config/");
  return response;
};

export const useConfigSystem = (): UseQueryResult<
  AxiosResponse<IConfigurationSystem>,
  unknown
> => {
  return useQuery(["configSystem"], requestConfig, {
    refetchOnWindowFocus: false,
  });
};
