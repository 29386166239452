import { Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";

interface IOwnProps {
  children: React.ReactElement;
  title: string;
  subTitle?: string;
  margin?: string;
  ml?: string;
  mr?: string;
  mt?: string;
  mb?: string;
  bg?: string;
  w?: string;
  minW?: string;
  overflow?: string;
}

const Card: React.FC<IOwnProps> = (props: IOwnProps) => {
  return (
    <Stack
      margin={props.margin}
      mt={props.mt}
      ml={props.ml}
      mr={props.mr}
      mb={props.mb}
      w={props.w}
      h="100%"
      overflow={props.overflow || "auto"}
      minW={props.minW || ""}
    >
      <Flex
        h="45px"
        bg="cardHeaderBackgroundColor"
        align="center"
        justify="center"
        color="white"
      >
        <Text fontSize="20px" fontWeight="bold" whiteSpace="nowrap">
          {props.title}{" "}
          <Text fontSize="14px" fontStyle="italic" as="span">
            {props.subTitle}
          </Text>
        </Text>
      </Flex>
      <Flex mt="5px !important" bg={props.bg || "white"} h="calc(100% - 50px)">
        {props.children}
      </Flex>
    </Stack>
  );
};

Card.defaultProps = {
  margin: "0",
  mb: "0",
  ml: "0",
  mr: "0",
  mt: "0",
  bg: "white",
  w: "100%",
  minW: "",
  overflow: "auto",
  subTitle: "",
};

export default Card;
