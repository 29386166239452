import { Box, Flex, Image } from "@chakra-ui/react";
import react, { MouseEventHandler } from "react";
import { theme } from "../../styles/theme";

interface IOwnProps {
  labelSup: string;
  labelInf: string;
  image: string;
  setView: MouseEventHandler;
  selectedMenu: boolean;
}

const ItemMenu: react.FC<IOwnProps> = (props: IOwnProps) => {
  return (
    <Flex
      fontWeight="300"
      textAlign="left"
      fontSize="10.5px"
      backgroundColor={(props.selectedMenu && "#e2e2e2") || "transparent"}
      mr="5px"
      _focus={{
        color: "none",
        border: "none",
      }}
      _hover={{
        background: "#e2e2e2",
        color: "black",
        border: "1px solid #e2e2e2",
      }}
      px="10px"
      border={`1px solid ${
        (props.selectedMenu && "#e2e2e2") || theme.colors.borderHeaderMenu
      }`}
      borderWidth="1px"
      borderBottomColor={`${
        (props.selectedMenu && "#e2e2e2") || "headerColor"
      }`}
      borderRadius="10px 10px 0 0"
      w="150px"
      h="43px"
      onClick={props.setView}
      cursor="pointer"
      transition="0.3s"
      color={(props.selectedMenu && "black") || theme.colors.textHeaderMenu}
    >
      <Flex justifyContent="flex-start" w="100%" alignItems="center">
        <Image src={props.image} width="30px" mr="10px" />
        <Box>
          {props.labelSup}
          <br />
          {props.labelInf}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ItemMenu;
