import {
  ITypeGraphicHorizontalBar,
  ITypeGraphicPeriodBar,
} from "../../../services/Hooks/OnePage/onePageTypes";

export const validateAndRemoveIndex = (
  array: string[] | number[]
): string[] | number[] => {
  if (array !== undefined)
    return array.length > 0 ? array.slice(1, array.length) : [];

  return [];
};

export const removeArrayIndex = (
  data?: ITypeGraphicHorizontalBar
): ITypeGraphicHorizontalBar => {
  const textValidated: string[] = validateAndRemoveIndex(
    data?.label || []
  ) as string[];
  const XValidated: number[] = validateAndRemoveIndex(
    data?.x || []
  ) as number[];
  const YValidated: string[] = validateAndRemoveIndex(
    data?.y || []
  ) as string[];

  return {
    label: textValidated,
    x: XValidated,
    y: YValidated,
  };
};

export function FormatStringData(data: string): string {
  const dia = data?.split("/")[0];
  const mes = data?.split("/")[1];
  const ano = data?.split("/")[2];

  return `${ano}-${mes?.slice(-2)}-${dia?.slice(-2)}`;
}

export function FormatStringDataBr(data: string): string {
  const dia = data?.split("-")[2];
  const mes = data?.split("-")[1];
  const ano = data?.split("-")[0];

  if (dia && mes && ano) return `${dia}/${mes}/${ano}`;

  return data;
}

export const formatName = (
  data: ITypeGraphicPeriodBar
): ITypeGraphicPeriodBar => {
  const newFormatName = data?.x.map((name) => {
    if (name.includes(" ")) {
      const separateName = name.split(" ");
      return `${separateName[0]}<br />${separateName[1]}`;
    }

    if (name.length > 8) {
      return `${name.substr(0, 7)}.`;
    }

    return name;
  });

  return {
    ...data,
    x: newFormatName,
  };
};

export const createDiferentialName = (quantity: number): string => {
  let lol = "-";
  for (let index = 0; index < quantity; index += 1) {
    lol = lol.concat(" ");
  }

  return lol;
};

export const addVoidValueGraphic = (
  value: number,
  data?: ITypeGraphicPeriodBar
): ITypeGraphicPeriodBar => {
  const newListMiddleLine = {
    ...(data ?? { label: [], x: [], y: [] }),
  };
  const lenghtList = newListMiddleLine.x.length;

  for (let index = lenghtList; index < value; index += 1) {
    newListMiddleLine.label.push("");
    newListMiddleLine.x.push(createDiferentialName(index));
    newListMiddleLine.y.push(0);
  }

  return newListMiddleLine;
};

export const formatedLegendName = (legends: string[]): string[] => {
  const formatedLegend = legends.map((item) => {
    let newLegend = item;
    if (item.includes(" ")) {
      const separateLegend = item
        .replace(" ", "*")
        .split("*", 2)
        .map((legend) => {
          let response = '';

          if (legend.length > 8) {
              if (legend.length === 17) {
                  response = `${legend.split('-')[0]}<br >${legend.split('-')[1]}`
              } else {
                response = legend.substring(0, 6)
              }
          } else {
              response = legend
          }

          return response;
        });
      newLegend = `${separateLegend[0]}<br >${separateLegend[1]}`;
    } else if (newLegend.length > 8){
      newLegend = `${newLegend.substring(0, 6)}.`;
    }
    return newLegend;
  });
  return formatedLegend;
};
