import React from "react";
import { useRequestExtraExposure } from "../../../services/Hooks/MyExecution/useRequestExtraExposure";
import { useRequestExtraExposureTable } from "../../../services/Hooks/MyExecution/useRequestExtraExposureTable";
import { useRequestShareOfShelfTable } from "../../../services/Hooks/MyExecution/useRequestShareOfShelfTable";
import { IStateFilters } from "../../../store/SideFilters/types";
import TableComplements from "../../TableComplements";

interface IOwnProps {
  SFilters: IStateFilters;
  period: string;
}

const ExtraExposureTable: React.FC<IOwnProps> = ({ SFilters, period }) => {
  const extraExposureTable = useRequestExtraExposureTable(period, SFilters);

  return (
    <TableComplements
      // mainFilter={SAllFilters.data.myExecution.subviewOSA.main.categories}
      // secundaryFilter={SFilters.brand.listFilters || []}
      noFilter
      titleCard="Share de Pontos Extras %"
      subTitleCard="(Mensal)"
      subTitle="Detalhe ALAVANCAS / MARCAS"
      data={extraExposureTable.data}
      isLoading={extraExposureTable.isLoading}
      // goDetail={{
      //   detailPage: INavContent.MN_DETAIL_COMPLIANCE,
      //   label: "Detalhe loja",
      // }}
    />
  );
};

export default ExtraExposureTable;
