import { Flex, List, ListItem, Stack, Text } from "@chakra-ui/react";
import React from "react";

interface IOwnProps {
  title: string;
  subTitle: string;
  itens?: string[];
  children?: JSX.Element;
}

const Column: React.FC<IOwnProps> = ({ title, subTitle, itens, children }) => {
  return (
    <Stack w="100%" ml="5px" color="black" minW="160px" maxW="200px">
      <Text
        fontSize="14px"
        background="#e7e6e6"
        p="8px 12px"
        textAlign="center"
        fontWeight="bold"
      >
        {title}
      </Text>
      <Flex
        mt="5px !important"
        minH="33px"
        alignItems="center"
        justifyContent="center"
        background="white"
        position="sticky"
        top="0px"
      >
        <Text fontWeight="bold" whiteSpace="pre-line" textAlign="center">{subTitle}</Text>
      </Flex>
      {(itens && (
        <List mt="0px !important">
          {itens.map((item) => (
            <ListItem
              textAlign="center"
              h="31px"
              lineHeight="31px"
              mt="2px !important"
              bg="#A6A6A6"
              color="white"
            >
              <Text fontWeight="bold">{item}</Text>
            </ListItem>
          ))}
        </List>
      )) ||
        children}
    </Stack>
  );
};

Column.defaultProps = {
  itens: undefined,
  children: <></>,
};

export default Column;
