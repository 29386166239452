import React, {
  ChangeEvent,
  FormEvent,
  ReactEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Flex, Input, Text } from "@chakra-ui/react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { ImSpinner2 } from "react-icons/im";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// actions redux
import * as actionNavigator from "../../../store/Navigation/actions";
import * as actionAuth from "../../../store/Authentication/Login/actions";

// images
import Logo from "../../../assets/TradexLogos/logo-industry.svg";

// Types
import { login, stateAuth } from "../../../store/Authentication/Login/types";
import { INavPage } from "../../../store/Navigation/types";

// Props Global
import { GlobalProps } from "../../../store/index";

// Components
import {
  BackgroundConteiner,
  ErrorMessage,
  Form,
  FormConteiner,
  InputPassword,
  InputPasswordConteiner,
  Label,
  MessageResetSuccess,
  Submit,
} from "./Login.element";

// Hooks
import {
  ERenderingForms,
  useResetPassword,
} from "../../../services/Hooks/modifyPassword/useResetPassword";
import { ConfigContext } from "../../../services/Context/ConfigContext";

interface stateProps {
  stateLogin: stateAuth;
}

interface dispatchProps {
  actionsNavigator: {
    changeMain(indexPage: INavPage): void;
  };
  actionsLogin: {
    requestRefreshToken(data: login): void;
  };
}

type props = stateProps & dispatchProps;

const Login: React.FC<props> = (props: props) => {
  const { changeMain } = props.actionsNavigator;
  const { error } = props.stateLogin;
  const { requestRefreshToken } = props.actionsLogin;

  const [inputUser, setInputUser] = useState<string>("");
  const [inputPassword, setInputPassword] = useState<string>("");
  const [inputEmailRecovery, setInputEmailRecovery] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [renderingForms, setRenderingForms] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const refButtonSubmit = useRef<HTMLButtonElement>(null);

  const { config } = useContext(ConfigContext);

  const validateForm = yup.object().shape({
    user: yup
      .string()
      .required("O campo E-Mail é obrigatorio!")
      .email("Formato de E-Mail incorreto!"),

    password: yup.string().required("O campo Senha é obrigatorio!"),
  });

  // Hooks
  const { refetch } = useResetPassword({
    inputEmailRecovery,
    setErrorMessage,
    setRenderingForms,
    setInputEmailRecovery,
  });

  const { register, formState, setError, clearErrors } = useForm({
    resolver: yupResolver(validateForm),
  });

  // Functions

  interface ILoginData {
    user: string;
    password: string;
  }

  const validationForm = (dataLogin: ILoginData): boolean => {
    let isInvalid = false;
    clearErrors();
    Object.entries(dataLogin).forEach(([key, value]) => {
      if (value === "") {
        setError(
          key,
          {
            type: "required",
            message: `O campo ${key} é obrigatorio`,
          },
          { shouldFocus: true }
        );

        isInvalid = true;
      }
    });

    return isInvalid;
  };

  const requestAuthentication = async (event?: FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    const payload: login = {
      email: inputUser,
      password: inputPassword,
      navigator: changeMain,
    };

    const invalid = validationForm({
      user: inputUser,
      password: inputPassword,
    });

    if (!invalid) requestRefreshToken(payload);
  };

  const handleInputUser: ReactEventHandler<HTMLInputElement> = (e) => {
    setInputUser(e.currentTarget.value);
  };

  const sendMailToRecoveryPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    refetch();
  };

  useEffect(() => {
    const listner = (e: KeyboardEvent) => {
      if (e.key === "enter" || e.key === "enter" || e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        if (refButtonSubmit) {
          refButtonSubmit.current?.click();
        }
      }
    };

    window.addEventListener("keyup", listner);

    return () => {
      window.removeEventListener("keyup", listner);
    };
  }, []);

  return (
    <BackgroundConteiner>
      <FormConteiner>
        <img src={Logo} alt={`logo ${config.general.industryName}`} />

        {(renderingForms === ERenderingForms.FormsLogins && (
          // <Stack mt="30px" maxW="700px">
          //   <Text
          //     color="#ffc600"
          //     as="h1"
          //     fontSize={30}
          //     textAlign="center"
          //     fontWeight="bold"
          //   >
          //     Olá, EXÉRCITO AMARELO
          //   </Text>
          //   <Text fontSize={20} mt="40px !important">
          //     Estamos atualizando as novas regras de ponto extras e fazendo
          //     recálculo dos indicadores da farmácia amarela. Por isso o sistema
          //     hoje vai ficar indisponível.
          //   </Text>
          //   <Text mt="25px !important" fontSize={20}>
          //     Mas fique tranquilo, isso é rapidinho e amanhã o sistema estará
          //     normalizado!
          //   </Text>
          //   <Text mt="25px !important" fontSize={20}>
          //     Um grande abraço.
          //   </Text>
          //   <Text
          //     mt="80px !important"
          //     color="#ffc600"
          //     fontWeight="bold"
          //     fontSize={20}
          //     textAlign="end"
          //   >
          //     Equipe Tradex.
          //   </Text>
          // </Stack>
          <Form
            as="form"
            onSubmit={(e: FormEvent<HTMLFormElement>) =>
              requestAuthentication(e)
            }
          >
            <Label>Login</Label>
            <Input
              value={inputUser}
              id="user"
              type="email"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register("user")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputUser(e)
              }
              placeholder="email@industria.com"
              background="white"
            />
            {formState.errors.user && (
              <Text color="red" fontSize="10px" mt="-10px" mb="10px">
                {formState.errors?.user.message}
              </Text>
            )}
            <Label>Senha</Label>
            <InputPasswordConteiner>
              <InputPassword
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register("password")}
                type={(!showPassword && "password") || "text"}
                placeholder="********"
                value={inputPassword}
                onChange={(e) => setInputPassword(e.currentTarget.value)}
              />
              {(!showPassword === true && (
                <FiEyeOff
                  size={35}
                  style={{ padding: "0 6px", color: "#616480" }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )) || (
                <FiEye
                  size={35}
                  style={{ padding: "0 6px", color: "#616480" }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </InputPasswordConteiner>
            {formState.errors.password && (
              <Text color="red" fontSize="10px" mt="-10px" mb="10px">
                {formState.errors?.password.message}
              </Text>
            )}
            <span
              aria-hidden="true"
              onClick={() => setRenderingForms(ERenderingForms.SendEmail)}
            >
              Esqueci minha senha
            </span>
            {error && <ErrorMessage>Email ou senha inválidos</ErrorMessage>}
            <Submit type="submit" ref={refButtonSubmit} background="#00defd">
              <Flex justifyContent="center">
                {(props.stateLogin.isLoading && <ImSpinner2 />) || "Entrar"}
              </Flex>
            </Submit>
          </Form>
        )) ||
          (renderingForms === ERenderingForms.SendEmail && (
            <Form
              as="form"
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                sendMailToRecoveryPassword(e)
              }
            >
              <Label>Informe seu email</Label>
              <input
                value={inputEmailRecovery}
                type="email"
                name="emailReset"
                id="emailReset"
                required
                onChange={(e) => setInputEmailRecovery(e.currentTarget.value)}
                placeholder="email@industria.com"
              />
              {errorMessage !== "" && <p>{errorMessage}</p>}
              <div>
                <Submit
                  onClick={() => setRenderingForms(ERenderingForms.FormsLogins)}
                  background="#9699B0"
                >
                  Cancelar
                </Submit>
                <Submit type="submit" background="#00defd" margin>
                  Enviar
                </Submit>
              </div>
            </Form>
          )) ||
          (renderingForms === ERenderingForms.ScreenMessage && (
            <MessageResetSuccess>
              <p>Uma nova senha foi enviada para o seu email.</p>
              <span
                aria-hidden="true"
                onClick={() => setRenderingForms(ERenderingForms.FormsLogins)}
              >
                Faça login com a nova senha <span>clicando aqui</span>
              </span>
            </MessageResetSuccess>
          ))}
      </FormConteiner>
    </BackgroundConteiner>
  );
};

const mapStateToProps = (state: GlobalProps) => {
  return {
    stateLogin: state.loginAuth,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actionsNavigator: bindActionCreators(actionNavigator, dispatch),
    actionsLogin: bindActionCreators(actionAuth, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
