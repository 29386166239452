import styled from 'styled-components';

export const WrapperHeader = styled.div`
  display: grid;
  grid-template-columns: auto 15px;
  width: 100%;
  height: 100%;

  & > div {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
`;
