import {
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import react from "react";

// imagens
import editIcon from "../../assets/MyFinancial/edit.svg";

interface IBrand {
  qtdFamily: number;
  name: string;
}

interface IMainTable {
  brand?: IBrand;
  family: string;
  baseVolume: string;
  ProjVolume: string;
  investment: string;
  investmentPercent: string;
  ROIProj: number;
}

interface IComplementTable {
  investment: string;
  realValume: string;
  realROI: number;
}

export interface IDataFinancialPlanning {
  codAction: number;
  gondolaEnd: string;
  mainTable: IMainTable[];
  complementTable: IComplementTable[];
}

interface IOwnProps {
  data: IDataFinancialPlanning;
  alavancas: string;
}

const TableFinancialPlanning: react.FC<IOwnProps> = ({
  data,
  alavancas,
}: IOwnProps) => {
  const setColor = (value: number): JSX.Element => {
    let colors = "#a144ac";
    if (value >= 1) colors = "#00B050";
    else if (value < 0) colors = "#C00000";

    return <Text color={colors}>{value}</Text>;
  };

  return (
    <>
      <TableContainer w="auto" h="fit-content" mr="5px">
        <Table style={{ borderCollapse: "separate" }}>
          <Thead>
            <Tr>
              <Th
                fontSize="11px"
                textAlign="center"
                color="black"
                bg="#E7E6E6"
                p={0}
                height="31px"
              >
                Cod. Ação
              </Th>
              <Th
                fontSize="11px"
                textAlign="center"
                color="black"
                bg="#E7E6E6"
                colSpan={3}
                p={0}
                height="31px"
              >
                Detalhe da Ação
              </Th>
              <Th
                fontSize="11px"
                textAlign="center"
                color="black"
                bg="#E7E6E6"
                colSpan={5}
                p={0}
                height="31px"
              >
                Planejamento Financeiro
              </Th>
            </Tr>
          </Thead>
          <Tbody color="black" fontSize="11px">
            <Tr fontWeight="bold">
              <Td
                minW="100px"
                p={0}
                height="31px"
                rowSpan={2}
                border="none"
                textAlign="center"
              >
                <Text fontSize="28px" textAlign="center" fontWeight="bold">
                  {data.codAction}
                </Text>
              </Td>
              <Td
                minW="110px"
                p={0}
                height="31px"
                bg="#E7E6E6"
                textAlign="center"
              >
                {alavancas}
              </Td>
              <Td minW="110px" py={0} px="8px" height="31px" bg="#E7E6E6">
                MARCAS
              </Td>
              <Td minW="110px" p={0} pl="8px" height="31px" bg="#E7E6E6">
                FÁMILIA
              </Td>
              <Td
                minW="110px"
                p={0}
                height="31px"
                bg="#E7E6E6"
                textAlign="center"
              >
                Volume BASE (un)
              </Td>
              <Td
                minW="110px"
                p={0}
                height="31px"
                bg="#E7E6E6"
                textAlign="center"
              >
                Volume PROJ (un)
              </Td>
              <Td
                minW="110px"
                p={0}
                height="31px"
                bg="#E7E6E6"
                textAlign="center"
              >
                Investimento R$
              </Td>
              <Td
                minW="110px"
                p={0}
                height="31px"
                bg="#E7E6E6"
                textAlign="center"
              >
                Investimento %
              </Td>
              <Td
                p={0}
                minW="110px"
                height="31px"
                bg="#E7E6E6"
                textAlign="center"
              >
                ROI Proj
              </Td>
            </Tr>
            {data.mainTable.map((values, index) => {
              return (
                <Tr>
                  {index === 1 && (
                    <Td
                      p={0}
                      height="31px"
                      bg="#E7E6E6"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      Editar
                    </Td>
                  )}
                  {index === 2 && (
                    <Td
                      p={0}
                      height="31px"
                      rowSpan={data.mainTable.length - 2}
                      textAlign="center"
                      border="none"
                      fontWeight="bold"
                    >
                      <Image
                        src={editIcon}
                        mx="auto"
                        alt="icon-edit"
                        width="40px"
                        cursor="pointer"
                      />
                    </Td>
                  )}
                  {index === 0 && (
                    <Td
                      p={0}
                      rowSpan={data.mainTable.length}
                      w={0}
                      border="1px solid #E7E6E6"
                    >
                      <Flex w="189.5px" bg="yellow" objectFit="cover">
                        <Image
                          src={data.gondolaEnd}
                          h="100%"
                          alt="icon-lavarage"
                        />
                      </Flex>
                    </Td>
                  )}
                  {values.brand && (
                    <Td
                      py={0}
                      px="8px"
                      height="31px"
                      whiteSpace="nowrap"
                      rowSpan={values.brand?.qtdFamily}
                      border="1px solid #E7E6E6"
                      fontWeight={
                        index === data.mainTable.length - 1 ? "bold" : "none"
                      }
                      bg={index === data.mainTable.length - 1 ? "#E7E6E6" : ""}
                      colSpan={index === data.mainTable.length - 1 ? 2 : 1}
                    >
                      {values.brand?.name}
                    </Td>
                  )}
                  {index !== data.mainTable.length - 1 && (
                    <Td
                      py={0}
                      px="8px"
                      whiteSpace="nowrap"
                      height="31px"
                      border="1px solid #E7E6E6"
                      bg={index === data.mainTable.length - 1 ? "#E7E6E6" : ""}
                    >
                      {values.family}
                    </Td>
                  )}

                  <Td
                    p={0}
                    height="31px"
                    border="1px solid #E7E6E6"
                    textAlign="center"
                    bg={index === data.mainTable.length - 1 ? "#E7E6E6" : ""}
                    fontWeight={
                      index === data.mainTable.length - 1 ? "bold" : "none"
                    }
                  >
                    {values.baseVolume}
                  </Td>
                  <Td
                    p={0}
                    height="31px"
                    border="1px solid #E7E6E6"
                    textAlign="center"
                    bg={index === data.mainTable.length - 1 ? "#E7E6E6" : ""}
                    fontWeight={
                      index === data.mainTable.length - 1 ? "bold" : "none"
                    }
                  >
                    {values.ProjVolume}
                  </Td>
                  <Td
                    p={0}
                    height="31px"
                    border="1px solid #E7E6E6"
                    textAlign="center"
                    bg={index === data.mainTable.length - 1 ? "#E7E6E6" : ""}
                    fontWeight={
                      index === data.mainTable.length - 1 ? "bold" : "none"
                    }
                  >
                    {values.investment}
                  </Td>
                  <Td
                    p={0}
                    height="31px"
                    border="1px solid #E7E6E6"
                    textAlign="center"
                    bg={index === data.mainTable.length - 1 ? "#E7E6E6" : ""}
                    fontWeight={
                      index === data.mainTable.length - 1 ? "bold" : "none"
                    }
                  >
                    {values.investmentPercent}
                  </Td>
                  <Td
                    p={0}
                    height="31px"
                    border="1px solid #E7E6E6"
                    textAlign="center"
                    fontWeight="bold"
                    bg={index === data.mainTable.length - 1 ? "#E7E6E6" : ""}
                  >
                    {setColor(values.ROIProj)}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {data.complementTable && (
        <TableContainer
          w="auto"
          h="fit-content"
          mr="5px"
          borderLeft="2px solid #A5A5A5"
          pl="5px"
        >
          <Table style={{ borderCollapse: "separate" }}>
            <Thead>
              <Tr>
                <Th
                  fontSize="11px"
                  textAlign="center"
                  color="#ffffff"
                  bg="#6ec1e4"
                  colSpan={3}
                  p={0}
                  height="31px"
                  textTransform="none"
                >
                  Realizado
                </Th>
              </Tr>
              <Tr>
                <Th
                  fontSize="11px"
                  textAlign="center"
                  color="#ffffff"
                  bg="#6ec1e4"
                  py={0}
                  height="31px"
                  textTransform="none"
                >
                  Investimento R$
                </Th>
                <Th
                  fontSize="11px"
                  textAlign="center"
                  color="#ffffff"
                  bg="#6ec1e4"
                  py={0}
                  height="31px"
                  textTransform="none"
                >
                  Volume REAL (un)
                </Th>
                <Th
                  fontSize="11px"
                  textAlign="center"
                  color="#ffffff"
                  bg="#6ec1e4"
                  py={0}
                  height="31px"
                  textTransform="none"
                >
                  ROI Real
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.complementTable.map((value, index) => {
                return (
                  <Tr>
                    <Td
                      height="31px"
                      border="1px solid #E7E6E6"
                      py={0}
                      textAlign="center"
                      color="black"
                      fontWeight={
                        data.complementTable.length - 1 === index ? "bold" : ""
                      }
                    >
                      {value.investment}
                    </Td>
                    <Td
                      height="31px"
                      border="1px solid #E7E6E6"
                      py={0}
                      textAlign="center"
                      color="#A6A6A6"
                      fontWeight="bold"
                    >
                      {value.realValume}
                    </Td>
                    <Td
                      height="31px"
                      border="1px solid #E7E6E6"
                      py={0}
                      textAlign="center"
                      fontWeight="bold"
                    >
                      {setColor(value.realROI)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TableFinancialPlanning;
