import styled from "styled-components";

// configiguration platform
import { theme } from "../../../../styles/theme";

interface IPropStyle {
  margin?: string;
  paddingTable?: string;
  font?: string;
  border?: string;
  padding?: string;
  width?: string;
  minWidth?: string;
  color?: string;
  inline?: boolean;
  background?: string;
  align?: string;
  top?: string;
  overflow?: boolean;
  sellOutOverflow?: boolean;
  display?: string;
  columns?: string;
  gridColumns?: string;
  height?: string;
  minW?: string;
  heightItem?: string;
  marginItem?: string;
  titleHeight?: string;
  textAlign?: string;
  rows?: number;
  gridGap?: string;
}

export const WrapperSubView = styled.div<IPropStyle>`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => props.height || "inherit"};
  padding: ${(props) => (props.padding ? props.padding : "5px")};
  display: flex;
  overflow: auto;
  margin: ${(props) => props.margin || "0"};

  ${(props) =>
    props.sellOutOverflow &&
    `overflow-x: auto;
  `};
  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }
`;

interface IConteinerProps {
  marginRight?: string;
  marginTop?: string;
  width?: string;
  minWidth?: string;
  display?: string;
  columns?: string;
  inline?: boolean;
  height?: string;
}

export const Container = styled.section<IConteinerProps>`
  margin-right: ${(props) => props.marginRight || "5px"};
  margin-top: ${(props) => props.marginTop || "0px"};
  width: ${(props) => (props.width ? props.width : "100%")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "501px")};
  display: ${(props) => props.display || "flex"};
  grid-template-columns: ${(props) => props.columns || ""};
  column-gap: 5px;
  flex-direction: ${(props) => (props.inline ? "row" : "column")};
  color: ${(props) => (props.color ? props.color : "var(--white)")};
  height: ${(props) => props.height || "100%"};

  .btn-download-excel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #c05c20;
    box-shadow: none;
    border: 1.5px solid #c05c20;
    transition: filter 0.2s;
    padding: 0 8px;

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }

    :enabled {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }

    :disabled {
      background: gray;
      border: 1.5px solid gray;
    }

    svg {
      color: white;
    }

    color: white;

    :hover {
      filter: brightness(0.92);
    }
  }

  .qtd-sku {
    display: flex;

    p {
      margin-left: auto;

      b {
        width: 0.725rem;
      }
    }
  }

  & > table {
    width: 100%;
    min-width: 630px;
    text-align: left;
    font-size: 0.6rem;
  }
`;

export const ConteinerColumn = styled.div<IPropStyle>`
  width: ${(props) => props.width || "100%"};
  text-align: center;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.minW || "114px"};
  overflow: hidden;
  height: ${(props) => props.height};

  & > div {
    /* display: grid; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* grid-template-rows: 24px repeat(51, auto); */
    height: calc(100% - 32px);
    width: auto;

    ${(props) =>
      props.rows &&
      props.rows > 0 &&
      `grid-gap: ${props.gridGap || "1.84px"};
      grid-template-rows: 24px repeat(${props.rows}, auto);`}

    & > span {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      height: 100%;
    }

    & > span > img {
      width: 16.42px;
      display: block;
      margin: auto;
    }

    & > p {
      font-weight: bold;
      font-size: 12px;
      margin: auto 0;
      text-align: center;
      padding: 2px 0;

      & + img {
        margin-top: 5px;
      }
    }
  }
`;

export const ItemColumn = styled.span<IPropStyle>`
  background-color: ${(props) => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  margin-top: -1px !important;
  margin-bottom: 4.1px !important;
  color: ${(props) => props.color};
  font-size: ${(props) => props.font};
  text-align: ${(props) => props.textAlign};
`;

export const Title = styled.header<IPropStyle>`
  margin: ${(props) => props.margin || "10px 0 3px"};
  padding: ${(props) => props.padding || "5px"};
  height: ${(props) => props.height || "auto"};
  background-color: var(--light-gray);
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.font || "12px"};
  width: ${(props) => props.width || "100%"};
  display: ${(props) => props.display || "flex"};
  justify-content: center;
  align-items: center;

  /* position: absolute;
  width: 7.15%;
  margin-top: -30px; */

  ${(props) =>
    props.display === "flex" &&
    ` display: flex;
      justify-content: space-around;
    `}
`;

export const LegendTable = styled.section<IPropStyle>`
  background-color: #e7e6e6;
  min-width: ${(props) => props.width || "0"};
  width: 0px;
  border-radius: 7px;
  height: 65px;
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

export const MenuItem = styled.div<{
  noMargin?: boolean;
  bold?: boolean;
  width?: string;
  fontSize?: string;
  minWidth?: string;
  class?: string;
  noMarginP?: boolean;
  spanBold?: boolean;
  titleBoxAlign?: string;
}>`
  margin-left: ${(props) => (props.noMargin ? "0" : "5px")};
  text-align: center;
  margin-left: 5px;
  min-width: ${(props) => props.minWidth || "114"};
  font-size: 14px;

  & > p {
    font-style: italic;
    display: flex;
    justify-content: ${(props) => props.titleBoxAlign};
    align-items: baseline;
    ${(props) =>
      (!props.noMarginP &&
        `
      margin-bottom: 0.5rem;
    `) ||
      `
      margin-bottom: 0;
    `}

    font-weight: ${(props) => (props.bold ? "bold" : "")};

    & > img {
      width: 18px;
      margin: auto 0;
      margin-right: 5px;
      fill: red;
    }

    & > span {
      font-size: 9px;
      font-weight: normal;
      margin-left: 5px;
    }
  }

  & > span {
    font-size: ${(props) =>
      props.fontSize !== "" ? props.fontSize : "1.3rem"};
    font-weight: ${(props) => (props.spanBold ? "bold" : "normal")};
    display: block;
    min-width: 114px;
    background: #e7e6e6;
    padding: ${(props) => (props.noMarginP ? "0" : "5px 0")};
  }
`;

export const WrapperCardSubMenu = styled.div<IPropStyle>`
  height: 100%;
  width: inherit;
  display: grid;
  flex: 1;
  grid-template-columns: ${(props) => props.gridColumns || "repeat(7, 1.5fr)"};
  align-items: center;

  .toltip {
    background: red;
  }

  /* & > div:nth-child(4):hover::before {
    content: 'Crescimentos acima de 100% e abaixo de -100% travados em escalas de até 100%.';
    color: white;
    position: relative;
    top: 70px;
    opacity: 0.9;
    width: 220px;
    height: 90px;
    background-color: blue;
    position: absolute;
    border-radius: 4px;
    padding: 8px 4px;
  }

  & > div:nth-child(4):hover::after {
    content: '';
    position: relative;
    top: 70px;
    opacity: 0.9;
    width: 30px;
    height: 30px;
    background-color: blue;
    position: absolute;
    transform: rotate(180deg);
  } */
`;

export const ContainerFlex = styled.div<IPropStyle>`
  overflow: auto;
  width: ${(props) => (props.width ? props.width : "99.7%")};
  height: 100%;
  margin: ${(props) => (props.margin ? props.margin : "5px")};

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }
`;

export const ScrollingX = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding-right: 5px;

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }
`;

export const TableSellout = styled.table`
  & > tbody > tr {
    background-color: red;
    height: 25px;
  }
`;

export const Th = styled.th<IPropStyle>`
  background: ${(props) => props.color};
  padding: ${(props) => props.padding};
`;
