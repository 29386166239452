import { action } from 'typesafe-actions';
import {
  AProfileType,
  dataProfile,
  IProfileError,
  IProfileSuccess,
  IRequestProfile,
} from './types';

export const requestProfile = (): IRequestProfile =>
  action(AProfileType.REQUEST_PROFILE);

export const profileSuccess = (data: dataProfile): IProfileSuccess =>
  action(AProfileType.PROFILE_SUCCESS, data);

export const profileError = (): IProfileError =>
  action(AProfileType.PROFILE_FAIL);
