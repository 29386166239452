import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";
import { ISubTableComplements, ISubTableGraphics } from "./types";

const requestSubComplianceExtraExposure = async (
  filters: IStateFilters
): Promise<ISubTableComplements> => {
  const presenceCategory = await api.get<{ data: ISubTableGraphics }>(
    `compliance_extra_exposure_category/`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  const presenceRegion = await api.get<{ data: ISubTableGraphics }>(
    `compliance_extra_exposure_region/`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  const presenceSubChannel = await api.get<{ data: ISubTableGraphics }>(
    `compliance_extra_exposure_sub_channel/`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  const presenceBanner = await api.get<{ data: ISubTableGraphics }>(
    `compliance_extra_exposure_top_banner/`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  return {
    presenceCategory: presenceCategory.data.data,
    presenceRegion: presenceRegion.data.data,
    presenceSubChannel: presenceSubChannel.data.data,
    presenceBanner: presenceBanner.data.data,
  };
};

export const useRequestSubComplianceExtraExposure = (
  filters: IStateFilters
): UseQueryResult<ISubTableComplements, unknown> => {
  return useQuery(
    ["subComplianceExtraExposure", filters],
    () => requestSubComplianceExtraExposure(filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
