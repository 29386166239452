import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface IOwnProps {
  children: string;
  fs?: string;
  mb?: string;
  mw?: string;
}

const HeaderGraphic: React.FC<IOwnProps> = ({
  children,
  fs,
  mb,
  mw,
}: IOwnProps): JSX.Element => {
  return (
    <Flex
      bg="#E7E6E6"
      h="31px"
      mt="0 !important"
      justify="center"
      align="center"
      mb={mb}
      minW={mw}
      textTransform="inherit"
    >
      <Text fontWeight="bold" fontSize={fs} color="black">
        {children}
      </Text>
    </Flex>
  );
};

HeaderGraphic.defaultProps = {
  fs: '12px',
  mb: '0px',
  mw: '',
};

export default HeaderGraphic;
