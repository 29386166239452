import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Box,
  Flex,
  Grid,
  Image,
  Link,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CgClose } from "react-icons/cg";
import { HiOutlineArrowDown } from "react-icons/hi";
import { RiFileExcel2Line } from "react-icons/ri";
import * as ANavigator from "../../../../../store/Navigation/actions";
import * as AFilters from "../../../../../store/SideFilters/actions";
import {
  ENavSubMenu,
  filterComponent,
  INavContent,
} from "../../../../../store/Navigation/types";

import excel from "../../../../../assets/MyDay/excel.svg";
import lavarageIcon from "../../../../../assets/MyDay/iconLavarage.svg";
import productsIcon from "../../../../../assets/MyDay/products.svg";
import kpIcon from "../../../../../assets/MyDay/kpi.svg";
import calendarIcon from "../../../../../assets/MyDay/calendar.svg";
import ShareValue from "../../../../../components/Graphics/MarketShareValue/MarketShareValue";

import { TableSubView } from "../../../../../components/Contents/Resources/SubView/subView";

import { GlobalProps } from "../../../../../store";
import TemporalFilter from "../../../../../components/TemporalFilter";
import {
  filter,
  stateMarketShare,
} from "../../../../../store/SideFilters/RequestFilters/types";
import Column from "../../../../../components/DetailPages/Columns";
import FiltersList, {
  INITIAL_FILTERS,
  IShowFilters,
} from "../../../../../components/SideFilterMenu/Resource/FiltersList";
import { ConfigContext } from "../../../../../services/Context/ConfigContext";
import {
  IProductFilter,
  IStateFilters,
  TPromoFilter,
} from "../../../../../store/SideFilters/types";
import {
  ELavarage,
  ISelectedFiltersKpi,
  useRequestKpiStore,
} from "../../../../../services/Hooks/MyExecution/useRequestKpiStore";
import { EPeriod } from "../../../../../components/CardSubMenu";
import LoadingComponent from "../../../../../components/LoadingComponent";
import {
  ModalOverlay,
  ModalWrapper,
} from "../../../../../components/Modal/styles";
import { UModalCalendar } from "../../../MyNegotiations/ViewCalendar/ModalCalendar/styles";
import { theme } from "../../../../../styles/theme";
import { useRequestKpiStoreExcel } from "../../../../../services/Hooks/MyExecution/useKpiStoreExportData";
import EmptyMessage from "../../../../../components/EmptyMessage";
import { ITypeGraphicHorizontalBar } from "../../../../../services/Hooks/OnePage/onePageTypes";

interface IStateProps {
  SAllFilters: stateMarketShare;
  SFilters: IStateFilters;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeTitleMenu(newTitle: string): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeFilters(filters: filterComponent[]): void;
  };

  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };
}

type props = IStateProps & IDispatchProps;

const DetailKPI: React.FC<props> = ({
  DNavigator,
  SAllFilters,
  DFilters,
  SFilters,
}): JSX.Element => {
  const { config } = useContext(ConfigContext);

  const [widthContent, setWidthContent] = useState<number>(0);
  const [limitItem, setLimitItem] = useState<number>(50);
  const [heightGraphics, setHeightGraphics] = useState<number>(0);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const [popUpExportExcel, setPopUpExportExcel] = useState<boolean>(false);

  const refTable = useRef<HTMLTableElement>(null);
  const refDivContent = useRef<HTMLDivElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);

  const [stores, setStores] = useState<any>([]);
  const [selectedStore, setSelectedStore] = useState<string>("");

  const [banners, setBanners] = useState<any>([]);
  const [selectedBanner, setSelectedBanner] = useState<string>("");

  const [selections, setSelections] = useState<ISelectedFiltersKpi>({
    selectPeriod: {
      id: EPeriod.MONTHLY,
      label: "Mensal",
    },
    selectedPresence: {
      id: ELavarage.PRESENCA,
      label: "Presença",
    },
    selectedCategoryFilter: "",
    selectedBrandFilter: "",
  });

  const headers: string[] = ["#", "CNPJ", "Nome da Loja", "Bandeira", "UF"];

  const { data, isLoading } = useRequestKpiStore(selections, SFilters);
  const file = useRequestKpiStoreExcel(selections, SFilters);

  const limitItensTable = (data?.data?.dataTable || []).slice(0, limitItem);
  const limitActiveStore = (data?.data?.activeStore || []).slice(0, limitItem);
  const limitAct: ITypeGraphicHorizontalBar = {
    label: data?.data.act.data.label.slice(0, limitItem) || [],
    x: data?.data.act.data.x.slice(0, limitItem) || [],
    y: data?.data.act.data.y.slice(0, limitItem) || [],
  };

  const limitLM: ITypeGraphicHorizontalBar = {
    label: data?.data.lm.data.label.slice(0, limitItem) || [],
    x: data?.data.lm.data.x.slice(0, limitItem) || [],
    y: data?.data.lm.data.y.slice(0, limitItem) || [],
  };
  const limitL3M: ITypeGraphicHorizontalBar = {
    label: data?.data.l3m.data.label.slice(0, limitItem) || [],
    x: data?.data.l3m.data.x.slice(0, limitItem) || [],
    y: data?.data.l3m.data.y.slice(0, limitItem) || [],
  };
  const limitYTD: ITypeGraphicHorizontalBar = {
    label: data?.data?.ytd.data.label.slice(0, limitItem) || [],
    x: data?.data?.ytd.data.x.slice(0, limitItem) || [],
    y: data?.data?.ytd.data.y.slice(0, limitItem) || [],
  };
  const limitLastWeek: ITypeGraphicHorizontalBar = {
    label: data?.data?.lastWeek?.data.label.slice(0, limitItem) || [],
    x: data?.data?.lastWeek?.data.x.slice(0, limitItem) || [],
    y: data?.data?.lastWeek?.data.y.slice(0, limitItem) || [],
  };
  const limitPenultimateWeek: ITypeGraphicHorizontalBar = {
    label: data?.data.penultimateWeek?.data.label.slice(0, limitItem) || [],
    x: data?.data.penultimateWeek?.data.x.slice(0, limitItem) || [],
    y: data?.data.penultimateWeek?.data.y.slice(0, limitItem) || [],
  };

  const handleSelectedPeriod = (periodSelected: filter) => {
    setStores([]);
    setSelectedStore("");
    setBanners([]);
    setSelectedBanner("");
    setSelections({
      ...selections,
      selectPeriod: periodSelected,
    });
  };

  const handleSelectedLavarage = (lavarageSelected: filter) => {
    setStores([]);
    setSelectedStore("");
    setBanners([]);
    setSelectedBanner("");

    selections.selectedBrandFilter = "";
    selections.selectedCategoryFilter = "";

    setSelections({
      ...selections,
      selectedPresence: lavarageSelected,
    });
  };

  const handleLimitItem = (e: ChangeEvent<HTMLSelectElement>) => {
    const limitSelected = parseInt(e.currentTarget.value, 10);
    setLimitItem(limitSelected);
  };

  const convertFirstChartToUpperCase = (text: string) => {
    return text.charAt(0) + text.slice(1).toLowerCase();
  };

  useEffect(() => {
    DNavigator.changeTitleMenu("Minha Execução | Presença | Visão Loja");
  }, [DNavigator]);

  // const montListColor = (data: number[]): string[] => {
  //   const colorsList = data.map((item) => {
  //     return item >= 0 ? "rgb(0, 176, 240)" : "rgb(192, 0, 0)";
  //   });
  //   return colorsList;
  // };

  setTimeout(() => {
    if (refDivContent.current !== null) {
      const widthDiv = refDivContent.current.clientWidth - 148.5;
      if (widthDiv !== widthContent) {
        setWidthContent(widthDiv);
      }
    }

    if (refTable.current) {
      const heightDiv = refTable.current.clientHeight - 34;
      setHeightGraphics(heightDiv);
    }
  });

  // const generateListFilter = (): IItemFilter[] => {
  //   // SAllFilters.data.myExecution.subviewOSA.main.executions_type || [],
  //   return [
  //     {
  //       selected: "",
  //       id: "filterProduct",
  //       onChange: () => console.log("lol"),
  //       optionSelect: data?.data.dataTable,
  //     },
  //   ];
  // };

  const renderFilter = useCallback(() => {
    let showFilters: IShowFilters = {
      ...INITIAL_FILTERS,
      subChannel: true,
      banner: true,
      region: true,
      uf: true,
      isPromo: true,
      // commercialStructureDirector: true,
      // commercialStructureManager: true,
      // commercialStructureSales: true,
      storeCNPJ: true,
      // merchandising: true,
    };

    if (selections.selectedPresence.id === ELavarage.PRESENCA) {
      showFilters = {
        ...showFilters,
        businessUnitId: true,
        category: true,
        subCategory: true,
        // segment: true,
      };
    }

    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(SAllFilters, SFilters, showFilters, DFilters)
      );
  }, [SAllFilters, SFilters, selections]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter, selections]);

  const exportExcelSelected = () => {
    setIsLoadingExcel(true);
    file.refetch().finally(() => setIsLoadingExcel(false));
    setPopUpExportExcel(true);
  };

  const switchTitleByIndicators = (): string => {
    switch (selections.selectedPresence.id) {
      case ELavarage.PRESENCA:
        return selections.selectPeriod.id === EPeriod.MONTHLY
          ? "#SKUs Ativos"
          : "#SKUs Ativos (Act)";
      case ELavarage.GONDOLA:
        return "Meta";
      case ELavarage.PONTO_EXTRA:
        return "Qtd de Pontos Extras";
      default:
        return "SKUs Ativos";
    }
  };

  return (
    (popUpExportExcel && (
      <>
        <ModalOverlay className="remove" />
        <ModalWrapper className="remove">
          <UModalCalendar width="30vw" className="remove">
            {(false && (
              <LoadingComponent text="Preparando os dados para exportação!" />
            )) || (
              <Stack>
                <Flex justifyContent="flex-end" cursor="pointer">
                  <CgClose
                    size={30}
                    color={theme.colors.button}
                    onClick={() => setPopUpExportExcel(false)}
                  />
                </Flex>
                <Flex
                  w="100%"
                  alignItems="center"
                  p="50px 10px"
                  justify="space-around"
                >
                  {(isLoadingExcel && <LoadingComponent />) || (
                    <>
                      <Stack alignItems="center">
                        <RiFileExcel2Line color="green" size={85} />
                        <Text
                          color="black"
                          p="5px 10px"
                          background="#dfdfdf"
                          w="100%"
                          textAlign="center"
                          borderRadius="8px"
                          mt="5px !important"
                          mb="8px !important"
                        >
                          Kpi
                        </Text>
                        <Link
                          href={file.data?.file}
                          _focus={{
                            outline: false,
                          }}
                          download
                        >
                          <Flex
                            w="45px"
                            h="40px"
                            borderRadius="50%"
                            justify="center"
                            align="center"
                            cursor="pointer"
                            _hover={{
                              background: "#befdc3",
                              transition: "0.4s",
                            }}
                            boxShadow="2px 3px 10px #00000055"
                          >
                            <HiOutlineArrowDown color="black" size="23px" />
                          </Flex>
                        </Link>
                      </Stack>
                    </>
                  )}
                </Flex>
              </Stack>
            )}
          </UModalCalendar>
        </ModalWrapper>
      </>
    )) || (
      <Grid
        templateRows="auto 1fr"
        h="calc(100% - 120px)"
        m="5px !important"
        overflowY="hidden"
      >
        <Box w="max-content" minW="100%">
          {/* <Flex
            bg="white"
            color="black"
            h="85px"
            alignItems="center"
            px="4px"
            ref={refSubMenu}
            pr="15px"
          >
            <Image src={selectABrand} width="142px" p={5} />
            <Divisor />
            <BSLegendTable minWidth={`${widthContent || 550}px`}>
              <SubMenuItem
                text="Total de Lojas"
                bold
                value="--"
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Lojas c/ GAP"
                value="--"
                icon={More}
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Lojas s/ GAP"
                value="--"
                icon={Less}
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Performance"
                bold
                noMargin
                value="--"
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
            </BSLegendTable>
            <WrapperCardSubMenu
              gridColumns={`repeat(${
                selections.selectPeriod.id === EPeriod.WEEKLY ? 7 : 5
              }, 1.5fr)`}
              margin="0 12.5px 0 0"
            >
              <SubMenuItem
                font="14px"
                text={switchTitleByIndicators()}
                bold
                noMargin
                value="--"
                minWidth="160px"
                subTitleBold
              />
              <SubMenuItem
                font="14px"
                text={`% ${convertFirstChartToUpperCase(
                  selections.selectedPresence.label
                )} (${
                  selections.selectPeriod.id === EPeriod.WEEKLY ? "MTD" : "Act"
                })`}
                bold
                noMargin
                value="--"
                minWidth="160px"
                subTitleBold
              />

              <SubMenuItem
                text={`% ${convertFirstChartToUpperCase(
                  selections.selectedPresence.label
                )} (${
                  selections.selectPeriod.id === EPeriod.WEEKLY ? "W49" : "LM"
                })`}
                bold
                noMargin
                font="14px"
                value="--"
                minWidth="160px"
                subTitleBold
              />

              <SubMenuItem
                text={`% ${convertFirstChartToUpperCase(
                  selections.selectedPresence.label
                )} (${
                  selections.selectPeriod.id === EPeriod.WEEKLY ? "W48" : "L3M"
                })`}
                bold
                noMargin
                font="14px"
                value="--"
                minWidth="160px"
                subTitleBold
              />

              <SubMenuItem
                text={`% ${convertFirstChartToUpperCase(
                  selections.selectedPresence.label
                )} (${
                  selections.selectPeriod.id === EPeriod.WEEKLY ? "W47" : "YTD"
                })`}
                font="14px"
                bold
                noMargin
                value="--"
                minWidth="160px"
                subTitleBold
              />
              {selections.selectPeriod.id === EPeriod.WEEKLY && (
                <>
                  <SubMenuItem
                    text={`% ${convertFirstChartToUpperCase(
                      selections.selectedPresence.label
                    )} (W46)`}
                    bold
                    noMargin
                    font="14px"
                    value="--"
                    minWidth="160px"
                    subTitleBold
                  />

                  <SubMenuItem
                    text={`% ${convertFirstChartToUpperCase(
                      selections.selectedPresence.label
                    )} (W45)`}
                    font="14px"
                    bold
                    noMargin
                    value="--"
                    minWidth="160px"
                    subTitleBold
                  />
                </>
              )}
            </WrapperCardSubMenu>
          </Flex> */}
          <Flex
            position="relative"
            mt="5px !important"
            h="45px"
            bg={theme.colors.header}
            w="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Flex position="fixed">
              <Text fontSize="20px" fontWeight="bold">
                Detalhamento de{" "}
                {selections.selectedPresence.label.toUpperCase()} % TOP{" "}
                {(data && data.total < limitItem ? data.total : limitItem) || 0}{" "}
                Lojas Ofensoras | Bandeira:{" "}
                {` ${
                  SFilters.promoFilter.banner.selectedFilterName || "Todas"
                } `}{" "}
                | Região:{" "}
                {` ${
                  SFilters.promoFilter.region.selectedFilterName || "Todas"
                }`}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Stack bg="white" mt="5px !important" p="5px" overflow="auto">
          <Flex>
            <Flex
              w={`${widthContent + 148}px`}
              minW="fit-content"
              p="10px"
              justifyContent="space-between"
            >
              <Flex>
                <Image src={calendarIcon} alt="logo-store" w="40px" />
                {/* setTemporalFilter={setTemporalFilter} */}
                <TemporalFilter
                  setTemporalFilter={handleSelectedPeriod}
                  temporalFilter={selections.selectPeriod}
                  itens={[
                    { id: EPeriod.MONTHLY, label: "Mensal" },
                    { id: EPeriod.WEEKLY, label: "Semanal" },
                  ]}
                />
              </Flex>
              <Flex>
                <Image src={kpIcon} alt="logo-store" w="40px" />
                {/* setTemporalFilter={setLavarageFilter} */}
                {/* setTemporalFilter={handleSelectedLavarage} */}
                <TemporalFilter
                  setTemporalFilter={handleSelectedLavarage}
                  temporalFilter={selections.selectedPresence}
                  itens={[
                    { id: ELavarage.PRESENCA, label: "Presenca" },
                    { id: ELavarage.GONDOLA, label: "Gondola" },
                    { id: ELavarage.PONTO_EXTRA, label: "Ponto Extra" },
                  ]}
                />
              </Flex>
            </Flex>
            <Flex
              flex={1}
              justifyContent={
                selections.selectedPresence.id !== ELavarage.PRESENCA
                  ? "space-between"
                  : "flex-end"
              }
              pl="20px"
            >
              {selections.selectedPresence.id !== ELavarage.PRESENCA && (
                <Flex>
                  {/* <ListBox
                    itens={generateListFilter()}
                    icon={lavarageIcon}
                    iconSize="50px"
                    minWidth="200px"
                  /> */}
                  {stores && (
                    <Flex justifyContent="flex-end" align="center" mr={2}>
                      <Image
                        src={lavarageIcon}
                        alt="icon-funnel"
                        width="40px"
                      />
                      <Select
                        ml="5px"
                        p="0"
                        borderRadius="5px"
                        as="select"
                        className="selectForm"
                        borderColor="black"
                        h="28px"
                        fontSize="12px"
                        color="black"
                        onChange={(e) => {
                          const category = e.target.value;
                          setSelectedStore(category);
                          setSelections({
                            ...selections,
                            selectedCategoryFilter: category,
                          });
                        }}
                      >
                        <option value="">Todas</option>
                        {SAllFilters.data.myExecution.subviewOSA.secondary.categories_promo?.map(
                          (item) => (
                            <option
                              selected={item.id === selectedStore}
                              value={item.id}
                              key={Math.random()}
                            >
                              {(selections.selectedPresence.id ===
                                ELavarage.GONDOLA &&
                                "SOS") ||
                                "PE"}{" "}
                              - {item.label}
                            </option>
                          )
                        )}
                      </Select>
                    </Flex>
                  )}

                  {/* <ListBox
          itens={generateListFilter()}
          icon={productsIcon}
          iconSize="50px"
          minWidth="200px"
          marginLeft="20px"
        /> */}
                  {banners && (
                    <Flex justifyContent="flex-end" align="center">
                      <Image
                        src={productsIcon}
                        alt="icon-banner"
                        width="40px"
                      />
                      <Select
                        ml="5px"
                        p="0"
                        borderRadius="5px"
                        as="select"
                        className="selectForm"
                        borderColor="black"
                        h="28px"
                        fontSize="12px"
                        color="black"
                        onChange={(e) => {
                          const brand = e.target.value;
                          setSelections({
                            ...selections,
                            selectedBrandFilter: brand,
                          });
                        }}
                      >
                        <option value="">Todas</option>
                        {SAllFilters.data.myExecution.subviewOSA.secondary.brands_promo
                          .filter(
                            (category) =>
                              category.categoryPromoId === selectedStore
                          )
                          ?.map((item: filter) => (
                            <option
                              selected={
                                item.id === selections.selectedBrandFilter
                              }
                              value={item.id}
                              key={Math.random()}
                            >
                              {item.label}
                            </option>
                          ))}
                      </Select>
                    </Flex>
                  )}
                </Flex>
              )}
              <Flex
                align="center"
                cursor="pointer"
                onClick={() => exportExcelSelected()}
              >
                <Text fontWeight="bold" pr="15px" color="black">
                  Exportar para
                </Text>
                <Image src={excel} w="50px" h="50px" />
              </Flex>
            </Flex>
          </Flex>
          <Flex pl="13px" py="5px" alignItems="center" color="black">
            <Text ml="20px">Exibir</Text>
            <Select
              w="100px"
              ml="10px"
              h="30px"
              onChange={handleLimitItem}
              borderColor="black"
            >
              <option value={data?.total || 0}>Todos</option>
              <option selected={limitItem === 25} value={25}>
                25
              </option>
              <option selected={limitItem === 50} value={50}>
                50
              </option>
              <option selected={limitItem === 100} value={100}>
                100
              </option>
            </Select>
            <Text ml="10px !important">Itens</Text>
          </Flex>
          {(isLoading && <LoadingComponent />) ||
            ((!data || data?.total === 0) && <EmptyMessage />) || (
              <>
                <Flex overflo="auto" maxW="100%">
                  <Stack ref={refDivContent} minW="auto">
                    <Flex
                      backgroundColor="#e7e6e6"
                      p="8px 12px"
                      alignItems="center"
                    >
                      <Text color="#c00000" fontWeight="bold" fontSize="14px">
                        Top {limitItem} Lojas OFENSORAS
                      </Text>
                    </Flex>
                    <TableSubView
                      refElement={refTable}
                      headers={headers}
                      dataTable={limitItensTable}
                      collapse
                    />
                  </Stack>
                  <Column
                    title="-"
                    subTitle={switchTitleByIndicators()}
                    itens={limitActiveStore}
                  />
                  <Column
                    title={data?.data?.act.total || "-"}
                    subTitle={`% ${convertFirstChartToUpperCase(
                      selections.selectedPresence.label
                    )} \n(${
                      selections.selectPeriod.id === EPeriod.WEEKLY
                        ? "MTD"
                        : "Act"
                    })`}
                  >
                    <Stack mt="-1px !important">
                      <ShareValue
                        mt={0}
                        dataGraphic={limitAct}
                        height={heightGraphics}
                        gap={0.07}
                        color="#a6a6a6"
                      />
                    </Stack>
                  </Column>
                  {/* subTitle={`% ${convertFirstChartToUpperCase(
                    selections.selectedPresence.label
                  )} (${
                    selections.selectPeriod.id === EPeriod.WEEKLY
                      ? data?.data?.lm.label || "-"
                      : "LM"
                  })`} */}
                  <Column
                    title={data?.data?.lm.total || "-"}
                    subTitle={`% ${convertFirstChartToUpperCase(
                      selections.selectedPresence.label
                    )} \n(${
                      selections.selectPeriod.id === EPeriod.WEEKLY
                        ? data?.data?.lm.label || "-"
                        : "LM"
                    })`}
                  >
                    <Stack ref={refSubMenu} mt="-1px !important">
                      <ShareValue
                        mt={0}
                        dataGraphic={limitLM}
                        height={heightGraphics}
                        gap={0.07}
                        color="#1f497d"
                      />
                    </Stack>
                  </Column>
                  <Column
                    title={data?.data?.l3m.total || "-"}
                    subTitle={`% ${convertFirstChartToUpperCase(
                      selections.selectedPresence.label
                    )} \n(${
                      selections.selectPeriod.id === EPeriod.WEEKLY
                        ? data?.data?.l3m.label || "-"
                        : "L3M"
                    })`}
                  >
                    <Stack ref={refSubMenu} mt="-1px !important">
                      <ShareValue
                        mt={0}
                        dataGraphic={limitL3M}
                        height={heightGraphics}
                        gap={0.07}
                      />
                    </Stack>
                  </Column>
                  <Column
                    title={data?.data?.ytd.total || "-"}
                    subTitle={`% ${convertFirstChartToUpperCase(
                      selections.selectedPresence.label
                    )} \n(${
                      selections.selectPeriod.id === EPeriod.WEEKLY
                        ? data?.data?.ytd.label || "-"
                        : "YTD"
                    })`}
                  >
                    <Stack ref={refSubMenu} mt="-1px !important">
                      <ShareValue
                        mt={0}
                        dataGraphic={limitYTD || []}
                        height={heightGraphics}
                        gap={0.07}
                      />
                    </Stack>
                  </Column>
                  {selections.selectPeriod.id === EPeriod.WEEKLY && (
                    <>
                      {(data?.data?.lastWeek?.data.label || "0") > "0" && (
                        <Column
                          title={data?.data?.lastWeek.total || "-"}
                          subTitle={`% ${convertFirstChartToUpperCase(
                            selections.selectedPresence.id
                          )} \n(${data?.data?.lastWeek.label || "-"})`}
                        >
                          <Stack ref={refSubMenu} mt="-1px !important">
                            <ShareValue
                              mt={0}
                              dataGraphic={limitLastWeek}
                              height={heightGraphics}
                              gap={0.07}
                            />
                          </Stack>
                        </Column>
                      )}

                      {(data?.data?.penultimateWeek.data.label || "0") > "0" && (
                        <Column
                          title={data?.data?.penultimateWeek.total || "-"}
                          subTitle={`% ${convertFirstChartToUpperCase(
                            selections.selectedPresence.id
                          )} \n(${data?.data?.penultimateWeek.label || "-"})`}
                        >
                          <Stack ref={refSubMenu} mt="-1px !important">
                            <ShareValue
                              dataGraphic={limitPenultimateWeek || []}
                              height={heightGraphics}
                              gap={0.07}
                            />
                          </Stack>
                        </Column>
                      )}
                    </>
                  )}
                </Flex>
              </>
            )}
        </Stack>
      </Grid>
    )
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
    DFilters: bindActionCreators(AFilters, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailKPI);
